import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Announcements,
  AnnouncementsSkeleton,
  Error,
} from '@/admin/components';
import { defaultAnnouncements, langIdByLang } from '@/admin/consts';
import {
  AnnouncementsFormProvider,
  AppContext,
  defaultAnnouncementsValues,
  StorageBlobCleanProvider,
} from '@/admin/providers';
import { getAnnouncementKey } from '@/admin/utils/helpers';
import { getAnnouncementsApi } from '@/admin/utils/helpers-api';
import { AnnouncementsTitle, IAnnouncementsData } from '@/common/types';

const defaultValues = { ...defaultAnnouncementsValues };

export const AnnouncementsPage = () => {
  const { t } = useTranslation();
  const {
    selectedUser: { networkId: selectedUserNetworkId },
    userInfo: { role },
    getAccessToken,
  } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [announcementsData, setAnnouncementsData] =
    useState<IAnnouncementsData>(defaultAnnouncements);

  // fetch the announcements
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        await fetchAnnouncements();

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  }, [selectedUserNetworkId]);

  const fetchAnnouncements = async () => {
    const token = await getAccessToken();
    const networkId =
      role === 'm3Tech admin' ? selectedUserNetworkId : undefined;

    const announcementsData = await getAnnouncementsApi(token, networkId);

    if (announcementsData) {
      const { announcements } = announcementsData;

      announcements.forEach((announcement) => {
        const announcementId = getAnnouncementKey(
          langIdByLang[announcement.language],
          announcement.order
        );

        defaultValues[announcementId] = {
          ...defaultAnnouncementsValues[announcementId],
          ...announcement,
        };
      });

      setAnnouncementsData(announcementsData);
    } else {
      const keys = Object.keys(
        defaultAnnouncementsValues
      ) as AnnouncementsTitle[];

      keys.forEach((key) => {
        defaultValues[key] = { ...defaultAnnouncementsValues[key] };
      });
    }

    return defaultValues;
  };

  if (isLoading) return <AnnouncementsSkeleton />;

  if (isError)
    return (
      <Error
        message={t('errorOccurredMessage')}
        title={t('errorOccurredTitle')}
      />
    );

  return (
    <AnnouncementsFormProvider defaultValues={defaultValues}>
      <StorageBlobCleanProvider>
        <Announcements
          announcementsData={announcementsData}
          fetchAnnouncements={fetchAnnouncements}
        />
      </StorageBlobCleanProvider>
    </AnnouncementsFormProvider>
  );
};
