import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: 'en',
    debug: true,
    whitelist: ['en', 'fr'],

    interpolation: {
      escapeValue: false,
    },
  });
  
/* eslint-disable import/no-default-export */
export default i18n;