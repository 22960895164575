import {
  Star,
  UnfoldLess,
  UnfoldMore,
  ArrowDropDown,
  ArrowDropUp,
} from '@mui/icons-material';
import {
  Button,
  Paper,
  Rating,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  IconSvg,
  TestimonialChip,
  TestimonialFacebookRating,
} from '@/admin/components';
import { CHAR_LIMIT } from '@/admin/consts/testimonials';
import { useModal } from '@/admin/hooks';
import {
  AppContext,
  TestimonialsContext,
  ThemeContext,
} from '@/admin/providers';
import {
  brandIcons,
  getBrand,
  getLocalizedDate,
  sourceIcons,
} from '@/admin/utils/helpers';
import { updateTestimonialStatus } from '@/admin/utils/helpers-api';
import {
  TestimonialLikeRating,
  TestimonialSource,
  TestimonialStatus,
} from '@/common/types';

type TestimonialProps = {
  id: string;
  authorName: string;
  content: string;
  date: number;
  networkId: number;
  page: 'dashboard' | 'testimonials';
  source: TestimonialSource;
  status: TestimonialStatus;
  rating?: number;
  reviewLikeRating?: TestimonialLikeRating;
  isOutlined?: boolean;
  isTestimonialExpanded?: boolean;
  setIsTestimonialExpanded?: (isExpanded: boolean) => void;
};

export const Testimonial = ({
  id,
  authorName,
  content,
  date,
  networkId,
  page,
  rating,
  source,
  status,
  reviewLikeRating,
  isTestimonialExpanded,
  setIsTestimonialExpanded,
}: TestimonialProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    getAccessToken,
    displayedUserInfo: { displayTestimonials },
  } = useContext(AppContext);
  const { changeTestimonialStatus } = useContext(TestimonialsContext);
  const { openSnackbar } = useContext(ThemeContext);
  const { modalContent, showModal, handleModalClose } = useModal();

  const [isContentOpen, setIsContentOpen] = useState<boolean>(false);

  const isPublished = status === 'published';

  const brand = getBrand(networkId);

  const Icon = source === 'website' ? brandIcons[brand] : sourceIcons[source];

  const hasReadMore = content.length > CHAR_LIMIT;
  const contentFirstHalf = content.slice(0, CHAR_LIMIT);
  const contentSecondHalf = content.slice(CHAR_LIMIT);
  const hasEllipsis = hasReadMore && !isContentOpen;

  useEffect(() => {
    if (isTestimonialExpanded !== undefined) {
      setIsContentOpen(isTestimonialExpanded);
    }
  }, [isTestimonialExpanded]);

  const handleToggleButtonClick = () => {
    if (setIsTestimonialExpanded) {
      setIsTestimonialExpanded(!isTestimonialExpanded);
    }
    setIsContentOpen(!isContentOpen);
  };

  const handlePublishButtonClick = async () => {
    showModal({
      message: isPublished
        ? t('unpublishTestimonialMessage')
        : t('publishTestimonialMessage'),
      title: isPublished
        ? t('unpublishTestimonialTitle')
        : t('publishTestimonialTitle'),
      handleAgree: handleUpdateTestimonialStatus,
    });
  };

  const handleUpdateTestimonialStatus = async () => {
    try {
      const token = await getAccessToken();
      const publishStatus = await updateTestimonialStatus(
        !isPublished,
        id,
        token
      );

      if (publishStatus === 200) {
        changeTestimonialStatus(id);
      }

      handleModalClose();
      openSnackbar(
        isPublished
          ? t('unpublishSnackbarSuccessMessage').replace(
              '{authorName}',
              authorName
            )
          : t('publishSnackbarSuccessMessage').replace(
              '{authorName}',
              authorName
            )
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      openSnackbar(
        isPublished
          ? t('unpublishSnackbarFailMessage').replace(
              '{authorName}',
              authorName
            )
          : t('publishSnackbarFailMessage').replace('{authorName}', authorName)
      );
    }
  };

  return (
    <>
      <StyledTestimonial>
        <StyledStack justifyContent="space-between" direction="row" spacing={1}>
          <StyledStack direction="row" spacing={1}>
            <StyledSvgIcon>
              <IconSvg>
                <Icon />
              </IconSvg>
            </StyledSvgIcon>

            {source === 'facebook' && reviewLikeRating ? (
              <TestimonialFacebookRating
                isLiked={reviewLikeRating === 'positive'}
              />
            ) : (
              <StyledRating
                name="read-only"
                value={rating}
                readOnly
                emptyIcon={
                  <Star style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
            )}
          </StyledStack>

          <TestimonialChip
            isPublished={isPublished}
            displayTestimonials={displayTestimonials}
          />
        </StyledStack>

        <StyledWrapStack direction="row" alignItems="end" spacing={2}>
          {source !== 'facebook' && (
            <Typography variant="h3">{authorName}</Typography>
          )}
          <Typography variant="h4">
            {getLocalizedDate(language, date)}
          </Typography>
        </StyledWrapStack>

        <StyledContent variant="subtitle1">
          {contentFirstHalf}
          {hasEllipsis && '...'}
          {isContentOpen && contentSecondHalf}
        </StyledContent>

        {
          <StyledControls>
            {hasReadMore && (
              <Button onClick={handleToggleButtonClick}>
                {isContentOpen ? t('readLess') : t('readMore')}
                {page === 'dashboard' ? (
                  <StyledIcon>
                    {isContentOpen ? <UnfoldLess /> : <UnfoldMore />}
                  </StyledIcon>
                ) : isContentOpen ? (
                  <ArrowDropUp />
                ) : (
                  <ArrowDropDown />
                )}
              </Button>
            )}

            {source === 'website' &&
              /* only website reviews can be unpublished/published individually */
              (isPublished ? (
                <StyledButton onClick={handlePublishButtonClick}>
                  {t('unpublish')}
                </StyledButton>
              ) : (
                <StyledButton
                  variant="contained"
                  onClick={handlePublishButtonClick}
                >
                  {t('publish')}
                </StyledButton>
              ))}
          </StyledControls>
        }
      </StyledTestimonial>

      <Dialog
        buttonAgree={modalContent.buttonAgree}
        buttonClose={modalContent.buttonClose}
        message={modalContent.message}
        title={modalContent.title}
        open={modalContent.open}
        handleAgree={modalContent.handleAgree}
        handleClose={modalContent.handleClose}
      />
    </>
  );
};

const StyledTestimonial = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),

  '&:last-child': {
    marginBottom: theme.spacing(0),
  },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));

const StyledSvgIcon = styled('div')({
  fontSize: '24px',
  lineHeight: 0,
});

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: theme.palette.common.black,
  },
}));

const StyledContent = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  overflowWrap: 'break-word',
}));

const StyledControls = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(3),
}));

const StyledButton = styled(Button)({
  marginLeft: 'auto',
});

const StyledIcon = styled('div')(({ theme }) => ({
  lineHeight: 0,
  margin: theme.spacing(0.25, 0, 0, 0.5),
  transform: 'rotate(45deg)',
}));

const StyledWrapStack = styled(Stack)(({ theme }) => ({
  flexWrap: 'wrap',

  '&:first-of-type': {
    marginRight: theme.spacing(1),
  },

  '&:last-of-type': {
    marginLeft: theme.spacing(0),
  },
}));
