import { Paper, styled } from '@mui/material';
import { useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AnnouncementsForm, DragDropTabs, Icon } from '@/admin/components';
import { IDragDropTab } from '@/admin/types/common';
import { getAnnouncementKey, reorderDragDropTabs } from '@/admin/utils/helpers';
import { AnnouncementsValues } from '@/common/types';

type AnnouncementsPanelProps = {
  currentAnnouncementId: number;
  langTabsValue: number;
  tabs: IDragDropTab[];
  tabsValue: number;
  setCurrentAnnouncementId: (id: number) => void;
  setTabs: (tabs: IDragDropTab[]) => void;
  setTabsValue: (value: number) => void;
};

export const AnnouncementsPanel = ({
  currentAnnouncementId,
  langTabsValue,
  tabs,
  tabsValue,
  setCurrentAnnouncementId,
  setTabs,
  setTabsValue,
}: AnnouncementsPanelProps) => {
  const { t } = useTranslation();
  const { getValues, setValue, watch } = useFormContext<AnnouncementsValues>();

  const currentAnnouncement = getAnnouncementKey(
    langTabsValue,
    currentAnnouncementId
  );
  const currentAnnouncementTitle = watch(`${currentAnnouncement}.title`);

  // update the tabs if the title field is changed
  useEffect(() => {
    const updatedTabs = [...tabs].map((tab) => {
      const currentAnnouncement = getAnnouncementKey(
        langTabsValue,
        Number(tab.id)
      );
      const currentAnnouncementTitle = getValues(
        `${currentAnnouncement}.title`
      );

      return {
        ...tab,
        title: currentAnnouncementTitle || t('noAnnouncement'),
      };
    });

    setTabs(updatedTabs);
  }, [currentAnnouncementTitle]);

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) return;

    const reorderedTabs = reorderDragDropTabs(
      tabs,
      result.source.index,
      result.destination.index
    );

    const renamedReorderedTabs = reorderedTabs.map((tab, index) => {
      const currentAnnouncement = getAnnouncementKey(
        langTabsValue,
        Number(tab.id)
      );
      const currentAnnouncementTitle = getValues(
        `${currentAnnouncement}.title`
      );
      const newTab = {
        ...tab,
        subTitle: `${t('announcement')} ${index + 1}`,
        title: currentAnnouncementTitle
          ? currentAnnouncementTitle
          : t('noAnnouncement'),
      } as IDragDropTab;

      setValue(`${currentAnnouncement}.order`, index);
      return newTab;
    });

    setTabs(renamedReorderedTabs);
    setTabsValue(result.destination.index);
    setCurrentAnnouncementId(Number(result.draggableId));
  };

  const handleTitleTabClick = (tab: IDragDropTab, index: number) => {
    setTabsValue(index);
    setCurrentAnnouncementId(Number(tab.id));
  };

  const getDragDropStatusIcon = (tabId: number) => {
    const currentAnnouncement = getAnnouncementKey(langTabsValue, tabId);
    const isCurrentAnnouncementVisible =
      getValues(`${currentAnnouncement}.visibility`) === 'visible';

    return (
      <Icon
        symbol={isCurrentAnnouncementVisible ? 'visibility' : 'visibility_off'}
        size="md"
        wght={400}
      />
    );
  };

  return (
    <StyledPaper>
      <StyledDragDropTabs>
        <DragDropTabs
          tabs={tabs}
          value={tabsValue}
          setIcon={getDragDropStatusIcon}
          onDragEnd={handleDragEnd}
          onTabClick={handleTitleTabClick}
        />
      </StyledDragDropTabs>

      {currentAnnouncementId === 0 && (
        <AnnouncementsForm currentAnnouncement={currentAnnouncement} />
      )}

      {currentAnnouncementId === 1 && (
        <AnnouncementsForm currentAnnouncement={currentAnnouncement} />
      )}

      {currentAnnouncementId === 2 && (
        <AnnouncementsForm currentAnnouncement={currentAnnouncement} />
      )}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0, 3, 5),
}));

const StyledDragDropTabs = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, -3),
}));
