import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { defaultTextEditorContent } from '@/admin/providers';
import { IBlogData, IBlogValues } from '@/common/types';

type BlogFormProviderProps = {
  children: ReactNode;
  defaultValues: IBlogValues | IBlogData;
  isNew?: boolean;
};

export const BlogFormProvider = ({
  children,
  defaultValues,
  isNew = false,
}: BlogFormProviderProps) => {
  const { t } = useTranslation();

  const blogSchema = yup.object().shape({
    authorName: yup.string().required(t('formAuthorNameRequired')),
    blogContent: yup
      .string()
      .test(
        'is-default-content',
        t('formBlogContentRequired'),
        (value) => value !== defaultTextEditorContent
      )
      .required(t('formBlogContentRequired')),
    blogName: yup.string().required(t('formBlogNameRequired')),
    blogNameLowerCased: yup.string().required(t('formBlogNameRequired')),
    brand: yup.string().optional(),
    description: yup.string().max(160, t('formDescriptionMax')).optional(),
    imageURL: yup.string().optional(),
    language: yup.string().required(t('formLanguageRequired')),
    slug: yup
      .string()
      .matches(/^$|^[a-z0-9-]+$/, t('formSlugMatch'))
      .optional(),
    title: yup.string().max(60, t('formTitleMax')).optional(),
    userType: yup.string().required(t('formAuthorTypeRequired')),
  });

  const methods = useForm<IBlogData>({
    mode: 'onTouched',
    resolver: yupResolver(blogSchema),
    defaultValues,
  });

  useEffect(() => {
    // update default values as fetched blog data
    if (!isNew) {
      methods.reset({ ...defaultValues });
    }
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <StyledForm>{children}</StyledForm>
    </FormProvider>
  );
};

const StyledForm = styled('form')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});
