import { styled, Typography } from '@mui/material';

type ReadOnlyTextFieldProps = {
  label: string;
  placeholder?: string;
  value?: string;
};

export const ReadOnlyTextField = ({
  label,
  placeholder,
  value,
}: ReadOnlyTextFieldProps) => (
  <div>
    <Typography variant="caption">{label}:</Typography>

    {value && <StyledTypography variant="subtitle1">{value}</StyledTypography>}

    {placeholder && (
      <StyledTypographyPlaceholder variant="subtitle1">
        {placeholder}
      </StyledTypographyPlaceholder>
    )}
  </div>
);

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  overflowWrap: 'break-word',
}));

const StyledTypographyPlaceholder = styled(StyledTypography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
