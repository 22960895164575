import DeleteIcon from '@mui/icons-material/Delete';
import { alpha, styled } from '@mui/material';
import { useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@/admin/components';
import { ImageState } from '@/admin/types/common';
import { img_url } from '@/admin/utils/helpers';

export const aspectRatio = '16 / 9';

type HomepageCropImageProps = {
  crop: { x: number; y: number };
  image: string;
  imageState: ImageState;
  rotation: number;
  zoom: number;
  handleDeleteImage: () => void;
  setCrop: (value: { x: number; y: number }) => void;
  setCroppedAreaPixels: (value: Area | null) => void;
  setRotation: (value: number) => void;
  setZoom: (value: number) => void;
};

export const HomepageCropImage = ({
  crop,
  image,
  imageState,
  rotation,
  zoom,
  handleDeleteImage,
  setCrop,
  setCroppedAreaPixels,
  setRotation,
  setZoom,
}: HomepageCropImageProps) => {
  const { t } = useTranslation();

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  return (
    <StyledCropContainer>
      {imageState === 'previewImage' ? (
        <StyledImage src={image} alt={t('headerImage')} />
      ) : (
        <Cropper
          crop={crop}
          aspect={1.83}
          image={image}
          restrictPosition={false}
          rotation={rotation}
          showGrid={false}
          zoom={zoom}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      )}
      <StyledIconButton>
        <IconButton color="secondary" onClick={handleDeleteImage}>
          <DeleteIcon />
        </IconButton>
      </StyledIconButton>
    </StyledCropContainer>
  );
};

const StyledCropContainer = styled('div')(({ theme }) => ({
  aspectRatio: `${aspectRatio}`,
  backgroundImage: `url(${img_url}/images/crop_image_background.png)`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  borderRadius: '10px',
  overflow: 'hidden',
  position: 'relative',

  '& .reactEasyCrop_CropArea': {
    color: alpha(theme.palette.secondary.main, 0.75),
  },
}));

const StyledIconButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

const StyledImage = styled('img')({
  height: '100%',
  width: '100%',
});
