import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BlogPost } from '@/admin/components';
import {
  AppContext,
  BlogFormProvider,
  defaultBlogValues,
  StorageBlobCleanProvider,
} from '@/admin/providers';
import { getBrand } from '@/admin/utils/helpers';
import { IBlogValues, Lang } from '@/common/types';

export const AddBlogPage = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { displayedUserInfo, isAdmin, selectedUserId } = useContext(AppContext);

  const { brand, employeeId, name, networkId, userType } = displayedUserInfo;

  const defaultValues: IBlogValues = {
    ...defaultBlogValues,
    authorName: name,
    brand: brand || getBrand(networkId),
    language: language as Lang,
    slug: t('untitledBlogSlug'),
    title: t('untitledBlog'),
    networkId,
    userType: userType,
    userId: isAdmin ? selectedUserId : employeeId,
  };

  return (
    <BlogFormProvider defaultValues={defaultValues} isNew>
      <StorageBlobCleanProvider>
        <BlogPost isNewBlog={true} />
      </StorageBlobCleanProvider>
    </BlogFormProvider>
  );
};
