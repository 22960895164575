import { Button } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Top } from '@/admin/components';
import { AppContext } from '@/admin/providers';

type PrivacyPoliciesTopProps = {
  handleOpenModal: () => void;
};

export const PrivacyPoliciesTop = ({
  handleOpenModal,
}: PrivacyPoliciesTopProps) => {
  const { t } = useTranslation();
  const {
    selectedUser: { userType },
  } = useContext(AppContext);

  return (
    <Top title={t('privacyPolicies')}>
      {userType !== 'network' && (
        <Button
          color="primary"
          variant="contained"
          startIcon={<Icon symbol="add" size="md" />}
          onClick={handleOpenModal}
        >
          {t('addPolicy')}
        </Button>
      )}
    </Top>
  );
};
