import { GoogleOAuthProvider } from '@react-oauth/google';

import { Testimonials } from '@/admin/components';
import { REACT_APP_GOOGLE_CLIENT_ID } from '@/admin/config/variables';
import { TestimonialsProvider } from '@/admin/providers';

export const TestimonialsPage = () => (
  <TestimonialsProvider>
    <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
      <Testimonials />
    </GoogleOAuthProvider>
  </TestimonialsProvider>
);
