import { CSSObject, styled, Theme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { useContext, useEffect, useState } from 'react';

import {
  NavButton,
  NavHeader,
  NavLink,
  NavMenu,
  NavSkeleton,
} from '@/admin/components';
import {
  navLinks,
  ADMIN_TOOLBAR_HEIGHT,
  navSubLinks,
  SETTINGS_PATH,
} from '@/admin/consts';
import { AppContext, theme } from '@/admin/providers';
import { NavLinkType } from '@/admin/types/common';

const NAV_OPEN_WIDTH = 326;
const NAV_CLOSE_WIDTH = 115;

type NavProps = {
  hiddenLinks: NavLinkType[];
  hiddenSubLinks: NavLinkType[];
};

export const Nav = ({ hiddenLinks, hiddenSubLinks }: NavProps) => {
  const {
    isAdmin,
    isBossLogin,
    userInfo: { name, role },
    handleLogout,
  } = useContext(AppContext);

  const [open, setOpen] = useState(true);
  const [isAdminToolbar, setIsAdminToolbar] = useState<boolean>(false);

  const visibleLinks = navLinks.filter(
    (link) => !hiddenLinks.includes(link.id)
  );

  const visibleSubLinks = navSubLinks.filter(
    (link) => !hiddenSubLinks.includes(link.id)
  );

  const isBossAnnouncementsPage =
    window.location.href.includes('announcements') && isBossLogin;

  useEffect(() => {
    if (isAdmin) {
      setIsAdminToolbar(
        isBossAnnouncementsPage ? role !== 'network admin' : true
      );
    }
  }, [isAdmin, isBossAnnouncementsPage, role]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setOpen(window.innerWidth > theme.breakpoints.values.lg);
  };

  return (
    <StyledNav
      className={isAdminToolbar ? 'is-admin-toolbar' : ''}
      variant="permanent"
      open={open}
    >
      {!name && <NavSkeleton open={open} />}

      {name && (
        <>
          <NavHeader open={open} setOpen={setOpen} />

          <StyledLinks>
            <ul>
              {visibleLinks.map((link) => (
                <li key={link.to}>
                  <NavLink
                    icon={link.icon as string}
                    isOpen={open}
                    title={link.title}
                    to={link.to}
                  />
                </li>
              ))}

              {Boolean(visibleSubLinks.length) && (
                <li>
                  <NavMenu
                    icon="library_add"
                    isOpen={open}
                    links={visibleSubLinks}
                    title="pages"
                  />
                </li>
              )}
            </ul>
          </StyledLinks>

          <StyledActions>
            <NavLink
              icon="settings"
              isOpen={open}
              title="settings"
              to={SETTINGS_PATH}
              isSecondary
            />

            {!isAdmin && (
              <NavButton
                icon="logout"
                isOpen={open}
                title="logout"
                onClick={handleLogout}
              />
            )}
          </StyledActions>
        </>
      )}
    </StyledNav>
  );
};

const openedMixin = (theme: Theme): CSSObject => ({
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  width: NAV_OPEN_WIDTH,
});

const closedMixin = (theme: Theme): CSSObject => ({
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: NAV_CLOSE_WIDTH,
});

const StyledNav = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  boxSizing: 'border-box',
  boxShadow: `0px 4px 4px ${theme.palette.shadow?.main}`,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  width: NAV_OPEN_WIDTH,

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),

  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),

  '&.is-admin-toolbar > div': {
    bottom: 0,
    height: 'auto',
    top: ADMIN_TOOLBAR_HEIGHT,
  },

  [theme.breakpoints.down('lg')]: {
    width: NAV_CLOSE_WIDTH,

    '& .MuiDrawer-paper': {
      width: NAV_CLOSE_WIDTH,
    },
  },
}));

export const StyledLinks = styled('nav')(({ theme }) => ({
  flexGrow: 1,
  marginBottom: theme.spacing(5),
}));

export const StyledActions = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  flexShrink: 0,
  padding: theme.spacing(1, 0),
}));
