import { Skeleton, styled } from '@mui/material';

export const HomepageSkeleton = () => (
  <StyledContainer>
    <Skeleton variant="rectangular" width={212} height={56} />

    <StyledWrapper>
      <Skeleton variant="rectangular" height={56} />
      <StyledSkeleton variant="rectangular" />
    </StyledWrapper>
  </StyledContainer>
);

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.spacing(6, 6, 4),
}));

const StyledWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  margin: `${theme.spacing(12)} auto 0`,
  maxWidth: '1218px',
  width: '100%',
}));

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  flexGrow: 1,
  marginTop: theme.spacing(3),
}));
