import { Auth0Provider } from '@auth0/auth0-react';
import { createRoot } from 'react-dom/client';

import { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENTID, REACT_APP_AUTH0_AUDIENCE } from './config/variables';
import { App } from '@/admin/components';
import { reportWebVitals } from '@/admin/reportWebVitals';
import '@/admin/i18n';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <Auth0Provider
    domain={REACT_APP_AUTH0_DOMAIN ?? ''}
    clientId={REACT_APP_AUTH0_CLIENTID ?? ''}
    redirectUri={window.location.origin}
    audience={REACT_APP_AUTH0_AUDIENCE ?? ''}
    useRefreshTokens={true}
  >
    <App />
  </Auth0Provider>
);

reportWebVitals();
