// paths with / in the beginning are the root paths
export const ANNOUNCEMENTS_PATH = '/announcements';
export const BLOGS_PATH = '/blogs';
export const DASHBOARD_PATH = '/';
export const HOMEPAGE_PATH = '/homepage';
export const PRIVACY_POLICIES_PATH = '/privacypolicies';
export const SETTINGS_PATH = '/settings';
export const TESTIMONIALS_PATH = '/testimonials';

// paths without / are nested paths
export const ADD_PATH = 'add';
