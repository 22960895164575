import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useBlocker } from '@/admin/hooks';
import { AppContext } from '@/admin/providers';

export function useCallbackPrompt(when: boolean) {
  const navigate = useNavigate();
  const location = useLocation();

  const { isConfirmNavigation, setIsCancelNavigation, setIsConfirmNavigation } =
    useContext(AppContext);

  const [showPrompt, setShowPrompt] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [lastLocation, setLastLocation] = useState<any>(null);

  useEffect(() => {
    if (when) {
      setIsConfirmNavigation(false);
    } else {
      setIsConfirmNavigation(true);
    }
  }, [when]);

  useEffect(() => {
    if (isConfirmNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [isConfirmNavigation]);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setLastLocation(null);
    setIsCancelNavigation(true);
  }, []);

  const confirmNavigation = useCallback((callback?: () => void) => {
    setShowPrompt(false);
    setIsConfirmNavigation(true);
    setIsCancelNavigation(false);

    callback && callback();
  }, []);

  // handle blocking when the user clicks on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (nextLocation: any) => {
      // check the next location and the current location are equals or not
      if (
        !isConfirmNavigation &&
        nextLocation.location.pathname !== location.pathname
      ) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [isConfirmNavigation, location]
  );

  useBlocker(handleBlockedNavigation, when);

  return { showPrompt, confirmNavigation, cancelNavigation };
}
