import { styled } from '@mui/material';
import { RefObject, useEffect, useRef } from 'react';

import { SelectType } from '@/admin/types/common';

type ToolbarSelectDropdownItemProps = {
  buttonText: string;
  textType: SelectType;
  onClick: (type: SelectType) => void;
  registerItem: (itemRef: RefObject<HTMLButtonElement>) => void;
  className?: string;
};

export const ToolbarSelectDropdownItem = ({
  buttonText,
  textType,
  onClick,
  registerItem,
  className,
}: ToolbarSelectDropdownItemProps) => {
  const itemRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (itemRef && itemRef.current) {
      registerItem(itemRef);
    }
  }, [itemRef, registerItem]);

  const handleClick = () => {
    onClick(textType);
  };

  return (
    <StyledDropdownItem
      ref={itemRef}
      type="button"
      className={className}
      onClick={handleClick}
    >
      <StyledText>{buttonText}</StyledText>
    </StyledDropdownItem>
  );
};

const StyledDropdownItem = styled('button')(({ theme }) => ({
  margin: '0 8px',
  padding: '8px',
  color: '#050505',
  cursor: 'pointer',
  lineHeight: '16px',
  fontSize: '15px',
  display: 'flex',
  alignContent: 'center',
  flexDirection: 'row',
  flexShrink: 0,
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  borderRadius: '8px',
  border: 0,
  maxWidth: '250px',
  minWidth: '100px',

  '&:first-of-type': {
    marginTop: '8px',
  },

  '&:last-child': {
    marginBottom: '8px',
  },

  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },

  '&:focus-visible': {
    border: `2px solid ${theme.palette.primary.main}`,
  },

  '&.active': {
    backgroundColor: 'rgba(223, 232, 250, 0.3)',
  },
}));

const StyledText = styled('span')({
  display: 'flex',
  lineHeight: '20px',
  flexGrow: 1,
  minWidth: '120px',
});
