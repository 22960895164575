import { Skeleton as MuiSkeleton, styled } from '@mui/material';
import { ReactNode } from 'react';

type SkeletonProps = {
  height?: number;
  width?: number | string;
  variant?: 'rectangular' | 'text' | 'circular' | undefined;
  children?: ReactNode;
};

export const Skeleton = ({
  height,
  width,
  variant = 'rectangular',
  children,
}: SkeletonProps) => {
  return (
    <StyledSkeleton variant={variant} height={height} width={width}>
      {children}
    </StyledSkeleton>
  );
};

const StyledSkeleton = styled(MuiSkeleton)(({ theme }) => ({
  borderRadius: '5px',
  margin: theme.spacing(1, 1),
  display: 'flex',
  flexShrink: 1,
}));
