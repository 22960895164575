import { Button, Stack, styled, Typography } from '@mui/material';
import { ReactNode, SyntheticEvent } from 'react';

import { Tabs } from '@/admin/components';

type ToolbarProps = {
  button?: string;
  children?: ReactNode;
  isButtonDisabled?: boolean;
  tabs?: string[];
  tabsValue?: number;
  tabsWidth?: number;
  title?: string;
  handleButtonClick?: () => void;
  handleChangeTabsValue?: (e: SyntheticEvent, newValue: number) => void;
};

export const Toolbar = ({
  button,
  children,
  isButtonDisabled,
  tabs,
  tabsValue,
  tabsWidth,
  title,
  handleButtonClick,
  handleChangeTabsValue,
}: ToolbarProps) => (
  <StyledToolbar direction="row" spacing={2}>
    {title && <Typography variant="h3">{title}</Typography>}

    {tabs && handleChangeTabsValue && (
      <StyledTabs>
        <Tabs
          ariaLabel="tabs"
          tabs={tabs}
          textColor="secondary"
          value={tabsValue as number}
          width={tabsWidth}
          onChange={handleChangeTabsValue}
        />
      </StyledTabs>
    )}

    {(children || button) && (
      <Stack direction="row" spacing={1}>
        {children}

        {button && (
          <Button
            variant="contained"
            disabled={isButtonDisabled}
            onClick={handleButtonClick}
          >
            {button}
          </Button>
        )}
      </Stack>
    )}
  </StyledToolbar>
);

const StyledToolbar = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '4px',
  color: theme.palette.common.white,
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
}));

const StyledTabs = styled('div')(({ theme }) => ({
  margin: `${theme.spacing(0, 'auto', -1)} !important`,
}));
