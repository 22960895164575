import { SelectChangeEvent, Stack, styled } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, ToggleButtonGroup } from '@/admin/components';
import {
  languagesWithAll,
  sortingOptionsBlogs,
  statusFiltersBlogs,
} from '@/admin/consts/common';
import { BlogsContext } from '@/admin/providers';
import {
  BlogsFiltersNames,
  SortingOrderBlogs,
  BlogStatus,
  Lang,
} from '@/common/types';

type BlogsFiltersProps = {
  language: Lang | '';
  order: SortingOrderBlogs;
  status: BlogStatus | '';
  handleFiltersChange: (filter: BlogsFiltersNames, newValue: string) => void;
  setOrder: (newSortOption: SortingOrderBlogs) => void;
};

export const BlogsFilters = ({
  language,
  order,
  status,
  handleFiltersChange,
  setOrder,
}: BlogsFiltersProps) => {
  const { t } = useTranslation();
  const { changeCurrentPage } = useContext(BlogsContext);

  const handleOrderChange = (e: SelectChangeEvent) => {
    setOrder(e.target.value as SortingOrderBlogs);
    // reset pages when order is changed
    changeCurrentPage(1);
  };

  return (
    <StyledStack direction="row">
      <StyledFilters>
        <ToggleButtonGroup
          ariaLabel="status"
          buttons={statusFiltersBlogs}
          title={`${t('status')}:`}
          value={status}
          onChange={(e, status) => handleFiltersChange('status', status)}
        />

        {/* the laguage filter for large screens */}
        <StyledToggleButtonGroup>
          <ToggleButtonGroup
            ariaLabel="language"
            buttons={languagesWithAll}
            title={`${t('language')}:`}
            value={language}
            onChange={(e, language) =>
              handleFiltersChange('language', language)
            }
          />
        </StyledToggleButtonGroup>

        {/* the laguage filter for small screens */}
        <StyledSelectLanguage>
          <Select
            ariaLabel="language"
            label={`${t('language')}:`}
            options={languagesWithAll}
            value={language}
            onChange={(e) => handleFiltersChange('language', e.target.value)}
            renderValue={(type) => (type ? undefined : <div>{t('all')}</div>)}
          />
        </StyledSelectLanguage>
      </StyledFilters>

      <Stack direction="row" alignItems="flex-end" spacing={2}>
        <StyledSelectSorting>
          <Select
            ariaLabel="Sort by"
            label={`${t('sortBy')}:`}
            options={sortingOptionsBlogs}
            value={order}
            onChange={handleOrderChange}
          />
        </StyledSelectSorting>
      </Stack>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
}));

const StyledFilters = styled('div')(({ theme }) => ({
  display: 'flex',

  '& > div': {
    marginRight: theme.spacing(2),
  },
}));

const StyledToggleButtonGroup = styled('div')({
  '@media only screen and (max-width: 1096px)': {
    display: 'none',
  },
});

const StyledSelectLanguage = styled('div')({
  display: 'none',
  width: '128px',

  '@media only screen and (max-width: 1096px)': {
    display: 'block',
  },
});

const StyledSelectSorting = styled('div')({
  width: '166px',
});
