import {
  Pagination as MuiPagination,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isNumber } from '@/admin/utils/helpers';

type PaginationProps = {
  count: number;
  page: number;
  handleChangePage: (e: unknown, newPage: number) => void;
};

const DEFAULT_INPUT_WIDTH = 40;
const CHAR_WIDTH = 9;

export const Pagination = ({
  count,
  page,
  handleChangePage,
}: PaginationProps) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>(String(page));
  const [inputWidth, setInputWidth] = useState<number>(DEFAULT_INPUT_WIDTH);
  const [isError, setIsError] = useState<boolean>(false);

  // check <value> is invalid
  useEffect(() => {
    setIsError(Number(value) > count || Number(value) === 0);
  }, [value, page, setIsError]);

  // change <inputWidth> if <value> is more than 1 char
  useEffect(() => {
    setInputWidth(
      value.length > 1
        ? DEFAULT_INPUT_WIDTH + (value.length - 1) * CHAR_WIDTH
        : DEFAULT_INPUT_WIDTH
    );
  }, [value, setInputWidth]);

  // update <value> if <page> changes
  useEffect(() => {
    setValue(String(page));
  }, [page]);

  const handleChangeInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = e.target.value;
    const inputValueNumber = Number(inputValue);

    if (isNumber(e, inputValue)) setValue(inputValue);

    if (inputValueNumber <= count && inputValueNumber !== 0) {
      handleChangePage(e, inputValueNumber);
    }
  };

  return (
    <Stack direction="row" spacing={5}>
      <MuiPagination
        count={count}
        page={page}
        shape="rounded"
        size="large"
        variant="outlined"
        onChange={handleChangePage}
      />

      <Stack direction="row" spacing={0.5}>
        <StyledTextField
          error={isError}
          helperText={isError && t('invalidPage')}
          size="small"
          style={{ width: `${inputWidth}px` }}
          value={value}
          onChange={handleChangeInput}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
        />
        <StyledTypography variant="subtitle1">/{count}</StyledTypography>
      </Stack>
    </Stack>
  );
};

const StyledTextField = styled(TextField)({
  '& .MuiFormHelperText-root': {
    marginLeft: '-4px',
    marginRight: 0,
    whiteSpace: 'nowrap',
  },
});

const StyledTypography = styled(Typography)({
  lineHeight: '40px',
});
