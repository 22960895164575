import { Blogs } from '@/admin/components';
import { BlogsProvider, StorageBlobCleanProvider } from '@/admin/providers';

export const BlogsPage = () => (
  <BlogsProvider>
    <StorageBlobCleanProvider>
      <Blogs />
    </StorageBlobCleanProvider>
  </BlogsProvider>
);
