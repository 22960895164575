import { ArrowBackIosNew } from '@mui/icons-material';
import { Button, Dialog, Slide, styled, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref } from 'react';
import { useTranslation } from 'react-i18next';

import { BlogContentHeaderSlug, BlogPreviewBody } from '@/admin/components';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type BlogPreviewProps = {
  isOpen: boolean;
  handleClose: () => void;
};

export const BlogPreview = ({ isOpen, handleClose }: BlogPreviewProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <StyledHeader>
        <StyledHeaderContent>
          <StyledButton startIcon={<ArrowBackIosNew />} onClick={handleClose}>
            {t('editBlog')}
          </StyledButton>

          <Typography variant="h1">{t('blogPreview')}</Typography>

          <StyledBlogContentHeaderSlug>
            <BlogContentHeaderSlug />
          </StyledBlogContentHeaderSlug>
        </StyledHeaderContent>
      </StyledHeader>

      <BlogPreviewBody />
    </Dialog>
  );
};

const StyledHeader = styled('div')(({ theme }) => ({
  boxShadow: `0px 4px 4px ${theme.palette.shadow?.dark}`,
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(3, 4),
  position: 'relative',
}));

const StyledHeaderContent = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '1218px',
  width: '100%',
});

const StyledBlogContentHeaderSlug = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%',

  '& > div': {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  left: theme.spacing(4),
  position: 'absolute',
  textDecoration: 'none',
  top: theme.spacing(4),

  '&:hover': {
    backgroundColor: 'inherit',
  },
}));
