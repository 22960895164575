import { createContext, useState } from 'react';

import { TESTIMONIALS_PER_PAGE } from '@/admin/consts';
import { FC } from '@/admin/types/common';
import {
  removeItemFromSessionStorage,
  getItemFromSessionStorage,
  setItemToSessionStorage,
} from '@/admin/utils/helpers';
import {
  ITestimonialData,
  ITestimonialsFilters,
  SortingOrderTestimonials,
} from '@/common/types';

interface ITestimonialsContext {
  currentPage: number;
  testimonials: ITestimonialData[];
  totalCount: number;
  changeCurrentPage: (page: number) => void;
  changeTestimonials: (testimonials: ITestimonialData[], total: number) => void;
  changeTestimonialStatus: (id: string) => void;
  getTestimonials: (
    filters?: ITestimonialsFilters,
    order?: SortingOrderTestimonials
  ) => ITestimonialData[];
  storeTestimonialFilters: (filters: ITestimonialsFilters) => void;
  resetStoredTestimonials: () => void;
}

const initialContext: ITestimonialsContext = {
  currentPage: 1,
  testimonials: [],
  totalCount: 0,
  changeCurrentPage: () => {},
  changeTestimonials: () => {},
  changeTestimonialStatus: () => {},
  getTestimonials: () => [],
  storeTestimonialFilters: () => {},
  resetStoredTestimonials: () => {},
};

export const TestimonialsContext =
  createContext<ITestimonialsContext>(initialContext);

export const TestimonialsProvider = ({ children }: FC) => {
  const [testimonials, setTestimonials] = useState<ITestimonialData[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const changeCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const changeTestimonials = (
    testimonials: ITestimonialData[],
    total: number
  ) => {
    setTestimonials(testimonials);
    setTotalCount(total);
  };

  const changeTestimonialStatus = (testimonialId: string) => {
    const updatedTestimonials = testimonials.map((testimonial) => {
      if (testimonial.id !== testimonialId) return testimonial;

      return {
        ...testimonial,
        status:
          testimonial.status === 'published' ? 'unpublished' : 'published',
      } as ITestimonialData;
    });

    setTestimonials(updatedTestimonials);
  };

  const getTestimonials = (
    filters?: ITestimonialsFilters,
    order?: SortingOrderTestimonials
  ) => {
    const filtersToApply =
      filters ||
      (getItemFromSessionStorage(
        'testimonialFilters',
        true
      ) as ITestimonialsFilters);

    const filteredTestimonials =
      filtersToApply || order
        ? getFiltedTestimonials(filtersToApply, order)
        : testimonials;

    setTotalCount(filteredTestimonials.length);

    const lastPage =
      Math.ceil(filteredTestimonials.length / TESTIMONIALS_PER_PAGE) || 1;
    const pageNumber = Math.min(lastPage, currentPage);
    const startIndex = (pageNumber - 1) * TESTIMONIALS_PER_PAGE;
    const endIndex = startIndex + TESTIMONIALS_PER_PAGE;

    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
    }

    return filteredTestimonials.slice(startIndex, endIndex);
  };

  const getFiltedTestimonials = (
    filters: ITestimonialsFilters,
    order?: SortingOrderTestimonials
  ) => {
    const {
      language = '',
      source = '',
      status = '',
      reviewContent = '',
    } = filters;

    return testimonials
      .filter((testimonial) => {
        const isLanguageMatch = language
          ? testimonial.language === language
          : true;
        const isSourceMatch = source ? testimonial.source === source : true;
        const isStatusMatch = status ? testimonial.status === status : true;
        const isReviewContentMatch = reviewContent
          ? testimonial.reviewContent
              .toLowerCase()
              .includes(reviewContent.toLowerCase())
          : true;

        return (
          isLanguageMatch &&
          isSourceMatch &&
          isStatusMatch &&
          isReviewContentMatch
        );
      })
      .sort((a, b) => {
        if (order === 'dateAsc') return a.datePublished - b.datePublished;
        else if (order === 'ratingAsc') return a.reviewRating - b.reviewRating;
        else if (order === 'ratingDesc') return b.reviewRating - a.reviewRating;
        else return b.datePublished - a.datePublished;
      });
  };

  const storeTestimonialFilters = (filters: ITestimonialsFilters) => {
    setItemToSessionStorage('testimonialFilters', filters, true);
  };

  const resetStoredTestimonials = () => {
    removeItemFromSessionStorage('testimonialFilters');
  };

  return (
    <TestimonialsContext.Provider
      value={{
        currentPage,
        testimonials,
        totalCount,
        changeCurrentPage,
        changeTestimonials,
        changeTestimonialStatus,
        getTestimonials,
        resetStoredTestimonials,
        storeTestimonialFilters,
      }}
    >
      {children}
    </TestimonialsContext.Provider>
  );
};
