import { SelectChangeEvent, styled } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReadOnlyTextField, Select, SettingsSection } from '@/admin/components';
import { languages } from '@/admin/consts';
import { AppContext } from '@/admin/providers';
import { getLocalizedValue } from '@/admin/utils/helpers';
import { Lang } from '@/common/types';

export const SettingsAccountDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { displayedUserInfo, isAdmin, lang, selectedUser, setLang } =
    useContext(AppContext);

  const [selectedLang, setSelectedLang] = useState<Lang>(lang);

  const { email, name, nameFr } = displayedUserInfo;
  const {
    name: selectedUserName,
    nameFr: selectedUserNameFr,
    userType,
  } = selectedUser;

  const isSelectedUserNetwork = userType === 'network';

  const userName = isSelectedUserNetwork
    ? getLocalizedValue(language, selectedUserName, selectedUserNameFr)
    : getLocalizedValue(language, name, nameFr);
  const userEmail = isSelectedUserNetwork ? '' : email;

  const handleLangChange = (e: SelectChangeEvent) => {
    setSelectedLang(e.target.value as Lang);
  };

  const handleUpdateLang = () => {
    setLang(selectedLang);
  };

  return (
    <SettingsSection
      disabled={lang === selectedLang}
      isButtonHidden={isAdmin}
      title={t('accountDetails')}
      handleUpdate={handleUpdateLang}
    >
      <StyledField>
        <ReadOnlyTextField label={t('firstLastName')} value={userName} />
      </StyledField>

      {userEmail && (
        <StyledField>
          <ReadOnlyTextField label={t('email')} value={userEmail} />
        </StyledField>
      )}

      {!isAdmin && (
        <StyledField>
          <StyledSelect>
            <Select
              ariaLabel={t('language')}
              label={t('language')}
              options={languages}
              value={selectedLang}
              onChange={handleLangChange}
            />
          </StyledSelect>
        </StyledField>
      )}
    </SettingsSection>
  );
};

const StyledField = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),

  '&:last-child': {
    marginBottom: 0,
  },
}));

const StyledSelect = styled('div')({
  width: '178px',
});
