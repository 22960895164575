import { Stack, styled } from '@mui/material';
import { ChangeEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  BlogContentHeaderBlogName,
  BlogContentHeaderLanguage,
  BlogContentHeaderSlug,
  BlogContentHeaderVisibility,
} from '@/admin/components';

type BlogContentHeaderProps = {
  handleVisibilityChange: ChangeEventHandler;
};

export const BlogContentHeader = ({
  handleVisibilityChange,
}: BlogContentHeaderProps) => {
  const { getValues } = useFormContext();

  const status = getValues('status');

  return (
    <StyledContainer>
      <Stack direction="row" spacing={3}>
        <BlogContentHeaderBlogName />

        <BlogContentHeaderLanguage />

        {status !== 'draft' && (
          <BlogContentHeaderVisibility
            handleVisibilityChange={handleVisibilityChange}
          />
        )}
      </Stack>

      <StyledBlogContentHeaderSlug>
        <BlogContentHeaderSlug />
      </StyledBlogContentHeaderSlug>
    </StyledContainer>
  );
};

const StyledContainer = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  minWidth: '750px',
}));

const StyledBlogContentHeaderSlug = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}));
