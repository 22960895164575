import { styled } from '@mui/material';
import { LexicalEditor } from 'lexical';
import { ChangeEvent, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, TextField } from '@/admin/components';
import { INSERT_IMAGE_COMMAND } from '@/admin/components/blogEditor/editorPlugins';
import { ACCEPTED_IMAGE_TYPES, inputFileErrorMessages } from '@/admin/consts';
import { useImageUpload } from '@/admin/hooks';
import { AppContext, useStorageBlobCleanContext } from '@/admin/providers';
import { uploadImageApi } from '@/admin/utils/helpers-api';

type ImageInsertModalProps = {
  editor: LexicalEditor;
  open: boolean;
  onClose: () => void;
};

export const ImageInsertModal = ({
  editor,
  open,
  onClose,
}: ImageInsertModalProps) => {
  const { t } = useTranslation();

  const { selectedUserId, getAccessToken } = useContext(AppContext);
  const { addImageSrc } = useStorageBlobCleanContext();

  const {
    imageLocalURL,
    isLoading,
    error,
    getImageLocalURL,
    getImageFileFromLocalURL,
    handleDeleteImage,
    handleUploadImage,
    verifyImageFormat,
  } = useImageUpload();

  const [altText, setAltText] = useState('');

  const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      const isValidImage = verifyImageFormat(file);
      if (isValidImage) {
        getImageLocalURL(file);
      }
    } else {
      handleDeleteImage();
    }
  };

  const handleAltText = (e: ChangeEvent<HTMLInputElement>) => {
    setAltText(e.target.value);
  };

  const handleConfirm = async () => {
    if (imageLocalURL) {
      const imageFile = await getImageFileFromLocalURL(imageLocalURL);
      const token = await getAccessToken();
      const src = await handleUploadImage(() =>
        uploadImageApi(imageFile, token, selectedUserId)
      );
      if (src) {
        editor.dispatchCommand(INSERT_IMAGE_COMMAND, { src, altText });
        addImageSrc(src);
        handleClose();
      }
    }
  };

  const handleClose = () => {
    handleDeleteImage();
    onClose();
    setAltText('');
  };

  return (
    <Dialog
      buttonAgree={isLoading ? t('uploading') : t('confirm')}
      buttonClose={t('cancel')}
      disableAgreeButton={!imageLocalURL || isLoading || !!error}
      disableCloseButton={isLoading}
      maxWidth="xs"
      open={open}
      title={t('insertImageDialogTitle')}
      handleAgree={handleConfirm}
      handleClose={handleClose}
    >
      <StyledTextField>
        <TextField
          inputProps={{ accept: ACCEPTED_IMAGE_TYPES, disabled: isLoading }}
          label={t('imageUpload')}
          type="file"
          caption="jpg, jpeg, png | 25MB"
          error={!!error}
          helperText={error ? t(inputFileErrorMessages[error]) : ''}
          onChange={handleUploadFile}
        />
      </StyledTextField>

      <StyledTextField>
        <TextField
          label={t('altText')}
          onChange={handleAltText}
          inputProps={{ disabled: isLoading }}
        />
      </StyledTextField>
    </Dialog>
  );
};

const StyledTextField = styled('div')({
  margin: '16px 0',
});
