import { Grid, styled } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  BlogContent,
  BlogInfo,
  BlogPreview,
  Dialog,
  BlogLinkBack,
} from '@/admin/components';
import { SNACKBAR_TITLE_MAX_LENGTH } from '@/admin/consts/blogs';
import { BLOGS_PATH } from '@/admin/consts/paths';
import { useCallbackPrompt, useModal } from '@/admin/hooks';
import {
  AppContext,
  defaultTextEditorContent,
  ThemeContext,
  useStorageBlobCleanContext,
} from '@/admin/providers';
import { getShortenedText } from '@/admin/utils/helpers';
import {
  addBlogApi,
  deleteBlogApi,
  updateBlogApi,
} from '@/admin/utils/helpers-api';
import { IBlogData } from '@/common/types';

type BlogPostProps = {
  isNewBlog: boolean;
  getBlogDataById?: (blogId: string) => void;
};

export const BlogPost = ({ isNewBlog, getBlogDataById }: BlogPostProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { blogId } = useParams();

  const {
    userInfo: { name },
    isTokenExpired,
    getAccessToken,
    setOpenSessionTimeoutModal,
  } = useContext(AppContext);
  const { openSnackbar } = useContext(ThemeContext);

  const {
    formState: { isDirty },
    getValues,
    handleSubmit,
    setValue,
    trigger,
  } = useFormContext();
  const { modalContent, showModal, handleModalClose } = useModal();
  const {
    getImagesFromDom,
    removeAddedImageSrc,
    removeAllImageSrc,
    removeUnusedImageSrc,
  } = useStorageBlobCleanContext();

  // detect the user leaving the page
  const [isLeavingDialogActive, setIsLeavingDialogActive] =
    useState<boolean>(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [isActionDisabled, setIsActionDisabled] = useState<boolean>(false);

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    isLeavingDialogActive
  );

  const blogName = getValues('blogName');
  const trimmedBlogName =
    getShortenedText(blogName, SNACKBAR_TITLE_MAX_LENGTH, true) || t('blog');

  useEffect(() => {
    isDirty !== isLeavingDialogActive && setIsLeavingDialogActive(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (isTokenExpired) {
      setIsLeavingDialogActive(false);

      if (getValues('status') === 'draft') {
        const formData = getValues();
        const defaultSaveData: { [key: string]: string } = {
          blogName: t('untitledBlog'),
          blogNameLowerCased: t('untitledBlog').toLowerCase(),
          blogContent:
            '<p class="editor_paragraph" dir="ltr"><span> </span></p>',
        };

        for (const [key, value] of Object.entries(defaultSaveData)) {
          if (
            !formData[key] ||
            (key === 'blogContent' &&
              formData[key] === defaultTextEditorContent)
          ) {
            setValue(key, value);
          }
        }

        handleSave(true);
      } else {
        getSessionTimeoutModal();
      }
    }
  }, [isTokenExpired]);

  // handling visibility change
  const handleVisibilityChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      showModal({
        message: t('hideDialogMessage'),
        title: t('hideDialogTitle'),
        handleAgree: handleHideConfirm,
      });
      return;
    }

    showModal({
      message: t('unhideDialogMessage'),
      title: t('unhideDialogTitle'),
      handleAgree: handleUnhideConfirm,
    });
  };

  const handleHideConfirm = () => {
    setValue('status', 'hidden', { shouldDirty: true });
    handleModalClose();
  };

  const handleUnhideConfirm = () => {
    setValue('status', 'published', { shouldDirty: true });
    handleModalClose();
  };

  // handling action ERROR
  const handleActionError = (sessionTimeout: boolean = false) => {
    if (sessionTimeout) {
      getSessionTimeoutModal();
      return;
    }
    setIsLeavingDialogActive(true);
    showModal(
      {
        message: t('errorDialogMessage'),
        title: t('errorDialogTitle'),
        handleAgree: handleModalClose,
      },
      true
    );
  };

  // handling DELETE action
  const handleDelete = () => {
    showModal({
      message: t('deleteDialogMessage'),
      title: t('deleteDialogTitle'),
      handleAgree: handleDeleteConfirm,
      handleClose: handleModalClose,
    });
  };

  const handleDeleteConfirm = async () => {
    try {
      setIsLeavingDialogActive(false);

      const token = await getAccessToken();
      let status;

      if (!isNewBlog) {
        status = await deleteBlogApi(getValues('id'), token);
      }

      if (isNewBlog || status === 200) {
        removeAllImageSrc(token);
        openSnackbar(t('deleteSnackbarMessage', { trimmedBlogName }));
        navigate(BLOGS_PATH);
      }
    } catch (error) {
      handleActionError();
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // handling DISABLE actions
  const handleDisableAction = (disabled: boolean) => {
    setIsActionDisabled(disabled);
  };

  // handling PREVIEW action
  const handleOpenPreview = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  // handling SAVE & UPDATE DRAFT action
  const handleSave = (sessionTimeout: boolean = false) => {
    handleSubmit(async (data) => {
      try {
        setIsLeavingDialogActive(false);

        const postData: IBlogData = {
          ...(data as IBlogData),
          status: 'draft',
          updatedBy: name,
        };

        const token = await getAccessToken();

        if (isNewBlog) {
          await handleSubmitNewBlog(postData, token, true, sessionTimeout);
        } else {
          await handleSubmitExistedBlog(postData, token, true, sessionTimeout);
        }
      } catch (error) {
        handleActionError(sessionTimeout);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  };

  // handling PUBLISH action
  const handlePublish = async () => {
    const hasNoError = await trigger();
    const status = getValues('status');
    const authorName = getValues('authorName');

    if (hasNoError && status === 'draft') {
      showModal({
        message: t('publishDialogMessage', { authorName }),
        title: t('publishDialogTitle'),
        handleAgree: handlePublishConfirm,
      });
      return;
    }

    if (hasNoError) {
      showModal({
        message: t('publishUpdateDialogMessage', { authorName }),
        title: t('publishUpdateDialogTitle'),
        handleAgree: handlePublishConfirm,
      });
    }
  };

  const handlePublishConfirm = () => {
    setIsLeavingDialogActive(false);

    handleSubmit(async (data) => {
      try {
        const postData: IBlogData = {
          ...(data as IBlogData),
          status: data.status === 'hidden' ? 'hidden' : 'published',
          updatedBy: name,
        };
        const token = await getAccessToken();

        if (isNewBlog) {
          await handleSubmitNewBlog(postData, token);
        } else {
          await handleSubmitExistedBlog(postData, token);
        }
      } catch (error) {
        handleActionError();
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  };

  // handling SUBMIT
  const handleSubmitNewBlog = async (
    postData: IBlogData,
    token: string,
    isSave: boolean = false,
    sessionTimeout?: boolean
  ) => {
    const { status, blogId } = await addBlogApi(postData, token);

    if (status === 201) {
      const imagesFromDom = getImagesFromDom(
        postData.blogContent,
        postData.imageURL
      );
      removeUnusedImageSrc(token, imagesFromDom);
      navigate(`${BLOGS_PATH}/${blogId}`);
      openSnackbar(
        t(isSave ? 'blogSaveSnackbarMessage' : 'publishSnackbarMessage', {
          trimmedBlogName,
        })
      );

      if (isSave && sessionTimeout) {
        getSessionTimeoutModal(isSave && sessionTimeout);
      }
    }
  };

  const handleSubmitExistedBlog = async (
    postData: IBlogData,
    token: string,
    isSave: boolean = false,
    sessionTimeout?: boolean
  ) => {
    const status = await updateBlogApi(postData, token);

    if (status === 200) {
      const imagesFromDom = getImagesFromDom(
        postData.blogContent,
        postData.imageURL
      );
      removeUnusedImageSrc(token, imagesFromDom);
      openSnackbar(
        t(isSave ? 'blogUpdateSnackbarMessage' : 'publishSnackbarMessage', {
          trimmedBlogName,
        })
      );

      if (isSave && sessionTimeout) {
        getSessionTimeoutModal(isSave && sessionTimeout);
      } else if (!isSave) {
        blogId && getBlogDataById && getBlogDataById(blogId);
        handleModalClose();
      }
    }
  };

  // handling LEAVE modal close
  const handleLeaveConfirm = () => {
    confirmNavigation(async () => {
      const token = await getAccessToken();
      removeAddedImageSrc(token);
    });
  };

  // handling SESSION TIMEOUT modal
  const getSessionTimeoutModal = (autoSave: boolean = false) => {
    setOpenSessionTimeoutModal({
      open: true,
      path: 'blogs',
      autoSave,
      callback: async () => {
        if (!autoSave) {
          const token = await getAccessToken();
          removeAddedImageSrc(token);
        }
      },
    });
  };

  return (
    <>
      <BlogLinkBack
        disabled={isActionDisabled}
        isNewBlog={isNewBlog}
        handleDelete={handleDelete}
        handlePreview={handleOpenPreview}
        handleSave={handleSave}
        handlePublish={handlePublish}
      />

      <StyledGridContainer container>
        <StyledGrid item xs={12} lg={9}>
          <BlogContent handleVisibilityChange={handleVisibilityChange} />
        </StyledGrid>

        <Grid item xs={12} lg={3}>
          <BlogInfo handleDisableAction={handleDisableAction} />
        </Grid>
      </StyledGridContainer>

      <Dialog
        buttonAgree={modalContent.buttonAgree}
        buttonClose={modalContent.buttonClose}
        message={modalContent.message}
        title={modalContent.title}
        open={modalContent.open}
        handleAgree={modalContent.handleAgree}
        handleClose={modalContent.handleClose}
      />

      {/* the user leaving the page */}
      <Dialog
        buttonAgree={t('returnToBlog')}
        buttonClose={t('exitWithoutSaving')}
        message={t('unsavedChangesBlogMessage')}
        title={t('unsavedChanges')}
        open={showPrompt}
        handleAgree={cancelNavigation}
        handleClose={handleLeaveConfirm}
      />

      <BlogPreview isOpen={isPreviewOpen} handleClose={handleClosePreview} />
    </>
  );
};

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: { height: '100%' },
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 4, 3),
}));
