import { Box, styled } from '@mui/material';

import {
  BlogInfoDetails,
  BlogInfoImage,
  BlogInfoSeo,
} from '@/admin/components';

type BlogInfoProps = {
  handleDisableAction: (disabled: boolean) => void;
};

export const BlogInfo = ({ handleDisableAction }: BlogInfoProps) => (
  <StyledBox>
    <BlogInfoSeo />
    <BlogInfoImage handleDisableAction={handleDisableAction} />
    <BlogInfoDetails />
  </StyledBox>
);

export const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: `0px 4px 4px ${theme.palette.shadow?.main}`,
  padding: theme.spacing(0, 3, 3),
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',

  [theme.breakpoints.down('lg')]: {
    backgroundColor: theme.palette.grey[100],
    boxShadow: 'none',
    padding: theme.spacing(0, 5, 5),
    flexDirection: 'row',
    minWidth: '800px',

    ' >*': { flex: 1, width: 'calc(100% / 3)' },
  },
}));
