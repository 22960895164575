import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMConversionOutput,
  ElementFormatType,
  LexicalNode,
  NodeKey,
  ParagraphNode,
} from 'lexical';

export class CustomParagraphNode extends ParagraphNode {
  constructor(key?: NodeKey) {
    super(key);
  }

  static getType(): string {
    return 'custom-paragraph';
  }

  static clone(node: ParagraphNode): ParagraphNode {
    return new ParagraphNode(node.__key);
  }

  static importDOM(): DOMConversionMap | null {
    return {
      ...super.importDOM(),
      p: () => ({
        conversion: convertCustomParagraphElement,
        priority: 1,
      }),
    };
  }
}

function convertCustomParagraphElement(domNode: Node): DOMConversionOutput {
  const node = $createCustomParagraphNode();

  const paragraph = domNode as HTMLElement;
  const align = paragraph.style.textAlign;
  if (align) {
    node.setFormat(align as ElementFormatType);
  }

  return { node };
}

export function $createCustomParagraphNode(): CustomParagraphNode {
  return $applyNodeReplacement(new CustomParagraphNode());
}

export function $isCustomParagraphNode(node: LexicalNode): boolean {
  return node instanceof CustomParagraphNode;
}
