import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import { styled } from '@mui/material';
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  GridSelection,
  KEY_ESCAPE_COMMAND,
  LexicalEditor,
  NodeSelection,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';

import { Icon } from '@/admin/components';
import {
  getSelectedNode,
  sanitizeUrl,
  setFloatingElemPosition,
} from '@/admin/utils/helpers-editor';

type FloatingLinkEditorProps = {
  editor: LexicalEditor;
  isLink: boolean;
  setIsLink: Dispatch<boolean>;
  anchorElem: HTMLElement;
};

export const FloatingLinkEditor = ({
  editor,
  isLink,
  setIsLink,
  anchorElem,
}: FloatingLinkEditorProps) => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [linkUrl, setLinkUrl] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState<
    RangeSelection | GridSelection | NodeSelection | null
  >(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }

    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();

    if (
      selection !== null &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode) &&
      editor.isEditable()
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;

      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;

        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild as HTMLElement;
        }

        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      setFloatingElemPosition(rect, editorElem, anchorElem);
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== 'link-input') {
      if (rootElement !== null) {
        setFloatingElemPosition(null, editorElem, anchorElem);
      }
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl('');
    }

    return true;
  }, [anchorElem, editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (isLink) {
            setIsLink(false);
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_HIGH
      )
    );
  }, [editor, updateLinkEditor, setIsLink, isLink]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <StyledLinkEditor ref={editorRef}>
      {isEditMode ? (
        <input
          ref={inputRef}
          className="linkInput"
          value={linkUrl}
          onChange={(e) => {
            setLinkUrl(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'Escape') {
              e.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== '') {
                  editor.dispatchCommand(
                    TOGGLE_LINK_COMMAND,
                    sanitizeUrl(linkUrl)
                  );
                }
                setEditMode(false);
              }
            }
          }}
        />
      ) : (
        <div className="linkInput">
          <StyledAnchor
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkUrl}
          </StyledAnchor>
          <StyledEditButton
            className="link-edit"
            type="button"
            tabIndex={0}
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              setEditMode(true);
            }}
          >
            <Icon size="md" symbol="edit" />
          </StyledEditButton>
        </div>
      )}
    </StyledLinkEditor>
  );
};

const StyledLinkEditor = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 10,
  maxWidth: '400px',
  width: '100%',
  opacity: 0,
  backgroundColor: theme.palette.common.white,
  boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)',
  borderRadius: '8px',
  transition: 'opacity 0.5s',
  willChange: 'transform',

  '& .linkInput': {
    display: 'block',
    width: 'calc(100% - 24px)',
    boxSizing: 'border-box',
    margin: '8px 12px',
    padding: '8px 12px',
    borderRadius: '15px',
    backgroundColor: theme.palette.grey[200],
    fontSize: '15px',
    color: 'rgb(5, 5, 5)',
    border: 0,
    outline: 0,
    position: 'relative',
    fontFamily: 'inherit',
  },
}));

const StyledAnchor = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  marginRight: '30px',
  textOverflow: 'ellipsis',

  '&:hover': {
    textDecoration: 'underline',
  },
}));

const StyledEditButton = styled('button')({
  width: '35px',
  verticalAlign: '-0.25em',
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  cursor: 'pointer',
});
