import { styled, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SettingsSection, Switch } from '@/admin/components';
import { DEFAULT_BLOG_ACCESS } from '@/admin/consts';
import { AppContext, ThemeContext } from '@/admin/providers';
import {
  getBlogAccessApi,
  updateBlogAccessApi,
} from '@/admin/utils/helpers-api';
import { BlogAccess } from '@/common/types';

export const SettingsPermissions = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: DEFAULT_BLOG_ACCESS,
  });

  const {
    selectedUser: { networkId },
    getAccessToken,
    setIsBlogAccessChanged,
  } = useContext(AppContext);
  const { openSnackbar } = useContext(ThemeContext);

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessToken();
        const {
          data: { broker, company, office },
        } = await getBlogAccessApi(token, networkId);
        reset({ broker: !broker, company: !company, office: !office }); // Update form default value to fetched data
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  }, []);

  const handleUpdatePermissions = async (data: BlogAccess) => {
    try {
      const token = await getAccessToken();
      const status = await updateBlogAccessApi(token, networkId, data);

      if (status === 201) {
        reset(data); // Change form default value for isDirty refresh
        setIsBlogAccessChanged(true);
      }

      openSnackbar(
        status === 201 ? t('blogsAccessSuccess') : t('blogsAccessFailed')
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      openSnackbar(t('blogsAccessFailed'));
    }
  };

  return (
    <SettingsSection
      disabled={!isDirty}
      title={t('permissions')}
      handleUpdate={handleSubmit(handleUpdatePermissions)}
    >
      <Typography variant="h4">{t('blogAccess')}</Typography>

      <StyledSwitch>
        <Controller
          name="broker"
          control={control}
          render={({ field: { value, ...field } }) => (
            <Switch
              {...field}
              ariaLabel="brokers blog access"
              checked={value}
              label={'brokers'}
              textChecked="off"
              textDefault="on"
              width={language === 'en' ? 84 : 110}
            />
          )}
        />
      </StyledSwitch>

      <StyledSwitch>
        <Controller
          name="company"
          control={control}
          render={({ field: { value, ...field } }) => (
            <Switch
              {...field}
              ariaLabel="companies blog access"
              checked={value}
              label={networkId === 6 ? 'teams' : 'companies'}
              textChecked="off"
              textDefault="on"
              width={language === 'en' ? 84 : 110}
            />
          )}
        />
      </StyledSwitch>

      <StyledSwitch>
        <Controller
          name="office"
          control={control}
          render={({ field: { value, ...field } }) => (
            <Switch
              {...field}
              ariaLabel="offices blog access"
              checked={value}
              label="offices"
              textChecked="off"
              textDefault="on"
              width={language === 'en' ? 84 : 110}
            />
          )}
        />
      </StyledSwitch>
    </SettingsSection>
  );
};

const StyledSwitch = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
}));
