import { $getListDepth, $isListItemNode, $isListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  ElementNode,
  INDENT_CONTENT_COMMAND,
} from 'lexical';
import { useEffect } from 'react';

import { getElementNodesInSelection } from '@/admin/utils/helpers-editor';

type ListMaxIndentLevelPluginProps = Readonly<{
  maxDepth: number | null | undefined;
}>;

export const ListMaxIndentLevelPlugin = ({
  maxDepth,
}: ListMaxIndentLevelPluginProps) => {
  const [editor] = useLexicalComposerContext();

  const isIndentPermitted = (maxDepth: number) => {
    const selection = $getSelection();

    if (!$isRangeSelection(selection)) {
      return false;
    }

    const elementNodesInSelection: Set<ElementNode> =
      getElementNodesInSelection(selection);

    let totalDepth = 0;

    elementNodesInSelection.forEach((elementNode) => {
      if ($isListNode(elementNode)) {
        totalDepth = Math.max($getListDepth(elementNode) + 1, totalDepth);
      } else if ($isListItemNode(elementNode)) {
        const parent = elementNode.getParent();

        if (!$isListNode(parent)) {
          throw new Error(
            'ListMaxIndentLevelPlugin: A ListItemNode must have a ListNode for a parent.'
          );
        }

        totalDepth = Math.max($getListDepth(parent) + 1, totalDepth);
      }
    });

    return totalDepth <= maxDepth;
  };

  useEffect(() => {
    return editor.registerCommand(
      INDENT_CONTENT_COMMAND,
      () => !isIndentPermitted(maxDepth ?? 7),
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, maxDepth]);
  return null;
};
