import { SelectChangeEvent, Stack, styled } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, ToggleButtonGroup } from '@/admin/components';
import {
  languagesWithAll,
  shortLanguagesWithAll,
  sortingOptionsTestimonials,
  sourceFiltersTestimonials,
  statusFiltersTestimonials,
} from '@/admin/consts/common';
import { AppContext, TestimonialsContext } from '@/admin/providers';
import {
  Lang,
  SortingOrderTestimonials,
  TestimonialsFiltersNames,
  TestimonialSource,
  TestimonialStatus,
} from '@/common/types';

type TestimonialsFiltersProps = {
  language: Lang | '';
  order: SortingOrderTestimonials;
  source: TestimonialSource | '';
  status: TestimonialStatus | '';
  handleFiltersChange: (
    filter: TestimonialsFiltersNames,
    newValue: string
  ) => void;
  setOrder: (newSortOption: SortingOrderTestimonials) => void;
};

export const TestimonialsFilters = ({
  language,
  order,
  source,
  status,
  handleFiltersChange,
  setOrder,
}: TestimonialsFiltersProps) => {
  const { t } = useTranslation();
  const {
    displayedUserInfo: { displayTestimonials },
  } = useContext(AppContext);
  const { changeCurrentPage } = useContext(TestimonialsContext);

  const handleOrderChange = (e: SelectChangeEvent) => {
    setOrder(e.target.value as SortingOrderTestimonials);
    // reset page number when order is changed
    changeCurrentPage(1);
  };

  return (
    <>
      <StyledTestimonialsFilters>
        <Stack direction="row" justifyContent="space-between">
          <StyledFilters>
            {/* the status filter for large screens */}
            <ToggleButtonGroup
              ariaLabel="Status"
              buttons={statusFiltersTestimonials}
              title={`${t('status')}:`}
              value={status}
              onChange={(e, status) => handleFiltersChange('status', status)}
              disableTooltip={displayTestimonials}
              tooltipTitle={t('testimonialsDisabled')}
            />
            {/* the source filter for large screens */}
            <StyledToggleButtonGroupSource>
              <ToggleButtonGroup
                ariaLabel="Source"
                buttons={sourceFiltersTestimonials}
                title={`${t('source')}:`}
                value={source}
                onChange={(e, source) => handleFiltersChange('source', source)}
              />
            </StyledToggleButtonGroupSource>
            {/* the source filter for small screens */}
            <StyledSelectSource>
              <Select
                ariaLabel="Source"
                label={`${t('source')}:`}
                options={sourceFiltersTestimonials}
                value={source}
                renderValue={(type) =>
                  type ? undefined : <div>{t('all')}</div>
                }
                onChange={(e) => handleFiltersChange('source', e.target.value)}
              />
            </StyledSelectSource>
            {/* the language filter for large screens */}
            <StyledToggleButtonGroupLanguage>
              <ToggleButtonGroup
                ariaLabel="Language"
                buttons={shortLanguagesWithAll}
                title={`${t('language')}:`}
                value={language}
                onChange={(e, language) =>
                  handleFiltersChange('language', language)
                }
              />
            </StyledToggleButtonGroupLanguage>
            {/* the language filter for small screens */}
            <StyledSelectLanguage>
              <Select
                ariaLabel="Language"
                label={`${t('language')}:`}
                options={languagesWithAll}
                value={language}
                renderValue={(type) =>
                  type ? undefined : <div>{t('all')}</div>
                }
                onChange={(e) =>
                  handleFiltersChange('language', e.target.value)
                }
              />
            </StyledSelectLanguage>
          </StyledFilters>

          <Stack direction="row" alignItems="flex-end" spacing={2}>
            <StyledSelectSorting>
              <Select
                ariaLabel="Sort by"
                label={`${t('sortBy')}:`}
                options={sortingOptionsTestimonials}
                value={order}
                onChange={handleOrderChange}
              />
            </StyledSelectSorting>
          </Stack>
        </Stack>
      </StyledTestimonialsFilters>
    </>
  );
};

const StyledTestimonialsFilters = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const StyledFilters = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,

  '& > div': {
    marginRight: theme.spacing(2),
  },
}));

const StyledToggleButtonGroupSource = styled('div')({
  '@media only screen and (max-width: 1464px)': {
    display: 'none',
  },
});

const StyledSelectSource = styled('div')({
  display: 'none',
  width: '145px',

  '@media only screen and (max-width: 1464px)': {
    display: 'block',
  },
});

const StyledToggleButtonGroupLanguage = styled('div')({
  '@media only screen and (max-width: 1520px)': {
    display: 'none',
  },
});

const StyledSelectLanguage = styled('div')({
  display: 'none',
  width: '128px',

  '@media only screen and (max-width: 1520px)': {
    display: 'block',
  },

  '@media only screen and (max-width: 1464px)': {
    marginLeft: 'auto',
  },
});

const StyledSelectSorting = styled('div')({
  width: '160px',
});
