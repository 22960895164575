import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { Testimonial } from '@/admin/components';
import { ITestimonialData } from '@/common/types';

type DashboardTestimonialsProps = {
  testimonials: ITestimonialData[];
  setCurrentTestimonial: (testimonial: ITestimonialData) => void;
  setIsTestimonialExpanded?: (testimonial: boolean) => void;
  isTestimonialExpanded?: boolean;
  isAdmin?: boolean;
};

export const DashboardTestimonials = ({
  testimonials,
  setCurrentTestimonial,
  setIsTestimonialExpanded,
  isTestimonialExpanded,
}: DashboardTestimonialsProps) => {
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    setCurrentTestimonial(testimonials[index]);
  }, [index]);

  const handlePrevClick = () => {
    setIndex(index - 1);
  };

  const handleNextClick = () => {
    setIndex(index + 1);
  };

  return (
    <StyledDashboardTestimonials>
      <IconButton
        color="primary"
        disabled={index === 0}
        onClick={handlePrevClick}
      >
        <ArrowBackIosNewIcon />
      </IconButton>

      <StyledContentWrapper>
        <StyledContent style={{ transform: `translateX(${-index * 100}%)` }}>
          {testimonials.map((testimonial, index) => (
            <StyledTestimonial key={index}>
              <Testimonial
                id={testimonial.id}
                authorName={
                  testimonial.reviewName ||
                  `${testimonial.reviewFirstName} ${testimonial.reviewLastName}`
                }
                content={testimonial.reviewContent}
                date={testimonial.datePublished}
                networkId={testimonial.networkId}
                rating={testimonial.reviewRating}
                source={testimonial.source}
                status={testimonial.status}
                setIsTestimonialExpanded={setIsTestimonialExpanded}
                isTestimonialExpanded={isTestimonialExpanded}
                reviewLikeRating={testimonial.reviewLikeRating}
                page="dashboard"
              />
            </StyledTestimonial>
          ))}
        </StyledContent>
      </StyledContentWrapper>

      <IconButton
        color="primary"
        disabled={index === testimonials.length - 1}
        onClick={handleNextClick}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </StyledDashboardTestimonials>
  );
};

const StyledDashboardTestimonials = styled('div')({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
});

const StyledContentWrapper = styled('div')({
  overflow: 'hidden',
});

const StyledContent = styled('div')({
  alignItems: 'center',
  display: 'flex',
  transition: 'transform .3s',
});

const StyledTestimonial = styled('div')(({ theme }) => ({
  flexShrink: 0,
  padding: theme.spacing(0, 1),
  width: '100%',
}));
