import { Tab, Tabs as MuiTabs } from '@mui/material';
import { SyntheticEvent } from 'react';

const tabProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

type TabsProps = {
  ariaLabel: string;
  tabs: string[];
  value: number;
  onChange: (e: SyntheticEvent, newValue: number) => void;
  textColor?: 'primary' | 'secondary' | 'inherit';
  width?: number;
};

export const Tabs = ({
  ariaLabel,
  tabs,
  textColor,
  value,
  width,
  onChange,
}: TabsProps) => (
  <MuiTabs
    textColor={textColor}
    value={value}
    onChange={onChange}
    aria-label={ariaLabel}
  >
    {tabs.map((tab, index) => (
      <Tab style={{ width }} key={tab} label={tab} {...tabProps(index)} />
    ))}
  </MuiTabs>
);
