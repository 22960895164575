import { ListItemNode, SerializedListItemNode } from '@lexical/list';
import {
  DOMExportOutput,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  DOMConversionMap,
  DOMConversionOutput,
  ElementFormatType,
  $applyNodeReplacement,
} from 'lexical';

interface SerializedCustomListItemNode
  extends Omit<SerializedListItemNode, 'type'> {
  type: 'custom-listitem' | 'listitem';
}

export class CustomListItemNode extends ListItemNode {
  constructor(value?: number, checked?: boolean, key?: NodeKey) {
    super(value, checked, key);
  }

  static getType(): string {
    return 'custom-listitem';
  }

  static clone(node: CustomListItemNode): CustomListItemNode {
    return new CustomListItemNode(node.__value, node.__checked, node.__key);
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const { element } = super.exportDOM(editor);

    if (element) {
      const formatType = this.getFormatType();
      element.style.textAlign = formatType;

      const direction = this.getDirection();
      if (direction) {
        element.dir = direction;
      }
    }

    return {
      element,
    };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      ...super.importDOM(),
      li: () => ({
        conversion: convertCustomListItemElement,
        priority: 1,
      }),
    };
  }

  static importJSON(
    serializedNode: SerializedCustomListItemNode
  ): CustomListItemNode {
    const node = new CustomListItemNode(
      serializedNode.value,
      serializedNode.checked
    );
    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);
    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'custom-listitem',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;
  }
}

function convertCustomListItemElement(domNode: Node): DOMConversionOutput {
  const checked =
    domNode instanceof HTMLElement &&
    domNode.getAttribute('aria-checked') === 'true';

  const node = $createCustomListItemNode(checked);

  const li = domNode as HTMLElement;
  const align = li.style.textAlign;
  if (align) {
    node.setFormat(align as ElementFormatType);
  }

  return { node };
}

export function $createCustomListItemNode(
  checked?: boolean
): CustomListItemNode {
  return $applyNodeReplacement(new CustomListItemNode(undefined, checked));
}

export function $isCustomListItemNode(node: LexicalNode): boolean {
  return node instanceof CustomListItemNode;
}
