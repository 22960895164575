import { yupResolver } from '@hookform/resolvers/yup';
import { ReactNode } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { AnnouncementsValues } from '@/common/types';

type AnnouncementsFormProviderProps = {
  children: ReactNode;
  defaultValues: AnnouncementsValues;
};

export const AnnouncementsFormProvider = ({
  children,
  defaultValues,
}: AnnouncementsFormProviderProps) => {
  const { t } = useTranslation();

  const announcementSchema = yup.object().shape({
    buttonStyle: yup.string().optional(),
    buttonText: yup.string().optional(),
    buttonUrl: yup.string().optional(),
    expiryDate: yup.number().nullable(true).optional(),
    image: yup.string().optional(),
    launchDate: yup.number().nullable(true).optional(),
    paragraph: yup.string().when('visibility', {
      is: 'visible',
      then: yup.string().required(t('announcementsParagraphRequired')),
      otherwise: yup.string().optional(),
    }),
    title: yup.string().when('visibility', {
      is: 'visible',
      then: yup.string().required(t('announcementsTitleRequired')),
    }),
    visibility: yup.string().required(),
  });
  // error validation for dates occurs in AnnouncementForm; invalid dates do not block submission 

  const announcementsSchema = yup.object().shape({
    en0: announcementSchema,
    en1: announcementSchema,
    en2: announcementSchema,
    fr0: announcementSchema,
    fr1: announcementSchema,
    fr2: announcementSchema,
  });

  const methods = useForm<AnnouncementsValues>({
    mode: 'onTouched',
    resolver: yupResolver(announcementsSchema),
    defaultValues,
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
