import {
  styled,
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ISelectOption } from '@/admin/types/common';

type ToggleButtonGroupProps = {
  ariaLabel: string;
  buttons: ISelectOption[];
  title: string;
  value: string;
  onChange: (e: SyntheticEvent, newValue: string) => void;
  tooltipTitle?: string;
  disabled?: boolean;
  disableTooltip?: boolean;
};

export const ToggleButtonGroup = ({
  ariaLabel,
  buttons,
  title,
  value,
  onChange,
  disabled,
  tooltipTitle,
  disableTooltip,
}: ToggleButtonGroupProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Tooltip
        arrow
        placement="top"
        title={tooltipTitle || ''}
        disableHoverListener={disableTooltip}
      >
        <StyledTypography
          variant="caption"
          className={disabled ? 'disabled' : ''}
        >
          {title}
        </StyledTypography>
      </Tooltip>

      <StyledMuiToggleButtonGroup
        className={disabled ? 'disabled' : ''}
        color="primary"
        value={disabled ? 'none' : value}
        exclusive
        onChange={onChange}
        aria-label={ariaLabel}
        disabled={disabled}
      >
        {buttons.map((button) => (
          <ToggleButton key={button.value} value={button.value} size="small">
            {t(button.label)}
          </ToggleButton>
        ))}
      </StyledMuiToggleButtonGroup>
    </div>
  );
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(0.5),
  '&.disabled': {
    color: theme.palette.grey[400],
  },
}));

const StyledMuiToggleButtonGroup = styled(MuiToggleButtonGroup)({
  '&.disabled': {
    boxShadow: 'none',
  },
});
