import { Box, Typography, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getLocalizedDate } from '@/admin/utils/helpers';

export const BlogInfoDetails = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const { getValues } = useFormContext();

  const dateCreated = getLocalizedDate(
    language,
    getValues('dateCreated') || new Date().toString()
  );
  const dateUpdated = getValues('dateUpdated');
  const lastSaved = dateUpdated && getLocalizedDate(language, dateUpdated);
  const updatedBy = getValues('updatedBy');
  const status = getValues('status');

  return (
    <StyledBox>
      <Typography variant="h3">{t('blogDetails')}</Typography>

      <StyledTextBox>
        <Typography variant="caption">{`${t('dateCreated')}:`}</Typography>
        <Typography>{dateCreated}</Typography>
      </StyledTextBox>

      <StyledTextBox>
        <Typography variant="caption">{`${t('lastSaved')}:`}</Typography>
        <Typography>
          {lastSaved || t('unsaved')}
          {dateUpdated && updatedBy && ` ${t('by')} ${updatedBy}`}
        </Typography>
      </StyledTextBox>

      <StyledTextBox>
        <Typography variant="caption">{`${t('status')}:`}</Typography>
        <Typography>{t(status)}</Typography>
      </StyledTextBox>
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const StyledTextBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));
