import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { LexicalEditor } from 'lexical';

import { Icon } from '@/admin/components';
import { ToolbarButton } from '@/admin/components/blogEditor/editorPlugins';

type ToolbarListProps = {
  editor: LexicalEditor;
  isBulletedList: boolean;
  isNumberedList: boolean;
};

export const ToolbarList = ({
  editor,
  isBulletedList,
  isNumberedList,
}: ToolbarListProps) => {
  const handleBulletedListClick = () => {
    editor.dispatchCommand(
      isBulletedList ? REMOVE_LIST_COMMAND : INSERT_UNORDERED_LIST_COMMAND,
      undefined
    );
  };

  const handleNumberedListClick = () => {
    editor.dispatchCommand(
      isNumberedList ? REMOVE_LIST_COMMAND : INSERT_ORDERED_LIST_COMMAND,
      undefined
    );
  };

  return (
    <>
      <ToolbarButton
        ariaLabel="Format bulleted list"
        onClick={handleBulletedListClick}
        className={isBulletedList ? 'active' : ''}
      >
        <Icon size="sm" symbol="format_list_bulleted" />
      </ToolbarButton>

      <ToolbarButton
        ariaLabel="Format numbered list"
        onClick={handleNumberedListClick}
        className={isNumberedList ? 'active' : ''}
      >
        <Icon size="sm" symbol="format_list_numbered" />
      </ToolbarButton>
    </>
  );
};
