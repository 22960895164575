import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

import { Icon } from '@/admin/components';

type EmptyStateProps = {
  message: string;
  title: string;
  icon?: string;
  iconFill?: 0 | 1;
  linkText?: string;
  linkTo?: string;
};

export const EmptyState = ({
  icon = 'article',
  iconFill = 1,
  linkText,
  linkTo,
  message,
  title,
}: EmptyStateProps) => (
  <StyledEmptyState>
    <Icon fill={iconFill} symbol={icon as string} size="xlg" />

    <StyledTypography variant="h3">{title}</StyledTypography>
    <Typography variant="body1">{message}</Typography>

    {linkText && linkTo && (
      <Link to={linkTo}>
        <StyledButton
          color="primary"
          variant="contained"
          startIcon={<Icon symbol="add" size="md" />}
        >
          {linkText}
        </StyledButton>
      </Link>
    )}
  </StyledEmptyState>
);

const StyledEmptyState = styled('div')(({ theme }) => ({
  boxSizing: 'content-box',
  margin: '0 auto',
  maxWidth: '360px',
  padding: theme.spacing(4),
  textAlign: 'center',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0, 3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));
