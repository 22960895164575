import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { SyntheticEvent } from 'react';

import { IGoogleBusinessData } from '@/common/types';

type TestimonialsBusinessPickerProps = {
  handleLocationChange: (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => void;
  googleLocations: IGoogleBusinessData[];
  preCheckedValues: string[];
};

export const TestimonialsBusinessPicker = ({
  handleLocationChange,
  googleLocations,
  preCheckedValues,
}: TestimonialsBusinessPickerProps) => {
  return (
    <FormControl>
      <FormGroup>
        {googleLocations?.length > 1 &&
          googleLocations.map((business) => (
            <FormControlLabel
              key={business.name}
              control={
                <Checkbox checked={preCheckedValues.includes(business.name)} />
              }
              label={business.title}
              value={business.name}
              onChange={handleLocationChange}
            />
          ))}
      </FormGroup>
    </FormControl>
  );
};
