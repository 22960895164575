import { HeadingNode, HeadingTagType } from '@lexical/rich-text';
import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  NodeKey,
} from 'lexical';

export class CustomHeadingNode extends HeadingNode {
  constructor(tag: HeadingTagType, key?: NodeKey) {
    super(tag, key);
  }

  static getType(): string {
    return 'custom-heading';
  }

  static clone(node: CustomHeadingNode): CustomHeadingNode {
    return new CustomHeadingNode(node.__tag, node.__key);
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const { element } = super.exportDOM(editor);

    if (element) {
      const formatType = this.getFormatType();
      element.style.textAlign = formatType;

      const direction = this.getDirection();
      if (direction) {
        element.dir = direction;
      }
    }

    return {
      element,
    };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      ...super.importDOM(),
      h1: () => ({
        conversion: convertCustomHeadingElement,
        priority: 1,
      }),
      h2: () => ({
        conversion: convertCustomHeadingElement,
        priority: 1,
      }),
      h3: () => ({
        conversion: convertCustomHeadingElement,
        priority: 1,
      }),
      h4: () => ({
        conversion: convertCustomHeadingElement,
        priority: 1,
      }),
      h5: () => ({
        conversion: convertCustomHeadingElement,
        priority: 1,
      }),
      h6: () => ({
        conversion: convertCustomHeadingElement,
        priority: 1,
      }),
    };
  }
}

function convertCustomHeadingElement(domNode: Node): DOMConversionOutput {
  const nodeName = domNode.nodeName.toLowerCase();
  let node = null;
  if (
    nodeName === 'h1' ||
    nodeName === 'h2' ||
    nodeName === 'h3' ||
    nodeName === 'h4' ||
    nodeName === 'h5' ||
    nodeName === 'h6'
  ) {
    node = $createCustomHeadingNode(nodeName);

    const heading = domNode as HTMLElement;
    const align = heading.style.textAlign;
    if (align) {
      node.setFormat(align as ElementFormatType);
    }
  }
  return { node };
}

export function $createCustomHeadingNode(
  tag: HeadingTagType
): CustomHeadingNode {
  return $applyNodeReplacement(new CustomHeadingNode(tag));
}

export function $isCustomHeadingNode(node: LexicalNode): boolean {
  return node instanceof CustomHeadingNode;
}
