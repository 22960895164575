import {
  $applyNodeReplacement,
  DOMConversionMap,
  NodeKey,
  TextNode,
  LexicalNode,
  $createTextNode,
  DOMConversionOutput,
} from 'lexical';

export class CustomTextNode extends TextNode {
  constructor(text: string, key?: NodeKey) {
    super(text, key);
  }

  static getType(): string {
    return 'custom-text';
  }

  static clone(node: CustomTextNode): CustomTextNode {
    return new CustomTextNode(node.__text, node.__key);
  }

  static importDOM(): DOMConversionMap<HTMLElement> | null {
    return {
      br: () => ({
        conversion: convertLineBreakToSpan,
        priority: 1,
      }),
    };
  }
}

function convertLineBreakToSpan(): DOMConversionOutput {
  return {
    node: $createTextNode(),
  };
}

export function $createCustomTextNode(text = ''): TextNode {
  return $applyNodeReplacement(new CustomTextNode(text));
}

export function $isCustomTextNode(
  node: LexicalNode | null | undefined
): node is CustomTextNode {
  return node instanceof CustomTextNode;
}
