import { TableBody, TableCell, TableRow } from '@mui/material';

import { EmptyState } from '@/admin/components';

type BlogsEmptyStateProps = {
  message: string;
  title: string;
  linkText?: string;
  linkTo?: string;
};

const TABLE_CELLS_NUM = 4;

export const BlogsEmptyState = ({
  linkText,
  linkTo,
  message,
  title,
}: BlogsEmptyStateProps) => (
  <TableBody>
    <TableRow tabIndex={-1}>
      <TableCell colSpan={TABLE_CELLS_NUM}>
        <EmptyState
          linkText={linkText}
          linkTo={linkTo}
          message={message}
          title={title}
        />
      </TableCell>
    </TableRow>
  </TableBody>
);
