import { EditPrivacyPolicy } from '@/admin/components/privacyPolicyPost/EditPrivacyPolicy';
import { PrivacyPoliciesProvider } from '@/admin/providers';

export const EditPrivacyPoliciesPage = () => {
  return (
    <PrivacyPoliciesProvider>
      <EditPrivacyPolicy />
    </PrivacyPoliciesProvider>
  );
};
