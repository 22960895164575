import { REACT_APP_PROXY_API_URL } from '@/admin/config/variables';
import {
  DELETE_HERO_IMAGE_URL,
  FETCH_HERO_IMAGE_URL,
  STORAGE_BLOB_URL,
} from '@/admin/consts';

export const uploadImageApi = async (
  file: File,
  token: string,
  userId: number
) => {
  const formData = new FormData();
  formData.append('file', file);
  if (userId) formData.append('userId', `${userId}`);

  const res = await fetch(
    `${REACT_APP_PROXY_API_URL}${STORAGE_BLOB_URL}/blog`,
    {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    }
  );
  const {
    data: { url },
  } = await res.json();

  return url as string;
};

export const uploadImageToBossApi = async (
  file: File,
  croppedFile: File,
  networkId: number,
  userId: number,
  userType: string
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('userId', String(userId));
  formData.append('websiteType', userType);
  formData.append('networkId', String(networkId));
  formData.append('imageType', 'HEROIMAGE');
  formData.append('croppedFile', croppedFile);

  const res = await fetch(`${REACT_APP_PROXY_API_URL}${FETCH_HERO_IMAGE_URL}`, {
    method: 'POST',
    body: formData,
    redirect: 'follow',
  });
  const { status, data } = await res.json();

  return { status, data };
};

export const deleteImageApi = async (fileName: string, token: string) => {
  const url = `${REACT_APP_PROXY_API_URL}${STORAGE_BLOB_URL}/${fileName}`;
  const res = await fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { status } = await res.json();
  return status;
};

export const deleteImageFromBossApi = async (
  fileName: string,
  croppedFileName: string,
  userId: number,
  websiteType: string,
  networkId: number
) => {
  const data = {
    fileName,
    croppedFileName,
    userId,
    websiteType,
    networkId,
    imageType: 'HEROIMAGE',
  };
  const res = await fetch(
    `${REACT_APP_PROXY_API_URL}${DELETE_HERO_IMAGE_URL}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }
  );
  const { status } = await res.json();

  return status;
};
