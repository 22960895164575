import {
  Search,
  VisibilityOutlined,
  VisibilityOffOutlined,
} from '@mui/icons-material';
import {
  IconButton,
  OutlinedInput,
  Stack,
  Tooltip,
  styled,
} from '@mui/material';
import { ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg, Top } from '@/admin/components';
import { GoogleIcon } from '@/admin/icons';
import { AppContext } from '@/admin/providers';

type TestimonialsTopProps = {
  searchValue: string | null;
  handleChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  handleClickSearch: () => void;
  handleOpenModal: () => void;
  googleButtonAction: () => void;
  googleSignInStatus: string;
  prefersNoAlert: boolean | null;
};

export const TestimonialsTop = ({
  searchValue,
  handleChangeSearch,
  handleClickSearch,
  handleOpenModal,
  googleButtonAction,
  googleSignInStatus,
  prefersNoAlert,
}: TestimonialsTopProps) => {
  const { t } = useTranslation();
  const {
    displayedUserInfo: { displayTestimonials },
  } = useContext(AppContext);

  return (
    <Top title={t('testimonials')}>
      <Stack alignItems="center" direction="row" spacing={3}>
        {(prefersNoAlert || googleSignInStatus === 'valid') && (
          <Tooltip title={t('editGoogle')} arrow placement="left">
            <StyledIconButton onClick={googleButtonAction}>
              <IconSvg>
                <GoogleIcon />
              </IconSvg>
            </StyledIconButton>
          </Tooltip>
        )}
        <StyledIconButton onClick={handleOpenModal}>
          {displayTestimonials ? (
            <VisibilityOutlined />
          ) : (
            <VisibilityOffOutlined />
          )}
        </StyledIconButton>

        <StyledSearchByTitle>
          <OutlinedInput
            fullWidth
            placeholder={t('searchTestimonialContent')}
            value={searchValue}
            onChange={handleChangeSearch}
            endAdornment={
              <IconButton onClick={handleClickSearch}>
                <Search />
              </IconButton>
            }
          />
        </StyledSearchByTitle>
      </Stack>
    </Top>
  );
};

const StyledSearchByTitle = styled('div')({
  width: '290px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderWidth: '1.5px',
  borderStyle: 'solid',
  borderRadius: '8px',
  color: theme.palette.primary.main,
}));
