import { Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  PrivacyPolicyPost,
  EditPrivacyPoliciesSkeleton,
  Error,
} from '@/admin/components';
import {
  defaultPrivacyPolicyValues,
  PrivacyPoliciesContext,
} from '@/admin/providers';
import { PrivacyPolicyFormProvider } from '@/admin/providers/form/PrivacyPolicyFormProvider';
import { IPrivacyPolicyValues } from '@/common/types';

export const EditPrivacyPolicy = () => {
  const { privacyPolicyId } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation();
  const { allPolicies, isLoading, isError, setIsError } = useContext(
    PrivacyPoliciesContext
  );

  const [privacyPoliciesData, setPrivacyPoliciesData] =
    useState<IPrivacyPolicyValues>(defaultPrivacyPolicyValues);

  const getPrivacyPoliciesDataById = async (PrivacyPoliciesId: string) => {
    try {
      const policy = allPolicies.find(
        (policy) => policy.id === PrivacyPoliciesId
      );

      if (policy) {
        setIsError(false);
        setPrivacyPoliciesData(policy);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (privacyPolicyId) {
      getPrivacyPoliciesDataById(privacyPolicyId);
    }
  }, [allPolicies, privacyPolicyId]);

  const handleGoBack = () => {
    navigate(-1);
  };

  if (isLoading) return <EditPrivacyPoliciesSkeleton />;

  if (isError)
    return (
      <Error title={t('errorOccurredTitle')}>
        <Typography variant="body1">
          {`${t('errorOccurredRefreshMessage')} ${t('or')} `}
          <span onClick={handleGoBack}>{t('errorOccurredBackMessage')}</span>
        </Typography>
      </Error>
    );

  return (
    <PrivacyPolicyFormProvider defaultValues={privacyPoliciesData}>
      <PrivacyPolicyPost
        getPrivacyPolicyDataById={getPrivacyPoliciesDataById}
      />
    </PrivacyPolicyFormProvider>
  );
};
