import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { TreeView } from '@lexical/react/LexicalTreeView';
import { styled } from '@mui/material';

export const TreeViewPlugin = () => {
  const [editor] = useLexicalComposerContext();

  return (
    <StyledDiv>
      <TreeView
        viewClassName="tree-view-output"
        timeTravelPanelClassName="debug-timetravel-panel"
        timeTravelButtonClassName="debug-timetravel-button"
        timeTravelPanelSliderClassName="debug-timetravel-panel-slider"
        timeTravelPanelButtonClassName="debug-timetravel-panel-button"
        editor={editor}
      />
    </StyledDiv>
  );
};

const StyledDiv = styled('div')({
  '& .tree-view-output': {
    display: 'block',
    background: '#222',
    color: '#fff',
    padding: 0,
    fontSize: '12px',
    margin: '1px auto 10px auto',
    position: 'relative',
    overflow: 'auto',
    borderRadius: '4px',
  },

  '& .debug-timetravel-panel': {
    overflow: 'hidden',
    padding: '0 0 10px 0',
    margin: 'auto',
    display: 'flex',
  },

  '& .debug-timetravel-button': {
    border: 0,
    padding: 0,
    fontSize: '12px',
    top: '10px',
    right: '15px',
    position: 'absolute',
    background: 'none',
    color: '#fff',
  },

  '& .debug-timetravel-panel-slider': {
    padding: 0,
    flex: 8,
  },

  '& .debug-timetravel-panel-button': {
    padding: 0,
    border: 0,
    background: 'none',
    flex: 1,
    color: '#fff',
    fontSize: '12px',
  },

  '& .debug-treetype-button:hover, & .debug-timetravel-button:hover, & .debug-timetravel-panel-button:hover':
    {
      textDecoration: 'underline',
    },
});
