import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  MenuItem,
  styled,
  Select,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/admin/components';
import {
  ADMIN_TOOLBAR_HEIGHT,
  shortLanguages,
  headerLogoByBrand,
} from '@/admin/consts';
import { AppContext } from '@/admin/providers';
import { FC } from '@/admin/types/common';
import { img_url, getBrand } from '@/admin/utils/helpers';
import { Lang } from '@/common/types';

export const AdminToolbarContainer = ({ children }: FC) => {
  const {
    isBossLogin,
    lang,
    userInfo: { firstName, lastName, networkId, role },
    handleLogout,
    setLang,
  } = useContext(AppContext);
  const { t } = useTranslation();

  const isM3TechAdmin = role === 'm3Tech admin';
  const brandLogo =
    isM3TechAdmin || !networkId
      ? headerLogoByBrand['m3tech']
      : headerLogoByBrand[getBrand(networkId)];

  const handleLanguageChange = (e: SelectChangeEvent) => {
    setLang(e.target.value as Lang);
  };

  return (
    <StyledAdminToolbar>
      <StyledLogo
        src={`${img_url}/images/logos/${brandLogo[lang]}`}
        alt="brand logo"
        className={isM3TechAdmin ? 'm3logo' : ''}
      />

      <StyledName variant="h3">
        <div>{firstName}</div>
        <div>{lastName}</div>
      </StyledName>

      <StyledSearch>{children}</StyledSearch>

      {!isBossLogin && (
        <>
          <StyledLangSelect>
            <Icon symbol="language" size="md" />
            <Select
              inputProps={{ 'aria-label': 'language' }}
              value={lang}
              onChange={handleLanguageChange}
              IconComponent={ExpandMoreIcon}
            >
              {shortLanguages.map((lang) => (
                <MenuItem key={lang.value} value={lang.value}>
                  {t(lang.label)}
                </MenuItem>
              ))}
            </Select>
          </StyledLangSelect>

          <StyledButton
            startIcon={<Icon size="md" symbol="logout" />}
            onClick={handleLogout}
          >
            {t('logout')}
          </StyledButton>
        </>
      )}
    </StyledAdminToolbar>
  );
};

const StyledAdminToolbar = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  boxShadow: `2px 0px 4px ${theme.palette.shadow?.dark}`,
  display: 'flex',
  flexShrink: 0,
  padding: theme.spacing(0, 5, 0, 5),
  zIndex: 1250,
  height: ADMIN_TOOLBAR_HEIGHT,
}));

const StyledLogo = styled('img')({
  width: '125px',
  '&.m3logo': { width: '86px' },
});

const StyledName = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 4.5),
  maxWidth: '175px',
  width: '100%',
}));

const StyledSearch = styled('div')(({ theme }) => ({
  alignSelf: 'flex-start',
  flexGrow: 1,
  marginTop: theme.spacing(1.5),
}));

const StyledLangSelect = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  marginLeft: theme.spacing(4),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),

  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
  '& .MuiSelect-select': { color: theme.palette.text.primary },
  '& .MuiSelect-icon': { color: `${theme.palette.text.primary} !important` },

  '&:hover': {
    backgroundColor: theme.palette.primary.lighter,

    '& .MuiSelect-select': {
      backgroundColor: theme.palette.primary.lighter,
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  color: theme.palette.text.primary,
  height: '100%',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  textDecoration: 'none',
  fontWeight: 400,

  '&:hover': {
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.text.primary,
    fontWeight: 400,
    textDecoration: 'none',
  },
}));
