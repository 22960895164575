import { Box, Grid, styled } from '@mui/material';

import {
  StyledBox,
  StyledGrid,
  StyledGridContainer,
} from '@/admin/components/blogPost';
import { StyledAppBar, StyledToolbar } from '@/admin/components/common';
import { Skeleton } from '@/admin/components/skeleton';

export const EditBlogsSkeleton = () => (
  <>
    <StyledContainer>
      <StyledAppBar position="static">
        <StyledToolbar>
          <Skeleton width={181} height={32} />
          <StyledRow>
            <Skeleton width={85} height={40} />
            <Skeleton width={124} height={40} />
          </StyledRow>
        </StyledToolbar>
      </StyledAppBar>
    </StyledContainer>

    <StyledGridContainer container>
      <StyledGrid item xs={12} lg={9}>
        <StyledRow>
          <StyledRow>
            <Skeleton width={169} height={60} />
            <Skeleton width={112} height={60} />
          </StyledRow>
        </StyledRow>

        <Skeleton height={706} />
      </StyledGrid>

      <Grid item xs={12} lg={3}>
        <StyledBox>
          <StyledCol>
            <Skeleton width={49} height={26} />
            <Skeleton height={82} />
            <Skeleton height={62} />
            <Skeleton height={200} />
          </StyledCol>
          <StyledCol>
            <Skeleton width={134} height={32} />
            <Skeleton height={290} />
          </StyledCol>
          <StyledCol>
            <Skeleton width={128} height={32} />
            <Skeleton width={222} height={32} />
            <Skeleton width={293} height={32} />
            <Skeleton width={49} height={32} />
          </StyledCol>
        </StyledBox>
      </Grid>
    </StyledGridContainer>
  </>
);

const StyledContainer = styled(Box)({ zIndex: 10, minWidth: '800px' });

const StyledRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const StyledCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
});
