import { $isLinkNode, LinkNode } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getNearestNodeFromDOMNode,
  $getSelection,
  $isRangeSelection,
} from 'lexical';
import { useEffect } from 'react';

import { getLinkDomNode } from '@/admin/utils/helpers-editor';

type ClickableLinkPluginProps = {
  newTab?: boolean;
  filter?: (event: MouseEvent, linkNode: LinkNode) => boolean;
};

export const ClickableLinkPlugin = ({
  newTab = true,
  filter,
}: ClickableLinkPluginProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      const linkDomNode = getLinkDomNode(e, editor);

      if (linkDomNode === null) {
        return;
      }

      const href = linkDomNode.getAttribute('href');

      if (
        linkDomNode.getAttribute('contenteditable') === 'false' ||
        href === undefined
      ) {
        return;
      }

      // Allow user to select link text without following url
      const selection = editor.getEditorState().read($getSelection);

      if ($isRangeSelection(selection) && !selection.isCollapsed()) {
        return;
      }

      let linkNode = null;
      editor.update(() => {
        const maybeLinkNode = $getNearestNodeFromDOMNode(linkDomNode);

        if ($isLinkNode(maybeLinkNode)) {
          linkNode = maybeLinkNode;
        }
      });

      if (linkNode === null || (filter !== undefined && !filter(e, linkNode))) {
        return;
      }

      if (href !== null) {
        window.open(
          href,
          newTab || e.metaKey || e.ctrlKey ? '_blank' : '_self'
        );
        e.preventDefault();
      }
    };

    return editor.registerRootListener(
      (
        rootElement: null | HTMLElement,
        prevRootElement: null | HTMLElement
      ) => {
        if (prevRootElement !== null) {
          prevRootElement.removeEventListener('click', onClick);
        }

        if (rootElement !== null) {
          rootElement.addEventListener('click', onClick);
        }
      }
    );
  }, [editor, filter, newTab]);
  return null;
};
