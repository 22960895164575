import { styled, Tooltip, Typography } from '@mui/material';
import { forwardRef, LegacyRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/admin/components';

type NavButtonProps = {
  icon: string;
  isOpen: boolean;
  title: string;
  onClick: () => void;
};

export const NavButton = ({ icon, isOpen, title, onClick }: NavButtonProps) => {
  const { t } = useTranslation();

  const NavButtonContent = forwardRef(function NavButtonContent(
    props,
    ref: LegacyRef<HTMLButtonElement>
  ) {
    return (
      <StyledNavButton
        {...props}
        className={!isOpen ? 'closed' : ''}
        type="button"
        ref={ref}
        onClick={onClick}
      >
        <Icon size="md" symbol={icon} />
        {isOpen && <StyledTitle variant="subtitle1">{t(title)}</StyledTitle>}
      </StyledNavButton>
    );
  });

  return (
    <>
      {isOpen && <NavButtonContent />}
      {!isOpen && (
        <Tooltip title={t(title)} arrow placement="right">
          <NavButtonContent />
        </Tooltip>
      )}
    </>
  );
};

const StyledNavButton = styled('button')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '48px',
  paddingLeft: theme.spacing(4.5),
  paddingRight: theme.spacing(2),
  transition: 'all 0.3s',
  width: '100%',

  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },

  '&.closed': {
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
  },

  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2.5),

  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));
