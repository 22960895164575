import {
  AnnouncementsValues,
  IAnnouncementValues,
  IBlogSEOData,
  IBlogValues,
  IPrivacyPolicyValues,
  IReviewIntegData,
} from '@/common/types';

export const defaultAnnouncementValues: IAnnouncementValues = {
  buttonText: '',
  buttonStyle: 'outlined',
  buttonUrl: '',
  expiryDate: null,
  image: '',
  language: 'en',
  launchDate: null,
  order: 0,
  paragraph: '',
  status: 'saved',
  title: '',
  visibility: 'hidden',
};

export const defaultAnnouncementsValues: AnnouncementsValues = {
  en0: { ...defaultAnnouncementValues, order: 0 },
  en1: { ...defaultAnnouncementValues, order: 1 },
  en2: { ...defaultAnnouncementValues, order: 2 },
  fr0: {
    ...defaultAnnouncementValues,
    language: 'fr',
    order: 0,
  },
  fr1: {
    ...defaultAnnouncementValues,
    language: 'fr',
    order: 1,
  },
  fr2: {
    ...defaultAnnouncementValues,
    language: 'fr',
    order: 2,
  },
};

export const defaultBlogSEOValues: IBlogSEOData = {
  slug: '',
  title: '',
  description: '',
};

export const defaultTextEditorContent = '<p class="editor_paragraph"><br></p>';

export const defaultBlogValues: IBlogValues = {
  authorName: '',
  blogContent: defaultTextEditorContent,
  blogName: '',
  blogNameLowerCased: '',
  brand: '',
  id: '',
  imageURL: '',
  isSEODefault: true,
  language: '',
  networkId: 0,
  status: 'draft',
  userType: 'broker',
  updatedBy: '',
  userId: 0,
  ...defaultBlogSEOValues,
};

export const defaultPrivacyPolicyValues: IPrivacyPolicyValues = {
  createdBy: '',
  dateCreated: 0,
  datePublished: 0,
  dateUpdated: 0,
  policyTextEn: '<p></p>',
  policyTextFr: '<p></p>',
  provinces: [],
  status: 'draft',
  updatedBy: '',
  userId: 0,
  userType: 'network',
};

export const defaultSettingValues: IReviewIntegData = {
  pageId: '',
  appId: '',
  userToken: '',
};
