import { REACT_APP_PROXY_API_URL } from '@/admin/config/variables';
import { FETCH_ANNOUNCEMENTS_URL } from '@/admin/consts';
import { getHeaders } from '@/admin/utils/helpers-api';
import { IAnnouncementsData, IAnnouncementsValues } from '@/common/types';

export const getAnnouncementsApi = async (
  token: string,
  networkId?: number
) => {
  const networkIdQuery = networkId ? `&networkId=${networkId}` : '';
  const url =
    `${REACT_APP_PROXY_API_URL}${FETCH_ANNOUNCEMENTS_URL}?status=saved${networkIdQuery}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { headers });
  const { data }: { data: IAnnouncementsData[] } = await res.json();

  return data[0];
};

export const createAnnouncementsApi = async (
  announcementsValues: IAnnouncementsValues,
  token: string
) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_ANNOUNCEMENTS_URL}`;
  const headers = getHeaders(token);

  const res = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(announcementsValues),
  });

  const { data }: { data: IAnnouncementsData } = await res.json();

  return data;
};

export const updateAnnouncementsApi = async (
  announcementsValues: IAnnouncementsValues,
  token: string
) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_ANNOUNCEMENTS_URL}`;
  const headers = getHeaders(token);

  const res = await fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(announcementsValues),
  });

  const { data }: { data: IAnnouncementsData } = await res.json();

  return data;
};
