import { Alert, Paper, Skeleton, styled } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Area } from 'react-easy-crop/types';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  HomepageCropImage,
  HomepageImageControls,
  HomepageToolbar,
  InputFile,
} from '@/admin/components';
import { inputFileErrorMessages, userTypeCode } from '@/admin/consts';
import { useCallbackPrompt, useModal } from '@/admin/hooks';
import { AppContext, ThemeContext } from '@/admin/providers';
import { ImageState, InputFileError } from '@/admin/types/common';
import {
  getLocalizedValue,
  getSubString,
  urlToObject,
  verifyImageFileSize,
  verifyImageFileType,
} from '@/admin/utils/helpers';
import {
  deleteImageFromBossApi,
  uploadImageToBossApi,
} from '@/admin/utils/helpers-api';
import { getCroppedImg } from '@/admin/utils/helpers-crop-image';
import { EmployeeType } from '@/common/types';

export const HomepageImage = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    isAdmin,
    isTokenExpired,
    selectedUserId,
    userInfo: { role },
    displayedUserInfo,
    setDisplayedUserInfo,
  } = useContext(AppContext);

  const {
    originalNetworkId,
    userType,
    employeeId,
    eHeroImage,
    eCroppedHeroImage,
    name,
    nameFr,
  } = displayedUserInfo;

  const { openSnackbar } = useContext(ThemeContext);
  const { modalContent, showModal, handleModalClose } = useModal();

  // images
  const [imageFromBoss, setImageFromBoss] = useState<string>('');
  const [displayedImage, setDisplayedImage] = useState<string>('');
  const [uniqueDisplayedImage, setUniqueDisplayedImage] = useState<string>('');
  // image crop state
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  // states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<InputFileError | null>(null);
  const [isNoChanges, setIsNoChanges] = useState<boolean>(true);
  const [imageState, setImageState] = useState<ImageState>('addImage');

  // detect the user leaving the page
  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    !isNoChanges
  );

  useEffect(() => {
    // Set Initial Images from BOSS
    setImageFromBoss(eCroppedHeroImage);
    setDisplayedImage(eCroppedHeroImage);
    setImageState(eCroppedHeroImage ? 'previewImage' : 'addImage');
  }, [eCroppedHeroImage]);

  useEffect(() => {
    // Making displayedImage URL unique to solve the cache issue
    setUniqueDisplayedImage(getUniqueImageUrl(displayedImage));
  }, [displayedImage]);

  useEffect(() => {
    // disable useCallbackPrompt if there are no changes
    setIsNoChanges(
      (!displayedImage && !imageFromBoss) || displayedImage === imageFromBoss
    );
  }, [displayedImage, imageFromBoss]);

  useEffect(() => {
    // disable useCallbackPrompt when the access token expires
    if (isTokenExpired) {
      setIsNoChanges(true);
    }
  }, [isTokenExpired]);

  const handleInputChange = (files: FileList) => {
    setError(null);
    setImageState('editImage');

    // accept single file drag n drop
    const isSingleImage = files.length === 1;
    if (!isSingleImage) {
      setError('multipleFiles');
      return;
    }

    // validate the file type
    const isValidFileType = verifyImageFileType(files[0].name);

    if (!isValidFileType) {
      setError('fileType');
      return;
    }

    // validate the file size
    const isValidFileSize = verifyImageFileSize(files[0].size);

    if (!isValidFileSize) {
      setError('fileSize');
      return;
    }

    const url = URL.createObjectURL(files[0]);
    setDisplayedImage(url);
    resetImageControls();
  };

  const handleDeleteImage = async () => {
    if (!imageFromBoss) {
      setDisplayedImage('');
      setImageState('addImage');
      resetImageControls();
      openSnackbar(t('headerImageDeleted'));
    } else {
      deleteImage();
    }
  };

  const handleOpenPublishDialog = () => {
    const isPersonalTab =
      (role === 'company admin' || role === 'office admin') &&
      userType === 'broker';
    const modalMessage = isAdmin
      ? isPersonalTab
        ? 'confirmPublicationBrokerMessage'
        : 'confirmPublicationSelectedUserMessage'
      : 'confirmPublicationMessage';
    const selectedUserName =
      isAdmin && !isPersonalTab
        ? getLocalizedValue(language, name, nameFr)
        : '';

    showModal({
      message: t(modalMessage, { selectedUserName }),
      title: t('confirmPublication'),
      handleAgree: handleAgreePublishDialog,
    });
  };

  const handleAgreePublishDialog = async () => {
    setIsLoading(true);
    handleModalClose();
    await publishImage();
  };

  const handleOpenErrorPublishDialog = () => {
    showModal(
      {
        message: t('errorDialogMessagePublishImage'),
        title: t('errorDialogTitle'),
        handleAgree: handleModalClose,
      },
      true
    );
  };

  const handleOpenErrorDeleteDialog = () => {
    showModal(
      {
        message: t('errorDialogMessageDeleteImage'),
        title: t('errorDialogTitle'),
        handleAgree: handleModalClose,
      },
      true
    );
  };

  const handleEditImage = () => {
    setImageState('editImage');
    setDisplayedImage(eHeroImage);
  };

  const publishImage = async () => {
    try {
      const croppedImage = (await getCroppedImg(
        displayedImage,
        croppedAreaPixels as Area,
        rotation
      )) as string;

      const croppedFile = await urlToObject(croppedImage);
      const originalFile = await urlToObject(displayedImage);

      const { status, data } = await uploadImageToBossApi(
        originalFile,
        croppedFile,
        // use original networkId since we are storing in boss
        originalNetworkId,
        selectedUserId || employeeId,
        userTypeCode[userType as EmployeeType]
      );

      if (status === 200) {
        const eHeroImage = data.image.heroImageUrl;
        const eCroppedHeroImage = data.cropImage.croppedHeroImageUrl;
        setDisplayedUserInfo({
          ...displayedUserInfo,
          eHeroImage,
          eCroppedHeroImage,
        });
        // The image url states need to be re-assgined because the BOSS returns the same URL
        setImageFromBoss(eCroppedHeroImage);
        setDisplayedImage(eCroppedHeroImage);
        setImageState('previewImage');
        setUniqueDisplayedImage(getUniqueImageUrl(displayedImage));
        setIsNoChanges(true);
        setIsLoading(false);

        openSnackbar(t('headerImagePublished'));
      } else {
        setIsLoading(false);
        setIsNoChanges(false);
        handleOpenErrorPublishDialog();
      }
    } catch (error) {
      setIsLoading(false);
      setIsNoChanges(false);
      handleOpenErrorPublishDialog();
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const deleteImage = async () => {
    try {
      const status = await deleteImageFromBossApi(
        getSubString(eHeroImage, 'HEROIMAGE/'),
        getSubString(imageFromBoss, 'HEROIMAGE/'),
        selectedUserId || employeeId,
        userTypeCode[userType as EmployeeType],
        // use original networkId since we are storing in boss
        originalNetworkId
      );

      if (status === 200) {
        setImageState('addImage');
        setDisplayedUserInfo({
          ...displayedUserInfo,
          eHeroImage: '',
          eCroppedHeroImage: '',
        });
        resetImageControls();
        openSnackbar(t('headerImageDeleted'));
      } else {
        setIsLoading(false);
        handleOpenErrorDeleteDialog();
      }
    } catch (error) {
      setIsLoading(false);
      handleOpenErrorDeleteDialog();
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const resetImageControls = () => {
    setZoom(1);
    setRotation(0);
  };

  const getUniqueImageUrl = (url: string) =>
    url ? (url.search('blob:') ? `${url}?${Date.now()}` : url) : '';

  return (
    <>
      <StyledHomepageImage>
        <StyledContent>
          <HomepageToolbar
            isChangeImageButtonVisible={Boolean(
              displayedImage && imageState === 'editImage'
            )}
            isPublishButtonDisabled={isNoChanges}
            handleChangeImage={handleInputChange}
            handlePublish={handleOpenPublishDialog}
            handleEditImage={handleEditImage}
            imageState={imageState}
          />

          {isLoading && <StyledSkeleton variant="rectangular" />}

          {!isLoading && (
            <StyledPaper>
              <StyledWrapper>
                <>
                  {!displayedImage && (
                    <StyledInputFile>
                      <InputFile
                        image={displayedImage}
                        error={error}
                        handleUploadImage={handleInputChange}
                        errorDisabled
                      />
                    </StyledInputFile>
                  )}

                  {displayedImage && (
                    <HomepageCropImage
                      imageState={imageState}
                      crop={crop}
                      image={uniqueDisplayedImage}
                      rotation={rotation}
                      zoom={zoom}
                      handleDeleteImage={handleDeleteImage}
                      setCrop={setCrop}
                      setCroppedAreaPixels={setCroppedAreaPixels}
                      setRotation={setRotation}
                      setZoom={setZoom}
                    />
                  )}
                </>
                {error && (
                  <StyledAlert severity="error">
                    {t(inputFileErrorMessages[error])}
                  </StyledAlert>
                )}

                {imageState === 'editImage' && (
                  <HomepageImageControls
                    isDisabled={!displayedImage || isLoading}
                    rotation={rotation}
                    zoom={zoom}
                    setRotation={setRotation}
                    setZoom={setZoom}
                    setIsNoChanges={setIsNoChanges}
                  />
                )}
              </StyledWrapper>
            </StyledPaper>
          )}
        </StyledContent>
      </StyledHomepageImage>

      <Dialog
        buttonAgree={modalContent.buttonAgree}
        buttonClose={modalContent.buttonClose}
        message={modalContent.message}
        title={modalContent.title}
        open={modalContent.open}
        handleAgree={modalContent.handleAgree}
        handleClose={modalContent.handleClose}
      />

      {/* the user leaving the page */}
      <Dialog
        buttonAgree={t('returnToHomepage')}
        buttonClose={t('exitWithoutSaving')}
        message={t('unsavedChangesHomepageMessage')}
        title={t('unsavedChanges')}
        open={showPrompt}
        handleAgree={cancelNavigation}
        handleClose={() => confirmNavigation()} // not to take event arg
      />
    </>
  );
};

const StyledHomepageImage = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.spacing(5, 6, 6),
}));

const StyledContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  margin: '0 auto',
  maxWidth: '1218px',
  width: '100%',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 5, 3),
}));

const StyledWrapper = styled('div')({
  margin: '0 auto',
  maxWidth: '1021px',
});

const StyledInputFile = styled('div')({
  aspectRatio: '16 / 9',
});

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  flexGrow: 1,
  marginTop: theme.spacing(2),
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));
