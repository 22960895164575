import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../app/AppProvider';
import { baseLinksByThemeName } from '@/admin/consts';
import { FC } from '@/admin/types/common';
import { env, getBrand } from '@/admin/utils/helpers';
import {
  getCorporatePolicyApi,
  getPoliciesApi,
} from '@/admin/utils/helpers-api';
import { IPrivacyPoliciesData, Lang } from '@/common/types';

interface IPrivacyPoliciesContext {
  allPolicies: IPrivacyPoliciesData[];
  isError: boolean;
  isLoading: boolean;
  privacyPoliciesList: IPrivacyPoliciesData[];
  tabLanguage: number;
  setIsError: (value: boolean) => void;
  setPrivacyPoliciesList: (policies: IPrivacyPoliciesData[]) => void;
  setTabLanguage: (number: number) => void;
}

const initialContext: IPrivacyPoliciesContext = {
  allPolicies: [],
  isError: false,
  isLoading: true,
  privacyPoliciesList: [],
  tabLanguage: 0,
  setIsError: () => {},
  setPrivacyPoliciesList: () => {},
  setTabLanguage: () => {},
};

export const PrivacyPoliciesContext =
  createContext<IPrivacyPoliciesContext>(initialContext);

export const PrivacyPoliciesProvider = ({ children }: FC) => {
  const {
    i18n: { language },
  } = useTranslation();

  const {
    getAccessToken,
    selectedUser: { networkId, userType },
    selectedUserId,
  } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [corporatePolicy, setCorporatePolicy] = useState(null);
  const [privacyPoliciesList, setPrivacyPoliciesList] = useState<
    IPrivacyPoliciesData[]
  >([]);
  const [tabLanguage, setTabLanguage] = useState(0);

  const brand = getBrand(networkId);
  const lang = language as Lang;
  const domain = baseLinksByThemeName[env][brand][lang];

  const allPolicies = [
    ...(corporatePolicy ? [corporatePolicy] : []),
    ...privacyPoliciesList,
  ];

  const fetchPrivacyPolicies = async () => {
    try {
      if (userType === 'network' || privacyPoliciesList.length > 0) {
        return;
      }
      const token = await getAccessToken();

      const policies = await getPoliciesApi({
        networkId,
        token,
        userType,
        userId: selectedUserId,
      });

      setPrivacyPoliciesList(policies);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const fetchCorporatePolicy = async () => {
    try {
      if (corporatePolicy !== null) {
        return;
      }
      const token = await getAccessToken();

      const { data } = await getCorporatePolicyApi(domain, networkId, token);

      setCorporatePolicy(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPolicies();
  }, [selectedUserId]);

  const fetchPolicies = async () => {
    try {
      setIsLoading(true);

      await Promise.all([fetchPrivacyPolicies(), fetchCorporatePolicy()]);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <PrivacyPoliciesContext.Provider
      value={{
        allPolicies,
        isError,
        isLoading,
        privacyPoliciesList,
        tabLanguage,
        setPrivacyPoliciesList,
        setIsError,
        setTabLanguage,
      }}
    >
      {children}
    </PrivacyPoliciesContext.Provider>
  );
};
