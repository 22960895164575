import { Slider, styled, Typography } from '@mui/material';

type HomepageSliderProps = {
  ariaLabelledby: string;
  max: number;
  min: number;
  step: number;
  title: string;
  value: number;
  onChange: (event: Event, value: number | number[]) => void;
  isDisabled?: boolean;
};

export const HomepageSlider = ({
  ariaLabelledby,
  isDisabled,
  max,
  min,
  step,
  title,
  value,
  onChange,
}: HomepageSliderProps) => (
  <StyledHomepageCropImageSlider>
    <StyledTypography
      className={isDisabled ? 'disabled' : ''}
      variant="caption"
    >
      {title}
    </StyledTypography>

    <Slider
      aria-labelledby={ariaLabelledby}
      disabled={isDisabled}
      max={max}
      min={min}
      step={step}
      value={value}
      onChange={onChange}
    />
  </StyledHomepageCropImageSlider>
);

const StyledHomepageCropImageSlider = styled('div')({
  flex: 1,
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  '&.disabled': {
    color: theme.palette.text.secondary,
  },
}));
