import { INSERT_TABLE_COMMAND } from '@lexical/table';
import { Stack, styled } from '@mui/material';
import { LexicalEditor } from 'lexical';
import { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, TextField } from '@/admin/components';

type TableInsertModalProps = {
  editor: LexicalEditor;
  open: boolean;
  onClose: () => void;
};

export const TableInsertModal = ({
  editor,
  open,
  onClose,
}: TableInsertModalProps) => {
  const { t } = useTranslation();

  const [rows, setRows] = useState('5');
  const [columns, setColumns] = useState('5');

  const handleChangeRows = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    verifyInputValue(value) && setRows(value);
  };

  const handleChangeColumns = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    verifyInputValue(value) && setColumns(value);
  };

  const handleConfirm = () => {
    editor.dispatchCommand(INSERT_TABLE_COMMAND, { columns, rows });
    handleClose();
  };

  const handleClose = () => {
    onClose();
    setRows('5');
    setColumns('5');
  };

  const verifyInputValue = (value: string) => {
    const pattern = /^([1-9]|[1-9][0-9]|100)?$/;
    return pattern.test(value);
  };

  return (
    <Dialog
      buttonAgree={t('confirm')}
      buttonClose={t('cancel')}
      disableAgreeButton={!rows || !columns}
      maxWidth="xs"
      open={open}
      title={t('insertTableDialogTitle')}
      handleAgree={handleConfirm}
      handleClose={handleClose}
    >
      <StyledStack direction="row" spacing={4}>
        <TextField
          label={t('numberOfRows')}
          placeholder="1-100"
          value={rows}
          onChange={handleChangeRows}
        />

        <TextField
          label={t('numberOfColumns')}
          placeholder="1-100"
          value={columns}
          onChange={handleChangeColumns}
        />
      </StyledStack>
    </Dialog>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));
