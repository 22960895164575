import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AdminToolbarContainer, AdminToolbarSearch } from '@/admin/components';
import { DASHBOARD_PATH, DEFAULT_USER } from '@/admin/consts';
import { AppContext } from '@/admin/providers';
import { IUserTypeOption } from '@/admin/types/common';
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemToLocalStorage,
} from '@/admin/utils/helpers';
import { IUser, UserType } from '@/common/types';

type AdminToolbarBottomProps = {
  isBossPolicyPage: boolean;
  fetchSelectedUserInfo: (user: IUser) => void;
};

// Company or Office admin toolbar
export const AdminToolbarBottom = ({
  isBossPolicyPage,
  fetchSelectedUserInfo,
}: AdminToolbarBottomProps) => {
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation();
  const {
    isBossLogin,
    isCancelNavigation,
    isConfirmNavigation,
    selectedUser,
    userInfo,
    setSelectedUser,
    setSelectedUserInfo,
    setIsCancelNavigation,
  } = useContext(AppContext);

  const DEFAULT_USER_TYPE: UserType = 'broker';

  const defaultUserTypes: IUserTypeOption[] = [
    { label: 'personal', value: 'broker', isAutocompleteHidden: true },
    { label: 'office', value: 'office' },
    { label: 'company', value: 'company' },
  ];

  const selectedUserFromLocalStorage = getItemFromLocalStorage(
    isBossLogin ? 'selectedUser-Boss' : 'selectedUser',
    true
  ) as IUser;

  const [companies, setCompanies] = useState<IUser[]>([]);
  const [offices, setOffices] = useState<IUser[]>([]);
  const [displayedUsers, setDisplayedUsers] = useState<IUser[]>([]);
  const [userType, setUserType] = useState<UserType>(
    selectedUserFromLocalStorage?.userType || DEFAULT_USER_TYPE
  );
  const [userTypes, setUserTypes] =
    useState<IUserTypeOption[]>(defaultUserTypes);
  const [newSelectedUser, setNewSelectedUser] = useState<IUser>(
    selectedUser || DEFAULT_USER
  );

  const { employeeId, name, nameFr, networkId, originalNetworkId, blogAccess } =
    userInfo;

  const personal: IUser = {
    employeeId,
    name,
    nameFr,
    networkId,
    originalNetworkId,
    userType: 'broker',
    blogAccess,
  };

  const usersByType = {
    company: companies,
    office: offices,
  } as Record<UserType, IUser[]>;

  // set the companies and offices
  useEffect(() => {
    const { franchise, offices } = userInfo;

    setOffices(offices);
    setCompanies(franchise);

    let types: IUserTypeOption[] = defaultUserTypes;

    if (isBossPolicyPage)
      types = types.filter((type) => type.label !== 'personal');

    if (!offices.length)
      types = types.filter((type) => type.label !== 'office');

    if (!franchise.length)
      types = types.filter((type) => type.label !== 'company');

    setUserTypes(types);
    setUserType(types[0].label as UserType);
  }, [language, userInfo]);

  useEffect(() => {
    if (selectedUser) fetchSelectedUserInfo(selectedUser);
  }, []);

  // update the displayed users
  useEffect(() => {
    setDisplayedUsers(usersByType[userType]);
  }, [companies, offices, userType]);

  // handle page leaving blocker after select userType
  useEffect(() => {
    const isUserTypeChanged =
      userType !== selectedUserFromLocalStorage?.userType;

    if (isConfirmNavigation && isUserTypeChanged) {
      const isPersonal = userType === 'broker';
      if (isPersonal) {
        handleUserChange(personal);
      } else {
        setSelectedUser(DEFAULT_USER);
        setNewSelectedUser(DEFAULT_USER);
        removeItemFromLocalStorage(
          isBossLogin ? 'selectedUser-Boss' : 'selectedUser'
        );
      }
    }

    if (isCancelNavigation && isUserTypeChanged) {
      setUserType(selectedUserFromLocalStorage?.userType);
      setIsCancelNavigation(false);
    }
  }, [isCancelNavigation, isConfirmNavigation, userType]);

  // handle page leaving blocker after change user
  useEffect(() => {
    const isUserChanged = selectedUser !== newSelectedUser;

    if (isConfirmNavigation && isUserChanged) {
      setSelectedUser(newSelectedUser);
      setItemToLocalStorage(
        isBossLogin ? 'selectedUser-Boss' : 'selectedUser',
        newSelectedUser,
        true
      );

      const isPersonal = userType === 'broker';
      if (isPersonal) {
        setSelectedUserInfo(userInfo);
      } else {
        fetchSelectedUserInfo(newSelectedUser);
      }
    }

    if (isCancelNavigation && isUserChanged) {
      setNewSelectedUser(selectedUser);
      setIsCancelNavigation(false);
    }
  }, [isCancelNavigation, isConfirmNavigation, newSelectedUser]);

  const handleUserTypeChange = (type: UserType) => {
    setUserType(type);
    if (!isBossLogin) navigate(DASHBOARD_PATH);
  };

  const handleUserChange = (user: IUser) => {
    setNewSelectedUser(user);
    if (!isBossLogin) navigate(DASHBOARD_PATH);
  };

  return (
    <AdminToolbarContainer>
      <AdminToolbarSearch
        user={
          userType === selectedUserFromLocalStorage?.userType
            ? selectedUser
            : DEFAULT_USER
        }
        userOptions={displayedUsers}
        userType={userType}
        userTypeOptions={userTypes}
        handleUserChange={handleUserChange}
        handleUserTypeChange={handleUserTypeChange}
      />
    </AdminToolbarContainer>
  );
};
