import { $createHeadingNode } from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  DEPRECATED_$isGridSelection,
  LexicalEditor,
} from 'lexical';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/admin/components';
import {
  ToolbarBlockFormatDropdown,
  ToolbarButton,
} from '@/admin/components/blogEditor/editorPlugins';
import { BLOCKTYPE_TO_BLOCKNAME } from '@/admin/consts/editor';

type ToolbarBlockFormatProps = {
  editor: LexicalEditor;
  blockType: keyof typeof BLOCKTYPE_TO_BLOCKNAME;
};

export const ToolbarBlockFormat = ({
  editor,
  blockType,
}: ToolbarBlockFormatProps) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation();

  const [showDropDown, setShowDropDown] = useState(false);

  useEffect(() => {
    const button = buttonRef.current;
    const dropDown = dropDownRef.current;

    if (showDropDown && button !== null && dropDown !== null) {
      const { top, left } = button.getBoundingClientRect();
      dropDown.style.top = `${top + 36}px`;
      dropDown.style.left = `${Math.min(
        left,
        window.innerWidth - dropDown.offsetWidth - 20
      )}px`;
    }
  }, [dropDownRef, buttonRef, showDropDown]);

  useEffect(() => {
    const button = buttonRef.current;

    if (button !== null && showDropDown) {
      const handle = (event: MouseEvent) => {
        const target = event.target;

        if (!button.contains(target as Node)) {
          setShowDropDown(false);
        }
      };
      document.addEventListener('click', handle);

      return () => {
        document.removeEventListener('click', handle);
      };
    }
  }, [dropDownRef, buttonRef, showDropDown]);

  const handleClick = () => {
    setShowDropDown(!showDropDown);
  };

  const handleClose = () => {
    setShowDropDown(false);

    if (buttonRef && buttonRef.current) {
      buttonRef.current.focus();
    }
  };

  const formatType = (textType: keyof typeof BLOCKTYPE_TO_BLOCKNAME) => {
    if (blockType === textType) return;

    editor.update(() => {
      const selection = $getSelection();

      if (
        $isRangeSelection(selection) ||
        DEPRECATED_$isGridSelection(selection)
      ) {
        $wrapNodes(selection, () =>
          textType === 'paragraph'
            ? $createParagraphNode()
            : $createHeadingNode(textType)
        );
      }
    });
  };

  return (
    <>
      <ToolbarButton
        ariaLabel={`Format ${t(BLOCKTYPE_TO_BLOCKNAME[blockType])}`}
        className="blockType"
        ref={buttonRef}
        onClick={handleClick}
      >
        <Icon size="sm" symbol={`format_${blockType}`} />
        <span className="buttonText">
          {t(BLOCKTYPE_TO_BLOCKNAME[blockType])}
        </span>
        <Icon size="md" symbol="expand_more" />
      </ToolbarButton>

      {showDropDown &&
        createPortal(
          <ToolbarBlockFormatDropdown
            blockType={blockType}
            ref={dropDownRef}
            onClose={handleClose}
            formatType={formatType}
          />,
          document.body
        )}
    </>
  );
};
