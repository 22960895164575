export const REACT_APP_PROXY_API_URL =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_PROXY_API_URL_PROD
    : process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? process.env.REACT_APP_PROXY_API_URL_STG
    : process.env.REACT_APP_PROXY_API_URL_DEV;

export const REACT_APP_AZURE_IMG_URL =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_AZURE_IMG_URL_PROD
    : process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? process.env.REACT_APP_AZURE_IMG_URL_STG
    : process.env.REACT_APP_AZURE_IMG_URL_DEV;

export const REACT_APP_AUTH0_DOMAIN =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_AUTH0_DOMAIN_PROD
    : process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? process.env.REACT_APP_AUTH0_DOMAIN_STG
    : process.env.REACT_APP_AUTH0_DOMAIN_DEV;

export const REACT_APP_AUTH0_CLIENTID =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_AUTH0_CLIENTID_PROD
    : process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? process.env.REACT_APP_AUTH0_CLIENTID_STG
    : process.env.REACT_APP_AUTH0_CLIENTID_DEV;

export const REACT_APP_AUTH0_AUDIENCE =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_AUTH0_AUDIENCE_PROD
    : process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? process.env.REACT_APP_AUTH0_AUDIENCE_STG
    : process.env.REACT_APP_AUTH0_AUDIENCE_DEV;

export const REACT_APP_GOOGLE_CLIENT_ID =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_GOOGLE_CLIENT_ID_PROD || ''
    : process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? process.env.REACT_APP_GOOGLE_CLIENT_ID_STG || ''
    : process.env.REACT_APP_GOOGLE_CLIENT_ID_DEV || '';
