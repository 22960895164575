import { Button, Divider, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LinkBack } from '@/admin/components';
import { BLOGS_PATH } from '@/admin/consts/paths';

type BlogLinkBackProps = {
  disabled: boolean;
  isNewBlog: boolean;
  handleDelete: () => void;
  handlePreview: () => void;
  handlePublish: () => void;
  handleSave: (sessionTimeout: boolean) => void;
};

export const BlogLinkBack = ({
  disabled,
  isNewBlog,
  handleDelete,
  handlePreview,
  handlePublish,
  handleSave,
}: BlogLinkBackProps) => {
  const { t } = useTranslation();

  const { getValues } = useFormContext();
  const status = getValues('status');

  return (
    <LinkBack text={t('allBlogs')} to={BLOGS_PATH}>
      <StyledButton disabled={disabled} onClick={handleDelete}>
        {t('delete')}
      </StyledButton>
      <Divider orientation="vertical" flexItem />
      <StyledButton onClick={handlePreview}>{t('preview')}</StyledButton>
      <Divider orientation="vertical" flexItem />

      {status === 'draft' && (
        <StyledButton
          variant="outlined"
          disabled={disabled}
          onClick={() => handleSave(false)}
        >
          {isNewBlog ? t('save') : t('update')}
        </StyledButton>
      )}

      <StyledButton
        variant="contained"
        disabled={disabled}
        onClick={handlePublish}
      >
        {status === 'draft' ? t('publish') : t('update')}
      </StyledButton>
    </LinkBack>
  );
};

const StyledButton = styled(Button)(() => ({
  whiteSpace: 'nowrap',
}));
