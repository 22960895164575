import {
  UnpublishedOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { Chip as MuiChip, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

type TestimonialChipProps = {
  isPublished: boolean;
  displayTestimonials: boolean;
};

export const TestimonialChip = ({
  isPublished,
  displayTestimonials,
}: TestimonialChipProps) => {
  const { t } = useTranslation();

  return (
    <StyledChip
      icon={
        isPublished ? (
          displayTestimonials ? (
            <VisibilityOutlined />
          ) : (
            <VisibilityOffOutlined />
          )
        ) : (
          <UnpublishedOutlined />
        )
      }
      label={t(
        isPublished
          ? displayTestimonials
            ? 'published'
            : 'hidden'
          : 'unpublished'
      )}
      color={
        isPublished ? (displayTestimonials ? 'success' : 'error') : 'default'
      }
      variant="outlined"
    />
  );
};

const StyledChip = styled(MuiChip)({
  marginLeft: 'auto',
});
