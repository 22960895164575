import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Link } from '@/admin/components';
import { BLOGS_PATH } from '@/admin/consts/paths';
import { IDashboardBlogsColumn } from '@/admin/types/common';
import { IBlogData } from '@/common/types';

type DashboardBlogsProps = {
  columns: IDashboardBlogsColumn[];
  blogs: IBlogData[];
};

export const DashboardBlogs = ({ columns, blogs }: DashboardBlogsProps) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell className="secondary" key={column.id}>
                {t(column.label)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {blogs.map((blog, i: number) => (
            <TableRow role="checkbox" tabIndex={-1} key={i}>
              {columns.map((column) => {
                if (column.id === 'blogName') {
                  return (
                    <TableCell className="secondary" key={column.id}>
                      <StyledLink>
                        <Link to={`${BLOGS_PATH}/${blog.id}`}>
                          {blog[column.id]}
                        </Link>
                      </StyledLink>
                    </TableCell>
                  );
                }

                return (
                  <TableCell className="secondary" key={column.id}>
                    {blog[column.id]}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledLink = styled('div')({
  '& > *': {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
  },
});
