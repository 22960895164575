import { createContext, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_USER, defaultSessionTimeoutModal } from '@/admin/consts';
import { DEFAULT_LANG, DEFAULT_USER_INFO } from '@/admin/providers';
import { IOpenSessionTimeoutModal } from '@/admin/types/common';
import {
  getItemFromLocalStorage,
  langByLangId,
  setItemToLocalStorage,
} from '@/admin/utils/helpers';
import {
  IUser,
  IUserInfo,
  Lang,
  SelectedUserInfo,
  UserType,
} from '@/common/types';

interface IAppContext {
  displayedUserInfo: SelectedUserInfo; // user information displayed in the app
  isAdmin: boolean; // M3Tech Admin, Network, Company or Office admin
  isBlogAccessChanged: boolean;
  isBossLogin: boolean;
  isCancelNavigation: boolean;
  isConfirmNavigation: boolean;
  isNoUserError: boolean;
  isTokenExpired: boolean;
  lang: Lang;
  openSessionTimeoutModal: IOpenSessionTimeoutModal;
  selectedUser: IUser; // user selected by an admin
  selectedUserId: number;
  selectedUserInfo: SelectedUserInfo;
  userInfo: IUserInfo;
  getAccessToken: (ignoreCache?: boolean) => Promise<string>;
  handleLogout: () => void;
  setDisplayedUserInfo: (userInfo: SelectedUserInfo) => void;
  setIsBlogAccessChanged: (isBlogAccessChanged: boolean) => void;
  setIsCancelNavigation: (cancelNavigation: boolean) => void;
  setIsConfirmNavigation: (confirmNavigation: boolean) => void;
  setIsTokenExpired: (isTokenExpired: boolean) => void;
  setLang: (lang: Lang) => void;
  setOpenSessionTimeoutModal: (
    openSessionTimeoutModal: IOpenSessionTimeoutModal
  ) => void;
  setIsNoUserError: (isNoUserError: boolean) => void;
  setSelectedUser: (selectedUser: IUser) => void;
  setSelectedUserInfo: (userInfo: SelectedUserInfo) => void;
}

type AppProviderProps = {
  children: ReactNode;
  isAdmin: boolean;
  isNoUserError: boolean;
  selectedUser: IUser;
  userInfo: IUserInfo;
  withToken: boolean;
  getAccessToken: (ignoreCache?: boolean) => Promise<string>;
  handleLogout: () => void;
  setIsNoUserError: (isNoUserError: boolean) => void;
  setSelectedUser: (selectedUser: IUser) => void;
};

const initialContext: IAppContext = {
  displayedUserInfo: DEFAULT_USER_INFO,
  isAdmin: false,
  isBlogAccessChanged: true,
  isBossLogin: false,
  isCancelNavigation: true,
  isConfirmNavigation: true,
  isNoUserError: false,
  isTokenExpired: false,
  lang: DEFAULT_LANG,
  openSessionTimeoutModal: defaultSessionTimeoutModal,
  selectedUser: DEFAULT_USER,
  selectedUserId: 0,
  selectedUserInfo: DEFAULT_USER_INFO,
  userInfo: DEFAULT_USER_INFO,
  getAccessToken: () => Promise.resolve(''),
  handleLogout: () => {},
  setDisplayedUserInfo: () => {},
  setIsBlogAccessChanged: () => {},
  setIsCancelNavigation: () => {},
  setIsConfirmNavigation: () => {},
  setIsNoUserError: () => {},
  setIsTokenExpired: () => {},
  setLang: () => {},
  setOpenSessionTimeoutModal: () => {},
  setSelectedUser: () => {},
  setSelectedUserInfo: () => {},
};

export const AppContext = createContext<IAppContext>(initialContext);

export const AppProvider = ({
  isAdmin,
  isNoUserError,
  children,
  selectedUser,
  userInfo,
  withToken,
  getAccessToken,
  handleLogout,
  setIsNoUserError,
  setSelectedUser,
}: AppProviderProps) => {
  const {
    i18n: { changeLanguage },
  } = useTranslation();

  // user
  const userLangFromLocalStorage = getItemFromLocalStorage(
    withToken ? 'language-Boss' : 'language'
  ) as Lang;

  const [lang, setLang] = useState<Lang>(
    userLangFromLocalStorage || langByLangId[userInfo.langId]
  );
  const [selectedUserId, setSelectedUserId] = useState<number>(0);
  const [selectedUserInfo, setSelectedUserInfo] =
    useState<SelectedUserInfo>(DEFAULT_USER_INFO);
  const [displayedUserInfo, setDisplayedUserInfo] =
    useState<SelectedUserInfo>(DEFAULT_USER_INFO);
  const [isBlogAccessChanged, setIsBlogAccessChanged] = useState<boolean>(true);
  // session timeout
  const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);
  const [openSessionTimeoutModal, setOpenSessionTimeoutModal] =
    useState<IOpenSessionTimeoutModal>(defaultSessionTimeoutModal);
  // navigation
  const [isConfirmNavigation, setIsConfirmNavigation] = useState<boolean>(true);
  const [isCancelNavigation, setIsCancelNavigation] = useState<boolean>(false);

  useEffect(() => {
    const isSelectedUser =
      selectedUser.name && userInfo.employeeId !== selectedUser.employeeId;
    setDisplayedUserInfo(isSelectedUser ? selectedUserInfo : userInfo);
    withToken && setLang(langByLangId[userInfo.langId]);
  }, [selectedUser, selectedUserInfo, userInfo]);

  useEffect(() => {
    const { employeeId, franchiseId, networkId, officeId, userType } =
      selectedUser;
    const selectedUserIdByUserType: Record<UserType, number> = {
      broker: employeeId as number,
      company: franchiseId as number,
      office: officeId as number,
      network: networkId as number,
    };

    setSelectedUserId(selectedUserIdByUserType[userType]);
  }, [selectedUser]);

  useEffect(() => {
    if (lang) {
      changeLanguage(lang);
      setItemToLocalStorage(withToken ? 'language-Boss' : 'language', lang);
    }
  }, [lang]);

  return (
    <AppContext.Provider
      value={{
        displayedUserInfo,
        isAdmin,
        isBlogAccessChanged,
        isBossLogin: withToken,
        isCancelNavigation,
        isConfirmNavigation,
        isNoUserError,
        isTokenExpired,
        lang,
        openSessionTimeoutModal,
        selectedUser,
        selectedUserId,
        selectedUserInfo,
        userInfo,
        getAccessToken,
        handleLogout,
        setDisplayedUserInfo,
        setIsBlogAccessChanged,
        setIsCancelNavigation,
        setIsConfirmNavigation,
        setIsNoUserError,
        setIsTokenExpired,
        setLang,
        setOpenSessionTimeoutModal,
        setSelectedUser,
        setSelectedUserInfo,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
