import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  alpha,
  Card,
  CardMedia,
  styled,
  Typography,
} from '@mui/material';
import { ChangeEvent, DragEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Loader } from '@/admin/components';
import { ACCEPTED_IMAGE_TYPES, inputFileErrorMessages } from '@/admin/consts';
import { IElementMUI, InputFileError } from '@/admin/types/common';

type InputFileProps = {
  error: InputFileError | null;
  image: string;
  handleUploadImage: (files: FileList) => void;
  acceptedFiles?: string;
  aspectRatio?: '16 / 9';
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
  handleDeleteImage?: () => void;
  errorDisabled?: boolean;
};

export const InputFile = ({
  acceptedFiles = ACCEPTED_IMAGE_TYPES,
  aspectRatio = '16 / 9',
  error,
  image,
  isDisabled,
  isLoading,
  label,
  handleDeleteImage,
  handleUploadImage,
  errorDisabled,
}: InputFileProps) => {
  const { t } = useTranslation();

  const handleFileDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleFileDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleUploadImage(e.dataTransfer.files);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) handleUploadImage(files);
  };

  return (
    <>
      {label && (
        <StyledTypography variant="caption">{`${label}:`}</StyledTypography>
      )}

      <StyledContent style={{ aspectRatio }}>
        <StyledCard>
          {image && handleDeleteImage && (
            <StyledCardMedia
              className={isDisabled ? 'disabled' : ''}
              image={image}
            >
              <StyledIconButton>
                <IconButton color="secondary" onClick={handleDeleteImage}>
                  <DeleteIcon />
                </IconButton>
              </StyledIconButton>
            </StyledCardMedia>
          )}

          {!image && (
            <StyledCardContent
              className={isDisabled ? 'disabled' : ''}
              onDragOver={handleFileDragOver}
              onDrop={handleFileDrop}
            >
              {isLoading && <Loader />}

              {!isLoading && (
                <div>
                  <div>
                    <Typography component="span" variant="subtitle1">{`${t(
                      'dragAndDrop'
                    )} ${t('or')} `}</Typography>
                    <>
                      <input
                        accept={acceptedFiles}
                        id="file-input"
                        hidden
                        type="file"
                        onChange={handleInputChange}
                      />
                      <label htmlFor="file-input">
                        <StyledTypographyLabel
                          component="span"
                          variant="subtitle1"
                        >
                          {t('browse')}
                        </StyledTypographyLabel>
                      </label>
                    </>
                  </div>

                  <StyledTypographyDescription variant="subtitle2">
                    {t('inputFileDescription')}
                  </StyledTypographyDescription>
                </div>
              )}
            </StyledCardContent>
          )}
        </StyledCard>
      </StyledContent>

      {error && !errorDisabled && (
        <StyledAlert severity="error">
          {t(inputFileErrorMessages[error])}
        </StyledAlert>
      )}
    </>
  );
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(1),
}));

const StyledContent = styled('div')({
  display: 'flex',
});

const StyledCard = styled(Card)({
  border: 'none',
  boxShadow: 'none',
  flexGrow: 1,
});

const StyledCardContent = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.primary.main, 0.05),
  border: `1px dashed ${alpha(theme.palette.primary.main, 0.5)}`,
  borderRadius: '4px',
  color: theme.palette.text.secondary,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  padding: theme.spacing(4, 2),
  position: 'relative',
  textAlign: 'center',

  '&.disabled': {
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.grey[400],
    color: theme.palette.grey[400],

    '& label': {
      pointerEvents: 'none',

      '& > *': {
        color: theme.palette.grey[400],
      },
    },
  },
}));

const StyledTypographyLabel = styled(Typography)<IElementMUI>(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  fontWeight: 600,
  textDecoration: 'underline',
}));

const StyledTypographyDescription = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  marginTop: theme.spacing(2),
  whiteSpace: 'break-spaces',
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: '100%',
  position: 'relative',

  '&.disabled': {
    '&::after': {
      backgroundColor: alpha(theme.palette.grey[200], 0.5),
      bottom: 0,
      content: '""',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
}));

const StyledIconButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));
