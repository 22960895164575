import { styled } from '@mui/material';

import { StyledActions, StyledLinks } from '@/admin/components/nav';
import { Skeleton } from '@/admin/components/skeleton';

type NavSkeletonProps = {
  open: Boolean;
};

export const NavSkeleton = ({ open }: NavSkeletonProps) => {
  return (
    <>
      <StyledContainer>
        <Skeleton width={142} height={16} />
      </StyledContainer>
      <StyledLinks>
        <StyledContainer>
          <Skeleton width={21} height={22} />
          {open && <Skeleton width={96} height={22} />}
        </StyledContainer>
        <StyledContainer>
          <Skeleton width={21} height={22} />
          {open && <Skeleton width={96} height={22} />}
        </StyledContainer>
        <StyledContainer>
          <Skeleton width={21} height={22} />
          {open && <Skeleton width={96} height={22} />}
        </StyledContainer>
        <StyledContainer>
          <Skeleton width={21} height={22} />
          {open && <Skeleton width={96} height={22} />}
        </StyledContainer>
      </StyledLinks>
      <StyledActions>
        <StyledContainer>
          <Skeleton width={21} height={22} />
          {open && <Skeleton width={96} height={22} />}
        </StyledContainer>
        <StyledContainer>
          <Skeleton width={21} height={22} />
          {open && <Skeleton width={96} height={22} />}
        </StyledContainer>
      </StyledActions>
    </>
  );
};

const StyledContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0.5, 4),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));
