import { styled } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Dialog,
  PrivacyPolicyContent,
  PrivacyPolicyLinkBack,
} from '@/admin/components';
import { PRIVACY_POLICIES_PATH } from '@/admin/consts/paths';
import { useCallbackPrompt, useModal } from '@/admin/hooks';
import {
  AppContext,
  PrivacyPoliciesContext,
  ThemeContext,
} from '@/admin/providers';
import { deletePolicyApi, updatePolicyApi } from '@/admin/utils/helpers-api';
import { IPrivacyPolicyValues, IPrivacyPoliciesData } from '@/common/types';

type PrivacyPolicyPostProps = {
  getPrivacyPolicyDataById?: (privacyPolicyId: string) => void;
};

export const PrivacyPolicyPost = ({
  getPrivacyPolicyDataById,
}: PrivacyPolicyPostProps) => {
  const { t } = useTranslation();
  const { privacyPolicyId } = useParams();
  const navigate = useNavigate();
  const { handleModalClose, modalContent, showModal } = useModal();

  const {
    userInfo: { name },
    displayedUserInfo,
    selectedUser: { userType },
    selectedUserId,
    isTokenExpired,
    getAccessToken,
    setOpenSessionTimeoutModal,
  } = useContext(AppContext);

  const { openSnackbar } = useContext(ThemeContext);
  const { privacyPoliciesList, setPrivacyPoliciesList } = useContext(
    PrivacyPoliciesContext
  );

  const { getValues, setValue } = useFormContext();

  // detect the user leaving the page
  const [isLeavingDialogActive, setIsLeavingDialogActive] =
    useState<boolean>(true);

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    isLeavingDialogActive && getValues('userType') !== 'network'
  );

  useEffect(() => {
    if (isTokenExpired) {
      setIsLeavingDialogActive(false);

      if (getValues('status') === 'draft') {
        const formData = getValues();
        const defaultSaveData: { [key: string]: string } = {
          policyTextEn:
            '<p class="editor_paragraph" dir="ltr"><span></span></p>',
          policyTextFr:
            '<p class="editor_paragraph" dir="ltr"><span></span></p>',
        };

        for (const [key, value] of Object.entries(defaultSaveData)) {
          if (!formData[key]) {
            setValue(key, value);
          }
        }

        handleSave(true);
      } else {
        getSessionTimeoutModal();
      }
    }
  }, [isTokenExpired]);

  const updatePrivacyPoliciesList = (updatedPolicy: IPrivacyPoliciesData) => {
    const updatedPolicies = privacyPoliciesList.map((policy) =>
      policy.id === updatedPolicy.id ? updatedPolicy : policy
    );
    setPrivacyPoliciesList(updatedPolicies);
  };

  // handling action ERROR
  const handleActionError = (sessionTimeout: boolean = false) => {
    if (sessionTimeout) {
      getSessionTimeoutModal();
      return;
    }
    setIsLeavingDialogActive(true);
    showModal(
      {
        message: t('errorDialogMessage'),
        title: t('errorDialogTitle'),
        handleAgree: handleModalClose,
      },
      true
    );
  };

  // handling DELETE action
  const handleDelete = () => {
    showModal({
      message: t('deletePolicyDialogMessage'),
      title: t('deletePolicyDialogTitle'),
      handleAgree: handleDeleteConfirm,
      handleClose: handleModalClose,
    });
  };

  const handleDeleteConfirm = async () => {
    try {
      setIsLeavingDialogActive(false);

      const token = await getAccessToken();

      const status = await deletePolicyApi(getValues('id'), token);

      if (status === 200) {
        openSnackbar(t('deletePolicySnackbarMessage'));
        navigate(PRIVACY_POLICIES_PATH);
      }
    } catch (error) {
      handleActionError();
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // handling UPDATE DRAFT action
  const handleSave = async (sessionTimeout: boolean = false) => {
    try {
      setIsLeavingDialogActive(false);

      const { dateCreated, policyTextEn, policyTextFr, provinces, status } =
        getValues();

      const values: IPrivacyPoliciesData = {
        createdBy: name,
        dateCreated,
        datePublished: null,
        dateUpdated: null,
        id: privacyPolicyId as string,
        policyTextEn,
        policyTextFr,
        provinces,
        status,
        updatedBy: name,
        userId: selectedUserId,
        userType,
      };

      await handleSubmitPrivacyPolicy(values, true, sessionTimeout);
    } catch (error) {
      handleActionError(sessionTimeout);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // handling PUBLISH action
  const handlePublish = async () => {
    try {
      const status = getValues('status');

      const companyName = displayedUserInfo.name;

      showModal({
        message: t(
          status === 'draft'
            ? 'publishPolicyDialogMessage'
            : 'publishUpdatePolicyDialogMessage',
          { companyName }
        ),
        title: t('publishUpdateDialogTitle'),
        handleAgree: handlePublishConfirm,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handlePublishConfirm = async () => {
    setIsLeavingDialogActive(false);

    try {
      const values = getValues();

      const postData: IPrivacyPolicyValues = {
        ...(values as IPrivacyPolicyValues),
        updatedBy: name,
        status: 'published',
      };

      await handleSubmitPrivacyPolicy(postData);
    } catch (error) {
      handleActionError();
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // handling SUBMIT

  const handleSubmitPrivacyPolicy = async (
    postData: IPrivacyPolicyValues,
    isSave: boolean = false,
    sessionTimeout?: boolean
  ) => {
    try {
      const token = await getAccessToken();
      const { policyData, status } = await updatePolicyApi(postData, token);

      if (status === 200) {
        updatePrivacyPoliciesList(policyData);
        openSnackbar(
          t(
            isSave
              ? 'policySaveSnackbarMessage'
              : 'policyPublishSnackbarMessage',
            {
              name,
            }
          )
        );

        if (isSave && sessionTimeout) {
          getSessionTimeoutModal(isSave && sessionTimeout);
        } else if (!isSave) {
          privacyPolicyId &&
            getPrivacyPolicyDataById &&
            getPrivacyPolicyDataById(privacyPolicyId);
          handleModalClose();
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // handling LEAVE modal close
  const handleLeaveConfirm = () => {
    confirmNavigation(async () => {
      // const token = await getAccessToken();
    });
  };

  // handling SESSION TIMEOUT modal
  const getSessionTimeoutModal = (autoSave: boolean = false) => {
    setOpenSessionTimeoutModal({
      open: true,
      path: 'privacypolicies',
      autoSave,
      callback: () => {},
    });
  };

  return (
    <>
      <PrivacyPolicyLinkBack
        handleDelete={handleDelete}
        handleSave={() => {
          handleSave();
        }}
        handlePublish={handlePublish}
      />

      <StyledContainer>
        <PrivacyPolicyContent />
      </StyledContainer>

      <Dialog
        buttonAgree={modalContent.buttonAgree}
        buttonClose={modalContent.buttonClose}
        message={modalContent.message}
        title={modalContent.title}
        open={modalContent.open}
        handleAgree={modalContent.handleAgree}
        handleClose={modalContent.handleClose}
      />

      {/* the user leaving the page */}
      <Dialog
        buttonAgree={t('returnToPrivacyPolicy')}
        buttonClose={t('exitWithoutSaving')}
        message={t('unsavedChangesPrivacyPolicyMessage')}
        title={t('unsavedChanges')}
        open={showPrompt}
        handleAgree={cancelNavigation}
        handleClose={handleLeaveConfirm}
      />
    </>
  );
};

const StyledContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 4, 3),
}));
