import {
  styled,
  Stack,
  Typography,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Chip,
} from '@mui/material';
import { useState, ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Dialog,
  Error,
  PrivacyPoliciesTable,
  PrivacyPoliciesTop,
  PrivacyPoliciesSkeleton,
  PrivacyPoliciesHeaderSkeleton,
  ProvinceAutoComplete,
} from '@/admin/components';
import { PRIVACY_POLICIES_PATH, provinceInitials } from '@/admin/consts';
import { useModal } from '@/admin/hooks';
import {
  AppContext,
  defaultPrivacyPolicyValues,
  PrivacyPoliciesContext,
  ThemeContext,
} from '@/admin/providers';
import { addPolicyApi } from '@/admin/utils/helpers-api';
import {
  IPrivacyPolicyValues,
  Province,
  IPrivacyPoliciesData,
} from '@/common/types';

export const PrivacyPolicies = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { modalContent, showModal, handleModalClose } = useModal();
  const navigate = useNavigate();

  const { allPolicies, isLoading, isError, setIsError } = useContext(
    PrivacyPoliciesContext
  );
  const { openSnackbar } = useContext(ThemeContext);

  const {
    getAccessToken,
    userInfo: { name },
    selectedUser: { userType },
    selectedUserId,
  } = useContext(AppContext);

  const [selectedProvinces, setSelectedProvinces] = useState<Province[]>([]);
  const [copiedPolicy, setCopiedPolicy] = useState<IPrivacyPoliciesData | null>(
    null
  );

  const handleOpenModal = async () => {
    showModal({
      title: t('privacyPolicyAddTitle'),
      message: '',
      buttonAgree: t('createPolicy'),
      handleAgree: handleSave,
      handleClose: handleClose,
    });
  };

  const handleSave = async () => {
    try {
      const token = await getAccessToken();

      //if copiedPolicy is true we take the values from chosen policy
      const policyTextEn = copiedPolicy ? copiedPolicy.policyTextEn : '<p></p>';
      const policyTextFr = copiedPolicy ? copiedPolicy.policyTextFr : '<p></p>';

      const values: IPrivacyPolicyValues = {
        ...defaultPrivacyPolicyValues,
        createdBy: name,
        policyTextEn,
        policyTextFr,
        provinces: selectedProvinces,
        updatedBy: name,
        userId: selectedUserId,
        userType,
      };

      const { policyId, status } = await addPolicyApi(values, token);

      if (status === 201 && userType !== 'network') {
        navigate(`${PRIVACY_POLICIES_PATH}/${policyId}`);
        openSnackbar(t('policyCreateSnackbarMessage'));
      }
    } catch (error) {
      setIsError(true);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleClose = () => {
    setSelectedProvinces([]);
    handleModalClose();
  };

  const handleCopyChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'yes') {
      setCopiedPolicy(allPolicies[0]);
    } else {
      setCopiedPolicy(null);
    }
  };

  //set the policy to be copied in modal
  const handleChangePolicy = (value: string | number) => {
    const selectedPolicy = allPolicies.find((policy) => policy.id === value);
    if (selectedPolicy) {
      setCopiedPolicy(selectedPolicy);
    }
  };

  //when "copy" is chosen in table actions
  const handleCopyClick = (privacyPolicy: IPrivacyPoliciesData) => {
    setCopiedPolicy(privacyPolicy);

    handleOpenModal();
  };

  const renderProvinces = (provinces: string[]) => {
    const firstSevenProvinces = provinces.slice(0, 7); // Get the first 7 provinces

    const provinceChips = firstSevenProvinces.map((province) => (
      <StyledChip
        variant="outlined"
        key={province}
        label={provinceInitials[language][province] as string}
      />
    ));

    if (provinces.length > 7) {
      provinceChips.push(
        <StyledChip variant="outlined" key="ellipsis" label="..." />
      );
    }

    return provinceChips;
  };

  if (isError)
    return (
      <Error
        message={t('errorOccurredMessage')}
        title={t('errorOccurredTitle')}
      />
    );

  return (
    <>
      {isLoading ? (
        <>
          <PrivacyPoliciesHeaderSkeleton />
          <PrivacyPoliciesSkeleton />
        </>
      ) : (
        <>
          <PrivacyPoliciesTop handleOpenModal={handleOpenModal} />

          <StyledTable>
            <PrivacyPoliciesTable
              handleCopyClick={handleCopyClick}
              handleOpenModal={handleOpenModal}
            />
          </StyledTable>

          <Dialog
            buttonAgree={modalContent.buttonAgree}
            message={modalContent.message}
            title={modalContent.title}
            open={modalContent.open}
            handleClose={modalContent.handleClose}
            handleAgree={handleSave}
            hasCloseIconButton
          >
            <Stack>
              <StyledTypography variant="body2">
                {t('provinces')}
              </StyledTypography>
              <ProvinceAutoComplete
                provinces={selectedProvinces}
                setProvinces={setSelectedProvinces}
              />
              {!!allPolicies.length && (
                <StyledFormControl>
                  <StyledTypography variant="body2">
                    {t('copyPolicy')}
                  </StyledTypography>
                  <RadioGroup
                    name="controlled-radio-buttons-group"
                    value={copiedPolicy ? 'yes' : 'no'}
                    onChange={handleCopyChange}
                    row
                  >
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label={t('no')}
                    />
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label={t('yes')}
                    />
                  </RadioGroup>
                </StyledFormControl>
              )}
              {!!copiedPolicy && (
                <>
                  <StyledTypography variant="body2">
                    {t('duplicatePolicy')}
                  </StyledTypography>

                  <StyledSelect
                    onChange={(event) =>
                      handleChangePolicy(event.target.value as string | number)
                    }
                    value={copiedPolicy.id}
                  >
                    {allPolicies.map((policy: IPrivacyPoliciesData) => (
                      <MenuItem key={policy.id} value={policy.id}>
                        {t(
                          policy.userType === 'network' ? 'corporate' : 'custom'
                        )}{' '}
                        {renderProvinces(policy.provinces)}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </>
              )}
            </Stack>
          </Dialog>
        </>
      )}
    </>
  );
};

const StyledTable = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.spacing(4, 8, 4, 6),
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  height: theme.spacing(7),
  margin: theme.spacing(1.5, 0),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.25, 0, 0, 1),
  backgroundColor: theme.palette.grey[300],
  borderColor: theme.palette.grey[500],
}));

const StyledTypography = styled(Typography)({
  fontWeight: 500,
});
