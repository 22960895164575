import { getHeaders } from '.';
import { REACT_APP_PROXY_API_URL } from '@/admin/config/variables';
import { FETCH_POLICY_URL } from '@/admin/consts';
import { IPrivacyPoliciesData, IPrivacyPolicyValues } from '@/common/types';

export const addPolicyApi = async (
  data: IPrivacyPolicyValues,
  token: string
) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_POLICY_URL}`;
  const headers = getHeaders(token);

  const res = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });
  const { status, data: policyData } = await res.json();

  return { status, policyId: policyData.id };
};

export const updatePolicyApi = async (
  data: IPrivacyPolicyValues,
  token: string
) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_POLICY_URL}`;
  const headers = getHeaders(token);

  const res = await fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data),
  });
  const { status, data: policyData } = await res.json();

  return { status, policyData };
};

export const deletePolicyApi = async (policyId: string, token: string) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_POLICY_URL}/${policyId}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { method: 'DELETE', headers });
  const { status } = await res.json();

  return status;
};

export const getPolicyByIdApi = async (token: string, policyId: string) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_POLICY_URL}/${policyId}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { headers });
  const { status, data }: { status: number; data: IPrivacyPoliciesData } =
    await res.json();

  // Check if data is an array
  if (Array.isArray(data) && data.length > 0) {
    return { status, data: data[0] }; // Return the first object in the array
  }

  return { status, data };
};

export const getPoliciesApi = async ({
  networkId,
  token,
  userType,
  userId,
}: {
  networkId: number;
  token: string;
  userType: string;
  userId: number;
}) => {
  const networkIdQuery = `&networkId=${networkId}`;
  const userTypeQuery = `?userType=${userType}`;
  const userIdQuery = `&userId=${userId}`;

  const url = `${REACT_APP_PROXY_API_URL}${FETCH_POLICY_URL}${userTypeQuery}${userIdQuery}${networkIdQuery}`;

  const headers = getHeaders(token);

  const res = await fetch(url, { headers });
  const { data }: { data: IPrivacyPoliciesData[] } = await res.json();

  return data;
};

export const getCorporatePolicyApi = async (
  domain: string,
  networkId: number,
  token: string
) => {
  const domainQuery = `?domain=${domain}`;
  const networkIdQuery = `&networkId=${networkId}`;

  const url = `${REACT_APP_PROXY_API_URL}${FETCH_POLICY_URL}/corporate${domainQuery}${networkIdQuery}`;
  const headers = getHeaders(token);

  const res = await fetch(url, {
    headers,
  });
  const { status, data } = await res.json();

  return { status, data };
};
