import { styled, Tooltip, Typography } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterNavLink } from 'react-router-dom';

import { Icon } from '@/admin/components';

type NavLinkProps = {
  icon: string;
  isOpen: boolean;
  title: string;
  to: string;
  isSecondary?: boolean;
};

export const NavLink = ({
  icon,
  isOpen,
  isSecondary,
  title,
  to,
}: NavLinkProps) => {
  const { t } = useTranslation();

  const NavLinkContent = forwardRef(function NavLinkContent(
    props,
    ref: ForwardedRef<HTMLAnchorElement>
  ) {
    return (
      <StyledNavLinkContent
        {...props}
        className={({ isActive }: { isActive: boolean }) =>
          isActive ? 'active' : undefined
        }
        to={to}
        ref={ref}
      >
        <Icon size="md" symbol={icon} />
        {isOpen && <StyledTitle variant="subtitle1">{t(title)}</StyledTitle>}
      </StyledNavLinkContent>
    );
  });

  return (
    <StyledNavLink
      className={`${!isOpen ? 'closed' : ''} ${isSecondary ? 'secondary' : ''}`}
    >
      {isOpen && <NavLinkContent />}

      {!isOpen && (
        <Tooltip title={t(title)} arrow placement="right">
          <NavLinkContent />
        </Tooltip>
      )}
    </StyledNavLink>
  );
};

const StyledNavLink = styled('div')(({ theme }) => ({
  '&.secondary': {
    '& > *': {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
  },

  '&.closed > a': {
    justifyContent: 'center',
    paddingLeft: theme.spacing(1.5),
  },

  [theme.breakpoints.down('lg')]: {
    '& > a': {
      justifyContent: 'center',
      paddingLeft: theme.spacing(1.5),
    },
  },
}));

const StyledNavLinkContent = styled(RouterNavLink)(({ theme }) => ({
  alignItems: 'center',
  borderLeft: '4px solid transparent',
  display: 'flex',
  height: '48px',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(2),
  transition: 'all 0.3s',

  '&:hover': {
    backgroundColor: theme.palette.primary.lighter,
  },

  '&.active': {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.main,
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2.5),

  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));
