import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Breakpoint,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import { ReactNode } from 'react';

export type DialogProps = {
  buttonAgree: string | null;
  buttonClose?: string | null;
  children?: ReactNode;
  disableAgreeButton?: boolean;
  disableCloseButton?: boolean;
  handleAgree: () => void;
  handleClose?: () => void;
  hasCloseIconButton?: boolean;
  open: boolean;
  actionsChildren?: ReactNode;
  maxWidth?: Breakpoint;
  message?: string;
  title: string;
};

export const Dialog = ({
  actionsChildren,
  buttonAgree,
  buttonClose,
  children,
  disableAgreeButton = false,
  disableCloseButton = false,
  handleAgree,
  handleClose,
  hasCloseIconButton,
  maxWidth = 'sm',
  message,
  open,
  title,
}: DialogProps) => {
  return (
    <MuiDialog
      fullWidth
      open={open}
      maxWidth={maxWidth}
      onClose={(e, reason) => {
        if (reason && reason == 'backdropClick' && 'escapeKeyDown') return;
        handleClose && handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {hasCloseIconButton && (
        <StyledButtonContainer>
          <StyledCloseIconButton onClick={handleClose}>
            <Close />
          </StyledCloseIconButton>
        </StyledButtonContainer>
      )}

      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

      <DialogContent>
        {message && (
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        )}
        {children}
      </DialogContent>

      <StyledDialogActions>
        {actionsChildren && <Box flexGrow={1}>{actionsChildren}</Box>}
        {buttonClose && (
          <Button
            color="primary"
            disabled={disableCloseButton}
            variant="outlined"
            onClick={handleClose}
          >
            {buttonClose}
          </Button>
        )}

        {buttonAgree && (
          <Button
            autoFocus
            color="primary"
            disabled={disableAgreeButton}
            variant="contained"
            onClick={handleAgree}
          >
            {buttonAgree}
          </Button>
        )}
      </StyledDialogActions>
    </MuiDialog>
  );
};

const StyledCloseIconButton = styled(IconButton)({
  padding: 0,
});

const StyledButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

const StyledDialogActions = styled(DialogActions)({
  display: 'flex',
});
