import { styled } from '@mui/material';
import { forwardRef, ReactNode } from 'react';

type ToolbarButtonProps = {
  ariaLabel: string;
  children: ReactNode;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
};

type Ref = HTMLButtonElement;

export const ToolbarButton = forwardRef<Ref, ToolbarButtonProps>(
  ({ ariaLabel, children, onClick, className, disabled }, ref) => {
    return (
      <StyledButton
        aria-label={ariaLabel}
        className={className}
        disabled={disabled}
        ref={ref}
        type="button"
        onClick={onClick}
      >
        {children}
      </StyledButton>
    );
  }
);

ToolbarButton.displayName = 'ToolbarButton';

const StyledButton = styled('button')(({ theme }) => ({
  color: theme.palette.common.white,
  border: 0,
  display: 'flex',
  background: 'none',
  borderRadius: '4px',
  padding: '6px',
  cursor: 'pointer',
  verticalAlign: 'middle',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
  marginRight: '3px',

  '&.blockType': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    margin: '0 6px',
    padding: '2px 6px',
  },

  '& .buttonText': {
    display: 'flex',
    lineHeight: '20px',
    verticalAlign: 'middle',
    fontSize: '14px',
    height: '20px',
    textAlign: 'left',
    paddingRight: '10px',
  },

  '&.active': { backgroundColor: 'rgba(223, 232, 250, 0.3)' },

  '&:hover:not([disabled])': {
    backgroundColor: theme.palette.grey[400],
  },

  '&:disabled': { color: 'rgba(223, 232, 250, 0.3)', cursor: 'initial' },
}));
