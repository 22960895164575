import { Grid, Stack, styled } from '@mui/material';
import { DateValidationError } from '@mui/x-date-pickers/models';
import dayjs, { Dayjs } from 'dayjs';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  DatePicker,
  InputFile,
  Select,
  Switch,
  TextField,
} from '@/admin/components';
import { buttonStylesAnnounce } from '@/admin/consts';
import { useImageUpload } from '@/admin/hooks';
import { AppContext, useStorageBlobCleanContext } from '@/admin/providers';
import { uploadImageApi } from '@/admin/utils/helpers-api';
import { AnnouncementsTitle, AnnouncementsValues } from '@/common/types';

type AnnouncementsFormProps = {
  currentAnnouncement: AnnouncementsTitle;
};

export const AnnouncementsForm = ({
  currentAnnouncement,
}: AnnouncementsFormProps) => {
  const { t } = useTranslation();
  const { selectedUserId, getAccessToken } = useContext(AppContext);
  const {
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext<AnnouncementsValues>();
  const {
    imageURL,
    isLoading,
    error,
    handleAddImageURL,
    handleDeleteImage,
    handleUploadImage,
    verifyImageFormat,
    verifySingleImage,
  } = useImageUpload();
  const { addImageSrc, addInitialImageSrc } = useStorageBlobCleanContext();

  const getDefaultPickerDate = (serverValue: number | null) => {
    if (!serverValue) return null;

    const yyyy_mm_dd = new Date(serverValue)
      .toLocaleDateString()
      .replace(/(\d+)\/(\d+)\/(\d+)/g, '$3-$1-$2');

    return dayjs(yyyy_mm_dd);
  };

  const [launchDate, setLaunchDate] = useState<Dayjs | null>(
    getDefaultPickerDate(getValues(`${currentAnnouncement}.launchDate`))
  );
  const [expiryDate, setExpiryDate] = useState<Dayjs | null>(
    getDefaultPickerDate(getValues(`${currentAnnouncement}.expiryDate`))
  );
  // invalid date does not block submission 
  const [launchDateError, setLaunchDateError] = useState<DateValidationError | null>(null); 
  const [expiryDateError, setExpiryDateError] = useState<DateValidationError | null>(null);
  const [isDisablePastActive, setIsDisablePastActive] = useState<boolean>(false); // for launch date: only shows error on Change

  const currentMessageErrors = errors[currentAnnouncement];
  const isHidden = watch(`${currentAnnouncement}.visibility`) === 'hidden';

  useEffect(() => {
    const imageSrc = getValues(`${currentAnnouncement}.image`);
    if (imageSrc) {
      handleAddImageURL(imageSrc);
      addInitialImageSrc(imageSrc);
    }
  }, []);

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(
      `${currentAnnouncement}.visibility`,
      e.target.checked ? 'hidden' : 'visible',
      { shouldDirty: true }
    );
  };

  const handleLaunchDateChange = (newValue: unknown) => {
    const value = newValue as Dayjs;
    setLaunchDate(value);
    setValue(
      `${currentAnnouncement}.launchDate`,
      Date.parse(value.toISOString()),
      { shouldDirty: true }
    );
    setIsDisablePastActive(true);
  };

  const handleExpiryDateChange = (newValue: unknown) => {
    const value = newValue as Dayjs;
    setExpiryDate(value);
    setValue(
      `${currentAnnouncement}.expiryDate`,
      Date.parse(value.toISOString()),
      { shouldDirty: true }
    );
  };

  const handleUploadFile = async (files: FileList) => {
    const isSingleImage = verifySingleImage(files);
    if (!isSingleImage) return;

    const isValidImage = verifyImageFormat(files[0]);
    if (!isValidImage) return;

    const token = await getAccessToken();
    const src = await handleUploadImage(() =>
      uploadImageApi(files[0], token, selectedUserId)
    );
    if (!src) return;

    addImageSrc(src);
    setValue(`${currentAnnouncement}.image`, src, { shouldDirty: true });
  };

  const handleDeleteFile = async () => {
    handleDeleteImage();
    setValue(`${currentAnnouncement}.image`, '', { shouldDirty: true });
  };

  return (
    <StyledForm>
      <Stack spacing={3}>
        <Stack direction="row" spacing={3}>
          <Controller
            name={`${currentAnnouncement}.title`}
            render={({ field }) => (
              <TextField
                error={!!currentMessageErrors?.title}
                helperText={currentMessageErrors?.title?.message as string}
                label={`${t('title')}`}
                placeholder={t('titlePlaceholder')}
                {...field}
              />
            )}
          />

          <Switch
            ariaLabel="status"
            checked={isHidden}
            hasIcon
            label={'visibility'}
            textChecked="hidden"
            textDefault="visible"
            width={112}
            onChange={handleStatusChange}
          />
        </Stack>

        <Controller
          name={`${currentAnnouncement}.paragraph`}
          render={({ field }) => (
            <TextField
              error={!!currentMessageErrors?.paragraph}
              helperText={currentMessageErrors?.paragraph?.message as string}
              label={`${t('paragraph')}`}
              multiline
              placeholder={t('paragraphPlaceholder')}
              {...field}
            />
          )}
        />

        <div>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <Stack spacing={2}>
                <Controller
                  name={`${currentAnnouncement}.buttonText`}
                  render={({ field }) => (
                    <TextField
                      error={!!currentMessageErrors?.buttonText}
                      helperText={
                        currentMessageErrors?.buttonText?.message as string
                      }
                      label={`${t('buttonText')}`}
                      placeholder={t('buttonTextPlaceholder')}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name={`${currentAnnouncement}.buttonUrl`}
                  render={({ field }) => (
                    <TextField
                      error={!!currentMessageErrors?.buttonUrl}
                      helperText={
                        currentMessageErrors?.buttonUrl?.message as string
                      }
                      label={`${t('buttonUrl')}`}
                      placeholder={t('buttonUrlPlaceholder')}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name={`${currentAnnouncement}.buttonStyle`}
                  render={({ field }) => (
                    <Select
                      ariaLabel="Button style"
                      label={`${t('buttonStyle')}:`}
                      options={buttonStylesAnnounce}
                      {...field}
                    />
                  )}
                />

                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DatePicker
                        disablePast={isDisablePastActive}
                        label={t('launchDate')}
                        value={launchDate}
                        onChange={handleLaunchDateChange}
                        onError={(newError: DateValidationError) => setLaunchDateError(newError)}
                        errorMessage={launchDateError ? t('announcementsErrorDateBeforeToday') : ''}              
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <DatePicker
                        disablePast
                        label={t('expiryDate')}
                        value={expiryDate}
                        onChange={handleExpiryDateChange}
                        minDate={launchDate}
                        onError={(newError: DateValidationError) => setExpiryDateError(newError)}
                        errorMessage={expiryDateError ? t('announcementsErrorExpiryDateBeforeLaunch') : ''}              
                      />
                    </Grid>
                  </Grid>
                </div>
              </Stack>
            </Grid>

            <Grid item xs={5}>
              <InputFile
                error={error}
                image={imageURL}
                isLoading={isLoading}
                label={t('image')}
                handleDeleteImage={handleDeleteFile}
                handleUploadImage={handleUploadFile}
              />
            </Grid>
          </Grid>
        </div>
      </Stack>
    </StyledForm>
  );
};

const StyledForm = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));
