import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Stack, styled, Tab, Tabs, Typography } from '@mui/material';
import { ReactNode } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import { theme } from '@/admin/providers';
import { IDragDropTab } from '@/admin/types/common';

type DragDropTabsProps = {
  tabs: IDragDropTab[];
  value: number;
  onDragEnd: (result: DropResult) => void;
  onTabClick: (tab: IDragDropTab, index: number) => void;
  setIcon?: (tabId: number) => ReactNode;
};

export const DragDropTabs = ({
  tabs,
  value,
  setIcon,
  onDragEnd,
  onTabClick,
}: DragDropTabsProps) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="droppable" direction="horizontal">
      {(provided, snapshot) => (
        <StyledTabs
          className={snapshot.isDraggingOver ? 'is-dragging' : ''}
          ref={provided.innerRef}
          {...provided.droppableProps}
          value={value}
        >
          {tabs.map((tab, index) => (
            <Draggable
              key={tab.id}
              disableInteractiveElementBlocking
              draggableId={tab.id}
              index={index}
            >
              {(provided, snapshot) => (
                <StyledTab
                  className={value === index ? 'Mui-selected' : ''}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    background: snapshot.isDragging
                      ? theme.palette.primary.light
                      : 'transparent',
                    ...provided.draggableProps.style,
                  }}
                  icon={
                    <Stack direction="row" spacing={1}>
                      {setIcon && (
                        <StyledIcon>{setIcon(Number(tab.id))}</StyledIcon>
                      )}
                      <DragIndicatorIcon color="primary" />
                    </Stack>
                  }
                  iconPosition="end"
                  label={
                    <div className="tab-content">
                      {tab.subTitle && (
                        <StyledTypography variant="caption">
                          {tab.subTitle}
                        </StyledTypography>
                      )}
                      <div className="title">{tab.title}</div>
                    </div>
                  }
                  onClick={() => {
                    onTabClick(tab, index);
                  }}
                />
              )}
            </Draggable>
          ))}
        </StyledTabs>
      )}
    </Droppable>
  </DragDropContext>
);

const StyledTabs = styled(Tabs)(({ theme }) => ({
  boxShadow: `0px 4px 4px ${theme.palette.shadow?.light}`,

  '&.is-dragging': {
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  borderRight: `1px solid ${theme.palette.grey[400]}`,
  justifyContent: 'space-between',
  opacity: 1,
  width: '100%',

  '&:last-child': {
    borderRight: 'none',
  },

  '& .tab-content': {
    flexGrow: 1,
    overflow: 'hidden',
    textAlign: 'left',
  },

  '& .title': {
    overflow: 'hidden',
    position: 'relative',
    whiteSpace: 'nowrap',

    '&::after': {
      background:
        'linear-gradient(270deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 195.83%)',
      bottom: 0,
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '36px',
    },
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'block',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(0.25),
}));

const StyledIcon = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  lineHeight: 0,
}));
