import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';

import { $isImageNode } from '@/admin/components/blogEditor/editorNodes';
import { useStorageBlobCleanContext } from '@/admin/providers';

export const OnLoadPlugin = () => {
  const [editor] = useLexicalComposerContext();

  const { addInitialImageSrc } = useStorageBlobCleanContext();

  useEffect(() => {
    // Add image src to provider on edit blog page loading
    editor.getEditorState()._nodeMap.forEach((node) => {
      if ($isImageNode(node)) {
        addInitialImageSrc(node.__src);
      }
    });
  }, []);

  return null;
};
