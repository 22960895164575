import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HomepageImage, Top } from '@/admin/components';

export const Homepage = () => {
  const { t } = useTranslation();

  return (
    <StyledHomepage>
      <Top title={t('homepage')} />

      <HomepageImage />
    </StyledHomepage>
  );
};

const StyledHomepage = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});
