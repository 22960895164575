import { styled } from '@mui/material';

type LazyImageProps = {
  altText: string;
  className: string | null;
  height: 'inherit' | number;
  imageRef: { current: null | HTMLImageElement };
  maxWidth: number;
  src: string;
  width: 'inherit' | number;
};

const imageCache = new Set();

const useSuspenseImage = (src: string) => {
  if (!imageCache.has(src)) {
    throw new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        imageCache.add(src);
        resolve(null);
      };
    });
  }
};

export const LazyImage = ({
  altText,
  className,
  imageRef,
  src,
  width,
  height,
  maxWidth,
}: LazyImageProps) => {
  useSuspenseImage(src);
  return (
    <StyledImg
      className={className || undefined}
      src={src}
      alt={altText}
      ref={imageRef}
      style={{
        height,
        maxWidth,
        width,
      }}
      draggable="false"
    />
  );
};

const StyledImg = styled('img')({
  maxWidth: '100%',
  cursor: 'default',

  '&.focused': {
    outline: '2px solid rgb(60, 132, 244)',
    userSelect: 'none',
  },

  '&.focused.draggable': {
    cursor: 'grab',
  },

  '&.focused.draggable:active': {
    cursor: 'grabbing',
  },
});
