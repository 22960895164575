import { styled } from '@mui/material';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AnnouncementsPanel,
  AnnouncementsTop,
  BackdropLoader,
  Dialog,
  Toolbar,
} from '@/admin/components';
import { langIdByLang } from '@/admin/consts';
import { useCallbackPrompt, useModal } from '@/admin/hooks';
import {
  AppContext,
  ThemeContext,
  useStorageBlobCleanContext,
} from '@/admin/providers';
import { IDragDropTab } from '@/admin/types/common';
import { getAnnouncementKey, getBrand } from '@/admin/utils/helpers';
import {
  createAnnouncementsApi,
  updateAnnouncementsApi,
} from '@/admin/utils/helpers-api';
import {
  AnnouncementAction,
  AnnouncementsTitle,
  AnnouncementsValues,
  IAnnouncementsData,
  IAnnouncementsValues,
} from '@/common/types';

const DEFAULT_LANG_TAB = 0;
const DEFAULT_TITLE_TAB = 0;

type AnnouncementsProps = {
  announcementsData: IAnnouncementsData;
  fetchAnnouncements: () => Promise<AnnouncementsValues>;
};

export const Announcements = ({
  announcementsData,
  fetchAnnouncements,
}: AnnouncementsProps) => {
  const { t } = useTranslation();
  const {
    formState: { errors, isDirty },
    getValues,
    trigger,
    reset,
  } = useFormContext<AnnouncementsValues>();
  const {
    isTokenExpired,
    selectedUser: { networkId: selectedUserNetworkId },
    userInfo: { name, networkId: userNetworkId, role },
    getAccessToken,
    setOpenSessionTimeoutModal,
  } = useContext(AppContext);
  const { openSnackbar } = useContext(ThemeContext);
  const { modalContent, showModal, handleModalClose } = useModal();
  const { removeAddedImageSrc, removeUnusedImageSrc } =
    useStorageBlobCleanContext();

  const { datePublished, dateSaved, id } = announcementsData;
  const networkId =
    role === 'm3Tech admin' ? selectedUserNetworkId : userNetworkId;

  const announcement = t('announcement');
  const noAnnouncement = t('noAnnouncement');

  const defaultAnnouncements: IDragDropTab[] = [
    { id: '0', subTitle: `${announcement} 1`, title: noAnnouncement },
    { id: '1', subTitle: `${announcement} 2`, title: noAnnouncement },
    { id: '2', subTitle: `${announcement} 3`, title: noAnnouncement },
  ];

  // lang tabs state
  const langTabs: string[] = [t('english'), t('french')];
  const [langTabsValue, setLangTabsValue] = useState<number>(DEFAULT_LANG_TAB);

  // title tabs state
  const [titleTabsValue, setTitleTabsValue] =
    useState<number>(DEFAULT_TITLE_TAB);
  const [titleTabs, setTitleTabs] =
    useState<IDragDropTab[]>(defaultAnnouncements);
  const [prevTitleTabs, setPrevTitleTabs] =
    useState<IDragDropTab[]>(defaultAnnouncements);

  // announcements state
  const [currentAnnouncementId, setCurrentAnnouncementId] =
    useState<number>(DEFAULT_TITLE_TAB);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOnlyEnglish, setIsOnlyEnglish] = useState<boolean>(false);

  // detect the user leaving the page
  const [isLeavingDialogActive, setIsLeavingDialogActive] =
    useState<boolean>(false);
  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    isLeavingDialogActive
  );

  useEffect(() => {
    isDirty !== isLeavingDialogActive && setIsLeavingDialogActive(isDirty);
  }, [isDirty]);

  useEffect(() => {
    (async () => {
      if (isTokenExpired) {
        setIsLeavingDialogActive(false);
        await handleSubmit('save');
      }
    })();
  }, [isTokenExpired]);

  // display the announcenment with the errors on submmit
  useEffect(() => {
    const errorAnnouncement = Object.keys(errors)[0] as AnnouncementsTitle;

    if (errorAnnouncement) {
      const values = getValues();
      const errorValues = values[errorAnnouncement];

      const currentAnnouncementId = Number(errorAnnouncement.slice(-1));
      const langTabsValue = langIdByLang[errorValues.language];

      moveToAnnouncement(
        currentAnnouncementId,
        langTabsValue,
        errorValues.order
      );
    }
  }, [errors]);

  useEffect(() => {
    setIsOnlyEnglish(getBrand(networkId) === 'invis');
  }, [networkId]);

  const handleChangeLangTabsValue = (e: SyntheticEvent, newValue: number) => {
    const currentAnnouncementId = Number(prevTitleTabs[0].id);
    moveToAnnouncement(currentAnnouncementId, newValue, 0);
  };

  const moveToAnnouncement = (
    currentAnnouncementId: number,
    langTabsValue: number,
    titleTabsValue: number
  ) => {
    const updatedTabs = [...prevTitleTabs].map((tab) => {
      const currentAnnouncement = getAnnouncementKey(
        langTabsValue,
        Number(tab.id)
      );
      const currentAnnouncementTitle = getValues(
        `${currentAnnouncement}.title`
      );

      return {
        ...tab,
        title: currentAnnouncementTitle || noAnnouncement,
      };
    });

    setPrevTitleTabs(titleTabs);
    setTitleTabs(updatedTabs);
    setLangTabsValue(langTabsValue);
    setTitleTabsValue(titleTabsValue);
    setCurrentAnnouncementId(currentAnnouncementId);
  };

  // handling SAVE, PUBLISH
  const handleOpenDialog = async (action: AnnouncementAction) => {
    const hasNoError = await trigger();

    if (hasNoError) {
      showModal({
        message: t(
          action === 'save'
            ? 'confirmSavingMessage'
            : 'confirmPublicationToAllMessage'
        ),
        title: t(action === 'save' ? 'confirmSaving' : 'confirmPublication'),
        handleAgree: () => handleAgreeDialog(action),
      });
    }
  };

  const handleAgreeDialog = (action: AnnouncementAction) => {
    handleModalClose();
    handleSubmit(action);
  };

  const handleSubmit = async (action: AnnouncementAction) => {
    const isSessionTimeAutoSave = isTokenExpired && action === 'save';

    try {
      setIsLoading(true);

      const announcements = Object.values(getValues());

      announcements.forEach((announcement) => {
        announcement.status = action === 'publish' ? 'published' : 'saved';
      });

      // remove the french announcements for invis
      if (isOnlyEnglish) announcements.length = 3;

      const values: IAnnouncementsValues = {
        action,
        announcements,
        id,
        networkId,
        updatedBy: name,
      };

      const token = await getAccessToken();

      const data = id
        ? await updateAnnouncementsApi(values, token)
        : await createAnnouncementsApi(values, token);

      if (data) {
        const updatedData = await fetchAnnouncements();
        reset(updatedData); // To reset isDirty value

        const images: string[] = [];

        announcements.forEach(({ image }) => {
          if (image) images.push(image);
        });

        removeUnusedImageSrc(token, images);
        setIsLoading(false);
        openSnackbar(
          action === 'publish'
            ? t('announcementsPublished')
            : t('announcementsSaved')
        );

        if (isSessionTimeAutoSave) {
          getSessionTimeoutModal(isSessionTimeAutoSave);
        }
      }
    } catch (error) {
      setIsLoading(false);
      handleSubmitError(isSessionTimeAutoSave);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleSubmitError = (sessionTimeout: boolean = false) => {
    if (sessionTimeout) {
      getSessionTimeoutModal();
      return;
    }

    setIsLeavingDialogActive(true);
    showModal(
      {
        message: t('errorDialogMessage'),
        title: t('errorDialogTitle'),
        handleAgree: handleModalClose,
      },
      true
    );
  };

  // handling LEAVE modal close
  const handleLeaveConfirm = () => {
    confirmNavigation(async () => {
      const token = await getAccessToken();
      removeAddedImageSrc(token);
    });
  };

  // handling SESSION TIMEOUT modal
  const getSessionTimeoutModal = (autoSave: boolean = false) => {
    setOpenSessionTimeoutModal({
      open: true,
      path: 'announcements',
      autoSave,
      callback: async () => {
        if (!autoSave) {
          const token = await getAccessToken();
          removeAddedImageSrc(token);
        }
      },
    });
  };

  return (
    <>
      <AnnouncementsTop
        dateSaved={dateSaved}
        datePublished={datePublished}
        handleSave={() => handleOpenDialog('save')}
        handlePublish={() => handleOpenDialog('publish')}
      />

      <StyledAnnouncements>
        {!isOnlyEnglish && (
          <StyledToolbar>
            <Toolbar
              tabs={langTabs}
              tabsValue={langTabsValue}
              tabsWidth={200}
              handleChangeTabsValue={handleChangeLangTabsValue}
            />
          </StyledToolbar>
        )}

        {langTabsValue === 0 && (
          <AnnouncementsPanel
            currentAnnouncementId={currentAnnouncementId}
            langTabsValue={langTabsValue}
            tabs={titleTabs}
            tabsValue={titleTabsValue}
            setCurrentAnnouncementId={setCurrentAnnouncementId}
            setTabs={setTitleTabs}
            setTabsValue={setTitleTabsValue}
          />
        )}

        {langTabsValue === 1 && (
          <AnnouncementsPanel
            currentAnnouncementId={currentAnnouncementId}
            langTabsValue={langTabsValue}
            tabs={titleTabs}
            tabsValue={titleTabsValue}
            setCurrentAnnouncementId={setCurrentAnnouncementId}
            setTabs={setTitleTabs}
            setTabsValue={setTitleTabsValue}
          />
        )}
      </StyledAnnouncements>

      <BackdropLoader open={isLoading} />

      <Dialog
        buttonAgree={modalContent.buttonAgree}
        buttonClose={modalContent.buttonClose}
        message={modalContent.message}
        title={modalContent.title}
        open={modalContent.open}
        handleAgree={modalContent.handleAgree}
        handleClose={modalContent.handleClose}
      />

      {/* the user leaving the page */}
      <Dialog
        buttonAgree={t('returnToAnnouncements')}
        buttonClose={t('exitWithoutSaving')}
        message={t('unsavedChangesAnnouncements')}
        title={t('unsavedChanges')}
        open={showPrompt}
        handleAgree={cancelNavigation}
        handleClose={handleLeaveConfirm}
      />
    </>
  );
};

const StyledAnnouncements = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.spacing(5, 6, 6),
}));

const StyledToolbar = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));
