import { useState } from 'react';

import { InputFileError } from '@/admin/types/common';
import {
  urlToObject,
  verifyImageFileSize,
  verifyImageFileType,
} from '@/admin/utils/helpers';

export const useImageUpload = () => {
  const [imageLocalURL, setImageLocalURL] = useState<string>('');
  const [imageURL, setImageURL] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<InputFileError | null>(null);

  const getImageLocalURL = (file: File) => {
    const localURL = URL.createObjectURL(file);
    setImageLocalURL(localURL);
  };

  const getImageFileFromLocalURL = async (url: string) => {
    const imageFile = await urlToObject(url);
    URL.revokeObjectURL(url);

    return imageFile;
  };

  const handleAddImageURL = (url: string) => {
    setImageURL(url);
  };

  const handleDeleteImage = () => {
    setError(null);
    setImageLocalURL('');
    setImageURL('');
  };

  const handleUploadImage = async (callback: Function) => {
    try {
      setIsLoading(true);

      const url = await callback();

      if (url) {
        setIsLoading(false);
        setImageURL(url);
        return url;
      }
    } catch (error) {
      setIsLoading(false);
      setError('uploading');
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const verifyImageFormat = (file: File) => {
    setError(null);

    const isValidFileType = verifyImageFileType(file.name);

    if (!isValidFileType) {
      setError('fileType');
      return false;
    }

    const isValidFileSize = verifyImageFileSize(file.size);

    if (!isValidFileSize) {
      setError('fileSize');
      return false;
    }

    return true;
  };

  const verifySingleImage = (files: FileList) => {
    setError(null);

    if (files.length > 1) {
      setError('multipleFiles');
      return false;
    }

    return true;
  };

  return {
    imageLocalURL,
    imageURL,
    isLoading,
    error,
    getImageFileFromLocalURL,
    getImageLocalURL,
    handleAddImageURL,
    handleDeleteImage,
    handleUploadImage,
    verifyImageFormat,
    verifySingleImage,
  };
};
