import { Search } from '@mui/icons-material';
import { Autocomplete, styled, TextField } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Top } from '@/admin/components';

type BlogsTopProps = {
  blogName: string | null;
  blogsNames: string[];
  handleChange: (e: SyntheticEvent, newValue: string | null) => void;
};

export const BlogsTop = ({
  blogName,
  blogsNames,
  handleChange,
}: BlogsTopProps) => {
  const { t } = useTranslation();

  return (
    <Top link={t('newBlog')} title={t('blogs')}>
      <StyledSearchByTitle>
        <Autocomplete
          aria-label="Search by title"
          fullWidth
          noOptionsText={t('noResultsFound')}
          options={blogsNames}
          popupIcon={<Search />}
          renderInput={(params) => (
            <TextField {...params} placeholder={t('searchByTitle')} />
          )}
          value={blogName}
          onChange={handleChange}
        />
      </StyledSearchByTitle>
    </Top>
  );
};

const StyledSearchByTitle = styled('div')({
  width: '290px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
