import { createContext, useState } from 'react';

import {
  DEFAULT_BLOGS_PAGE,
} from '@/admin/consts/blogs';
import { FC } from '@/admin/types/common';
import {
  removeItemFromSessionStorage,
  setItemToSessionStorage,
} from '@/admin/utils/helpers';
import { IBlogData, IBlogsFilters } from '@/common/types';

interface IBlogsList {
  [page: number]: IBlogData[];
}

interface IBlogsContext {
  blogsList: IBlogsList;
  currentPage: number;
  totalCount: number;
  addBlogs: (blogs: IBlogData[], page: number) => void;
  addTotalCount: (number: number) => void;
  changeCurrentPage: (number: number) => void;
  storeBlogsFilters: (filters: IBlogsFilters) => void;
  clearBlogsList: () => void;
  resetStoredBlogs: () => void;
}

const initialContext: IBlogsContext = {
  blogsList: [],
  currentPage: 1,
  totalCount: 0,
  addBlogs: () => {},
  addTotalCount: () => {},
  changeCurrentPage: () => {},
  storeBlogsFilters: () => {},
  clearBlogsList: () => {},
  resetStoredBlogs: () => {},
};

export const BlogsContext = createContext<IBlogsContext>(initialContext);

export const BlogsProvider = ({ children }: FC) => {
  // list of blogs where a key is a page
  const [blogsList, setBlogsList] = useState<IBlogsList>({});
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_BLOGS_PAGE);
  const [totalCount, setTotalCount] = useState<number>(0);

  const addBlogs = (blogs: IBlogData[], page: number) => {
    const blogsListCopy = { ...blogsList };
    blogsListCopy[page] = blogs;
    setBlogsList(blogsListCopy);
  };

  const addTotalCount = (number: number) => {
    setTotalCount(number);
  };

  const changeCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const storeBlogsFilters = (filters: IBlogsFilters) => {
    setItemToSessionStorage('blogFilters', filters, true);
  };

  const resetStoredBlogs = () => {
    removeItemFromSessionStorage('blogFilters');
  };

  const clearBlogsList = () => {
    setCurrentPage(DEFAULT_BLOGS_PAGE);
    setBlogsList({});
  };

  return (
    <BlogsContext.Provider
      value={{
        blogsList,
        currentPage,
        totalCount,
        addBlogs,
        addTotalCount,
        changeCurrentPage,
        storeBlogsFilters,
        clearBlogsList,
        resetStoredBlogs,
      }}
    >
      {children}
    </BlogsContext.Provider>
  );
};
