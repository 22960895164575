import { REACT_APP_PROXY_API_URL } from '@/admin/config/variables';
import {
  FETCH_BLOG_ACCESS_URL,
  FETCH_DISPLAY_TESTIMONIALS_URL,
  FETCH_EMPLOYEES_ALL_DETAILS,
  FETCH_USER_URL,
} from '@/admin/consts';
import { getNetworkId } from '@/admin/utils/helpers';
import { getHeaders } from '@/admin/utils/helpers-api';
import {
  BlogAccess,
  IDisplayTestimonialsUpdate,
  IEmployees,
  IReviewIntegData,
  SelectedUserInfo,
  UserType,
} from '@/common/types';

export const getUserInfoApi = async (
  token: string,
  selectedUserId?: number,
  selectedUserType?: UserType
) => {
  const userQueries = selectedUserId
    ? `?selectedUserId=${selectedUserId}&selectedUserType=${selectedUserType}`
    : '';
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_USER_URL}${userQueries}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { headers });
  const { data } = (await res.json()) as {
    data: SelectedUserInfo;
  };

  return data;
};

export const updateUserInfoApi = async (
  token: string,
  updatedInfo: IReviewIntegData,
  userType?: UserType
) => {
  const userTypeQuery = `?userType=${userType || 'broker'}`;
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_USER_URL}${userTypeQuery}`;
  const headers = getHeaders(token);

  const res = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(updatedInfo),
  });

  const { status } = await res.json();

  return status;
};

export const updateDisplayTestimonialsApi = async (
  token: string,
  updatedInfo: IDisplayTestimonialsUpdate,
  userType?: UserType
) => {
  const userTypeQuery = `?userType=${userType || 'broker'}`;
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_DISPLAY_TESTIMONIALS_URL}${userTypeQuery}`;
  const headers = getHeaders(token);

  const res = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(updatedInfo),
  });

  const { status } = await res.json();

  return status;
};

export const fetchEmployeesAllDetailsApi = async (token: string) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_EMPLOYEES_ALL_DETAILS}`;
  const headers = getHeaders(token);

  const response = await fetch(url, { headers });
  const { data } = await response.json();

  return data as IEmployees;
};

export const updateBlogAccessApi = async (
  token: string,
  networkId: number,
  { broker, company, office }: BlogAccess
) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_BLOG_ACCESS_URL}`;

  const headers = getHeaders(token);

  const postData = {
    networkId: getNetworkId(networkId),
    broker: !broker,
    company: !company,
    office: !office,
  };

  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(postData),
  });

  const { status } = await response.json();

  return status;
};

export const getBlogAccessApi = async (token: string, networkId: number) => {
  const networkIdQuery = `?networkId=${networkId}`;

  const url = `${REACT_APP_PROXY_API_URL}${FETCH_BLOG_ACCESS_URL}${networkIdQuery}`;

  const headers = getHeaders(token);

  const res = await fetch(url, { headers });

  const { status, data } = await res.json();

  return { status, data };
};
