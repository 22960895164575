import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HomepageSlider } from '@/admin/components';

type HomepageImageControlsProps = {
  isDisabled: boolean;
  rotation: number;
  zoom: number;
  setRotation: (rotation: number) => void;
  setZoom: (zoom: number) => void;
  setIsNoChanges: (isNoChanges: boolean) => void;
};

export const HomepageImageControls = ({
  isDisabled,
  rotation,
  zoom,
  setRotation,
  setZoom,
  setIsNoChanges,
}: HomepageImageControlsProps) => {
  const { t } = useTranslation();

  const onZoomChange = (e: Event, zoom: number) => {
    setZoom(zoom);
    setIsNoChanges(false);
  };

  const onRotationChange = (e: Event, rotation: number) => {
    setRotation(rotation);
    setIsNoChanges(false);
  };

  return (
    <StyledStack direction="row" spacing={9}>
      <HomepageSlider
        ariaLabelledby="Zoom"
        isDisabled={isDisabled}
        max={3}
        min={1}
        step={0.1}
        title={t('zoom')}
        value={zoom}
        onChange={(e, zoom) => onZoomChange(e, zoom as number)}
      />

      <HomepageSlider
        ariaLabelledby="Rotation"
        isDisabled={isDisabled}
        max={360}
        min={0}
        step={1}
        title={t('rotation')}
        value={rotation}
        onChange={(e, rotation) => onRotationChange(e, rotation as number)}
      />
    </StyledStack>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));
