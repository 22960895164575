import {
  URL_MATCHER,
  EmbedMatchResult,
} from '@lexical/react/LexicalAutoEmbedPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { styled } from '@mui/material';
import { useMemo, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, TextField } from '@/admin/components';
import {
  IEmbedConfig,
  videoEmbedConfig,
} from '@/admin/components/blogEditor/editorPlugins';
import { debounce } from '@/admin/utils/helpers-editor';

type VideoInsertModalProps = {
  embedConfig: IEmbedConfig | null;
  open: boolean;
  onClose: () => void;
};

export const VideoInsertModal = ({
  embedConfig,
  open,
  onClose,
}: VideoInsertModalProps) => {
  const { t } = useTranslation();

  const [editor] = useLexicalComposerContext();

  const [URL, setURL] = useState('');
  const [isError, setIsError] = useState(false);
  const [embedResult, setEmbedResult] = useState<EmbedMatchResult | null>(null);

  const validateText = useMemo(
    () =>
      debounce((inputText: string) => {
        const urlMatch = URL_MATCHER.exec(inputText);
        if (embedConfig != null && inputText != null && urlMatch != null) {
          Promise.resolve(embedConfig.parseUrl(inputText)).then(
            (parseResult) => {
              setEmbedResult(parseResult);
              setIsError(false);
            }
          );
        } else if (embedResult != null) {
          setEmbedResult(null);
        }
      }, 200),
    [embedConfig, embedResult]
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setURL(value);
    validateText(value);
  };

  const handleConfirm = () => {
    if (embedResult === null) {
      setIsError(true);
      return;
    }

    videoEmbedConfig.insertNode(editor, embedResult);
    handleClose();
  };

  const handleClose = () => {
    onClose();
    setURL('');
    setIsError(false);
  };

  return (
    <Dialog
      buttonAgree={t('confirm')}
      buttonClose={t('cancel')}
      disableAgreeButton={!URL}
      maxWidth="sm"
      open={open}
      title={t('embedVideoDialogTitle')}
      handleAgree={() => {
        handleConfirm();
      }}
      handleClose={handleClose}
    >
      <StyledTextField>
        <TextField
          caption={t('embedVideoType')}
          error={isError}
          label={t('videoLink')}
          placeholder={videoEmbedConfig.exampleUrl}
          type="url"
          helperText={isError ? t('embedVideoError') : ''}
          onChange={handleChange}
        />
      </StyledTextField>
    </Dialog>
  );
};

const StyledTextField = styled('div')({
  margin: '16px 0',
});
