/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { AdminToolbarContainer, AdminToolbarSearch } from '@/admin/components';
import {
  ANNOUNCEMENTS_PATH,
  DASHBOARD_PATH,
  DEFAULT_USER,
  networksList,
} from '@/admin/consts';
import { AppContext } from '@/admin/providers';
import { IUserTypeOption } from '@/admin/types/common';
import {
  getNetworkId,
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemToLocalStorage,
} from '@/admin/utils/helpers';
import { fetchEmployeesAllDetailsApi } from '@/admin/utils/helpers-api';
import { UserType, IUser } from '@/common/types';

type AdminToolbarTopProps = {
  isBossPolicyPage: boolean;
  fetchSelectedUserInfo: (user: IUser) => void;
};

// M3Tech or Network admin toolbar
export const AdminToolbarTop = ({
  isBossPolicyPage,
  fetchSelectedUserInfo,
}: AdminToolbarTopProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isBlogAccessChanged,
    isBossLogin,
    isCancelNavigation,
    isConfirmNavigation,
    selectedUser,
    userInfo: { networkId, role },
    getAccessToken,
    setIsBlogAccessChanged,
    setIsCancelNavigation,
    setSelectedUser,
    setIsNoUserError,
  } = useContext(AppContext);

  const DEFAULT_USER_TYPE: UserType = 'broker';

  const selectedUserFromLocalStorage = getItemFromLocalStorage(
    isBossLogin ? 'selectedUser-Boss' : 'selectedUser',
    true
  ) as IUser;

  const isBossAnnouncementsPage =
    window.location.href.includes('announcements') && isBossLogin;

  const [brokers, setBrokers] = useState<IUser[]>([]);
  const [companies, setCompanies] = useState<IUser[]>([]);
  const [offices, setOffices] = useState<IUser[]>([]);
  const [displayedUsers, setDisplayedUsers] = useState<IUser[]>([]);
  const [userType, setUserType] = useState<UserType>(
    selectedUserFromLocalStorage?.userType || DEFAULT_USER_TYPE
  );
  const [newSelectedUser, setNewSelectedUser] = useState<IUser>(
    selectedUser || DEFAULT_USER
  );

  const networkAdminPersonal =
    networksList.find(
      (network) => getNetworkId(networkId) === network.networkId
    ) || networksList[0];

  const isNetworkAdmin = role === 'network admin';
  const isOnAnnouncements =
    location && location.pathname === ANNOUNCEMENTS_PATH;

  const defaultUserTypes: IUserTypeOption[] = [
    { label: 'broker', value: 'broker' },
    { label: 'office', value: 'office' },
    { label: 'company', value: 'company' },
    {
      label: 'network',
      value: 'network',
      isAutocompleteHidden: isNetworkAdmin,
    },
  ];

  const userTypes = isBossPolicyPage
    ? defaultUserTypes.filter((type) => type.label !== 'broker')
    : isBossLogin
    ? defaultUserTypes.filter((type) => type.label !== 'network')
    : defaultUserTypes;

  const defaultUsersByType = {
    broker: brokers,
    company: companies,
    office: offices,
    network: networksList,
  } as Record<UserType, IUser[]>;

  const usersByType = isBossPolicyPage
    ? (({ broker: _, ...rest }) => rest)(defaultUsersByType)
    : isBossLogin
    ? (({ network: _, ...rest }) => rest)(defaultUsersByType)
    : defaultUsersByType;

  const getEmployeesAllDetails = async () => {
    try {
      const token = await getAccessToken();
      const data = await fetchEmployeesAllDetailsApi(token);

      const { brokers, companies, offices } = data;

      setBrokers(brokers);
      setCompanies(companies);
      setOffices(offices);
      setIsBlogAccessChanged(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // fetch the users
  useEffect(() => {
    if (isBlogAccessChanged) {
      setTimeout(getEmployeesAllDetails, 500);
    }
  }, [isBlogAccessChanged]);

  useEffect(() => {
    if (selectedUser) fetchSelectedUserInfo(selectedUser);

    if (isNetworkAdmin && isOnAnnouncements) {
      setUserType(networkAdminPersonal.userType);
    } else if (!isOnAnnouncements && userType === 'network') {
      setUserType(DEFAULT_USER_TYPE);
    } else if (isBossPolicyPage) {
      setUserType(userTypes[0].label as UserType);
    }
  }, []);

  // update the displayed users
  useEffect(() => {
    const usersBySelectedType = (usersByType as Record<UserType, IUser[]>)[
      userType
    ];
    const displayUsers = isBossPolicyPage
      ? usersBySelectedType?.filter((user) => user.networkId !== 6)
      : usersBySelectedType;

    setDisplayedUsers(displayUsers);
  }, [brokers, companies, offices, userType]);

  // handle page leaving blocker after select userType
  useEffect(() => {
    const isUserTypeChanged =
      userType !== selectedUserFromLocalStorage?.userType;
    if (isConfirmNavigation && isUserTypeChanged) {
      if (isNetworkAdmin && isOnAnnouncements) {
        setNewSelectedUser(networkAdminPersonal);
      } else {
        setSelectedUser(DEFAULT_USER);
        setNewSelectedUser(DEFAULT_USER);
        removeItemFromLocalStorage(
          isBossLogin ? 'selectedUser-Boss' : 'selectedUser'
        );
      }
    }

    if (isCancelNavigation && isUserTypeChanged) {
      setUserType(selectedUserFromLocalStorage?.userType);
      setIsCancelNavigation(false);
    }
  }, [isCancelNavigation, isConfirmNavigation, userType]);

  // handle page leaving blocker after change user
  useEffect(() => {
    const isUserChanged = selectedUser !== newSelectedUser;

    if (isConfirmNavigation && isUserChanged) {
      setSelectedUser(newSelectedUser);
      setItemToLocalStorage(
        isBossLogin ? 'selectedUser-Boss' : 'selectedUser',
        newSelectedUser,
        true
      );

      if (userType !== 'network') fetchSelectedUserInfo(newSelectedUser);
    }

    if (isCancelNavigation && isUserChanged) {
      setNewSelectedUser(selectedUser);
      setIsCancelNavigation(false);
    }
  }, [isCancelNavigation, isConfirmNavigation, newSelectedUser]);

  const handleUserTypeChange = (type: UserType) => {
    setUserType(type);
    setIsNoUserError(false);

    if (!isBossLogin) {
      navigate(
        type === 'network' && isNetworkAdmin
          ? ANNOUNCEMENTS_PATH
          : DASHBOARD_PATH
      );
    }
  };

  const handleUserChange = (user: IUser) => {
    setNewSelectedUser(user);

    if (!isBossLogin) {
      navigate(
        user.userType === 'network' ? ANNOUNCEMENTS_PATH : DASHBOARD_PATH
      );
    } else if (location.pathname.split('/').length > 2) {
      navigate(location.pathname.split('/')[1]);
    }
  };

  if (isBossAnnouncementsPage && isNetworkAdmin) return <></>;

  return (
    <AdminToolbarContainer>
      <AdminToolbarSearch
        user={
          userType === selectedUserFromLocalStorage?.userType
            ? selectedUser
            : DEFAULT_USER
        }
        userOptions={displayedUsers}
        userType={userType}
        userTypeOptions={userTypes}
        handleUserChange={handleUserChange}
        handleUserTypeChange={handleUserTypeChange}
      />
    </AdminToolbarContainer>
  );
};
