import { useContext } from 'react';

import {
  AdminToolbarAnnouncements,
  AdminToolbarBottom,
  AdminToolbarTop,
} from '@/admin/components';
import { AppContext, DEFAULT_USER_INFO } from '@/admin/providers';
import { getUserInfoApi } from '@/admin/utils/helpers-api';
import { IUser, UserType } from '@/common/types';

export const AdminToolbar = () => {
  const {
    isBossLogin,
    userInfo: { role },
    getAccessToken,
    setIsNoUserError,
    setSelectedUserInfo,
  } = useContext(AppContext);

  const isM3TechAdmin = role === 'm3Tech admin';
  const isNetworkAdmin = role === 'network admin';

  const isBossAnnouncementsPage =
    isBossLogin && window.location.href.includes('announcements');
  const isBossPolicyPage =
    isBossLogin && window.location.href.includes('privacypolicies');

  const fetchSelectedUserInfo = async (user: IUser) => {
    setIsNoUserError(false);
    const { employeeId, franchiseId, officeId, userType } = user;
    const userIdByUserType = {
      broker: employeeId,
      office: officeId,
      company: franchiseId,
    } as Record<UserType, number>;

    const userId = userIdByUserType[userType];

    try {
      const token = await getAccessToken();
      const userInfo = await getUserInfoApi(token, userId, userType);
      if (!userInfo) {
        setSelectedUserInfo(DEFAULT_USER_INFO);
        setIsNoUserError(true);
      } else {
        setSelectedUserInfo(userInfo);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  if (isBossAnnouncementsPage && isM3TechAdmin)
    return <AdminToolbarAnnouncements />;

  if (isM3TechAdmin || isNetworkAdmin)
    return (
      <AdminToolbarTop
        isBossPolicyPage={isBossPolicyPage}
        fetchSelectedUserInfo={fetchSelectedUserInfo}
      />
    );

  return (
    <AdminToolbarBottom
      isBossPolicyPage={isBossPolicyPage}
      fetchSelectedUserInfo={fetchSelectedUserInfo}
    />
  );
};
