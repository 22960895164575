import { Settings } from '@/admin/components';
import { SettingsFormProvider } from '@/admin/providers/form/SettingsFormProvider';

export const SettingsPage = () => {
  return (
    <SettingsFormProvider>
      <Settings />
    </SettingsFormProvider>
  );
};
