import { Typography, styled } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateValidationError } from '@mui/x-date-pickers/models';
import { Dayjs } from 'dayjs';

type DatePickerProps = {
  label: string;
  value: Dayjs | null;
  onChange: (newValue: unknown) => void;
  disabled?: boolean;
  disablePast?: boolean;
  minDate?: (Dayjs | null);
  onError?: ((error: DateValidationError, value: unknown) => void) | undefined;
  errorMessage: string;
};

export const DatePicker = ({
  disabled,
  disablePast,
  label,
  value,
  onChange,
  minDate,
  onError,
  errorMessage
}: DatePickerProps) => (
  <div>
    <StyledTypography className={disabled ? 'disabled' : ''} variant="caption">
      {`${label}:`}
    </StyledTypography>

    <StyledDatePicker
      disabled={disabled}
      disablePast={disablePast}
      minDate={minDate}
      onChange={onChange}
      onError={onError}
      slotProps={{ 
        textField: {
          size: 'small',
          helperText: errorMessage,
        }
      }}
      value={value}
    />
  </div>
);

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(0.5),

  '&.disabled': { color: theme.palette.text.secondary },
}));

const StyledDatePicker = styled(MuiDatePicker)({
  width: '100%',

  '& .MuiButtonBase-root': { marginRight: 0 },
});
