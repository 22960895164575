import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Stack, styled } from '@mui/material';

type TestimonialFacebookRatingProps = {
  isLiked: boolean;
};

export const TestimonialFacebookRating = ({
  isLiked,
}: TestimonialFacebookRatingProps) => (
  <Stack direction="row" spacing={1}>
    <StyledIcon className={isLiked ? 'active' : ''}>
      <ThumbUpIcon color="inherit" />
    </StyledIcon>

    <StyledIcon className={isLiked ? '' : 'active'}>
      <ThumbDownAltIcon color="inherit" />{' '}
    </StyledIcon>
  </Stack>
);

const StyledIcon = styled('div')(({ theme }) => ({
  color: theme.palette.grey[400],

  '&.active': {
    color: theme.palette.common.black,
  },
}));
