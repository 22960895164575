import { Button, styled } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Toolbar } from '@/admin/components';
import { ACCEPTED_IMAGE_TYPES } from '@/admin/consts';
import { ImageState } from '@/admin/types/common';

type HomepageToolbarProps = {
  imageState: ImageState;
  isChangeImageButtonVisible: boolean;
  isPublishButtonDisabled: boolean;
  handleChangeImage: (files: FileList) => void;
  handlePublish: () => void;
  handleEditImage: () => void;
};

export const HomepageToolbar = ({
  imageState,
  isChangeImageButtonVisible,
  isPublishButtonDisabled,
  handleChangeImage,
  handleEditImage,
  handlePublish,
}: HomepageToolbarProps) => {
  const { t } = useTranslation();

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) handleChangeImage(files);
  };

  return (
    <StyledToolbar>
      <Toolbar
        isButtonDisabled={isPublishButtonDisabled}
        button={imageState !== 'previewImage' ? t('publish') : ''}
        title={t('headerImage')}
        handleButtonClick={handlePublish}
      >
        {imageState === 'previewImage' && (
          <Button
            color="secondary"
            component="label"
            variant="outlined"
            onClick={handleEditImage}
          >
            {t('edit')}
          </Button>
        )}

        {isChangeImageButtonVisible && (
          <Button color="secondary" component="label" variant="outlined">
            {t('changeImage')}
            <input
              hidden
              accept={ACCEPTED_IMAGE_TYPES}
              type="file"
              onChange={handleImageChange}
            />
          </Button>
        )}
      </Toolbar>
    </StyledToolbar>
  );
};

const StyledToolbar = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));
