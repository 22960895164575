import type { EditorThemeClasses } from 'lexical';

import './index.css';

export const editorTheme: EditorThemeClasses = {
  embedBlock: {
    base: 'embedBlock',
    focus: 'embedBlockFocus',
  },
  heading: {
    h1: 'editor_h1',
    h2: 'editor_h2',
    h3: 'editor_h3',
  },
  image: 'editor_image',
  link: 'editor_link',
  list: {
    listitem: 'editor_listItem',
    nested: {
      listitem: 'editor_nestedListItem',
    },
    olDepth: [
      'editor_ol1',
      'editor_ol2',
      'editor_ol3',
      'editor_ol4',
      'editor_ol5',
    ],
    ulDepth: ['editor_ul1', 'editor_ul2'],
  },
  ltr: 'editor_ltr',
  paragraph: 'editor_paragraph',
  table: 'editor_table',
  tableAddColumns: 'editor_tableAddColumns',
  tableAddRows: 'editor_tableAddRows',
  tableCell: 'editor_tableCell',
  tableCellActionButton: 'editor_tableCellActionButton',
  tableCellActionButtonContainer: 'editor_tableCellActionButtonContainer',
  tableCellEditing: 'editor_tableCellEditing',
  tableCellHeader: 'editor_tableCellHeader',
  tableCellPrimarySelected: 'editor_tableCellPrimarySelected',
  tableCellResizer: 'editor_tableCellResizer',
  tableCellSelected: 'editor_tableCellSelected',
  tableCellSortedIndicator: 'editor_tableCellSortedIndicator',
  tableResizeRuler: 'editor_tableCellResizeRuler',
  tableSelected: 'editor_tableSelected',
  text: {
    bold: 'editor_textBold',
    italic: 'editor_textItalic',
    underline: 'editor_textUnderline',
  },
};
