import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { IconButton, styled, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '@/admin/providers';
import { getLocalizedValue } from '@/admin/utils/helpers';

type NavHeaderProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const NavHeader = ({ open, setOpen }: NavHeaderProps) => {
  const {
    i18n: { language },
  } = useTranslation();
  const {
    isAdmin,
    displayedUserInfo: { name: displayedUserName, nameFr: displayedUserNameFr },
    selectedUser: { name: selectedUserName, nameFr: selectedUserNameFr },
  } = useContext(AppContext);

  const name = isAdmin
    ? getLocalizedValue(language, selectedUserName, selectedUserNameFr)
    : getLocalizedValue(language, displayedUserName, displayedUserNameFr);

  const userInitials = name
    .trim()
    .replace(/-/g, ' ')
    .replace(/[^a-z0-9 -]/gi, '')
    .split(' ')
    .filter((word) => word)
    .map((word) => `${word[0]}.`)
    .join(' ');

  const handleToggleOpen = () => {
    setOpen(!open);
  };

  return (
    <StyledNavHeader className={!open ? 'closed' : ''}>
      <StyledTypography variant="caption">
        {open ? name : userInitials}
      </StyledTypography>

      <StyledIconButton size="small" onClick={handleToggleOpen}>
        {open ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
      </StyledIconButton>
    </StyledNavHeader>
  );
};

const StyledNavHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '43px',
  padding: theme.spacing(0.5, 0.5, 0.5, 5),

  '&.closed': {
    paddingLeft: theme.spacing(2),
  },

  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(2),
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  whiteSpace: 'pre-wrap',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  transform: 'rotate(45deg)',

  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));
