import { Grid, styled, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SettingsAccountDetails,
  SettingsIntegrations,
  SettingsPermissions,
  SettingsSkeleton,
} from '@/admin/components';
import { AppContext } from '@/admin/providers';

export const Settings = () => {
  const { t } = useTranslation();
  const {
    selectedUser: { userType },
    userInfo: { name, role },
  } = useContext(AppContext);

  const isSettingsPermissionsVisible =
    (role === 'network admin' || role === 'm3Tech admin') && userType === 'network';

  if (!name) return <SettingsSkeleton />;

  return (
    <StyledSettings>
      <StyledTypography variant="h1">{t('settings')}</StyledTypography>

      <Grid container spacing={3}>
        <SettingsAccountDetails />

        {isSettingsPermissionsVisible ? (
          <SettingsPermissions />
        ) : (
          <SettingsIntegrations />
        )}
      </Grid>
    </StyledSettings>
  );
};

const StyledSettings = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 8, 5, 6),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}));
