import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AdminToolbarContainer, AdminToolbarSearch } from '@/admin/components';
import { ANNOUNCEMENTS_PATH, DEFAULT_USER, networksList } from '@/admin/consts';
import { AppContext } from '@/admin/providers';
import {
  setItemToLocalStorage,
  removeItemFromLocalStorage,
} from '@/admin/utils/helpers';
import { IUser } from '@/common/types';

export const AdminToolbarAnnouncements = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedUser, setSelectedUser } = useContext(AppContext);

  useEffect(() => {
    if (selectedUser.userType !== 'network') {
      setSelectedUser(DEFAULT_USER);
      removeItemFromLocalStorage('selectedUser-Boss');
    }
  }, []);

  const handleUserChange = (user: IUser) => {
    setSelectedUser(user);
    setItemToLocalStorage('selectedUser-Boss', user, true);
    navigate(ANNOUNCEMENTS_PATH);
  };

  return (
    <AdminToolbarContainer>
      <AdminToolbarSearch
        title={t('network')}
        user={selectedUser}
        userOptions={networksList}
        userType="network"
        handleUserChange={handleUserChange}
      />
    </AdminToolbarContainer>
  );
};
