import { Stack, styled } from '@mui/material';

import { Skeleton } from '@/admin/components';

export const BlogHeaderSkeleton = () => (
  <StyledBlogHeaderSkeleton>
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={6}
    >
      <Skeleton width={125} height={56} />
      <Stack direction="row" justifyContent="space-between">
        <Skeleton width={162} height={40} />
      </Stack>
    </Stack>
  </StyledBlogHeaderSkeleton>
);

export const BlogFiltersSkeleton = () => (
  <StyledFilters>
    <StyledRow>
      <Skeleton width="100%" height={59} />
      <Skeleton width={200} height={59} />
    </StyledRow>

    <StyledRowEnd>
      <Skeleton width={191} height={48} />
    </StyledRowEnd>
  </StyledFilters>
);

export const BlogsSkeleton = () => <Skeleton height={700} />;

export const StyledBlogHeaderSkeleton = styled('div')(({ theme }) => ({
  flexShrink: 0,
  padding: theme.spacing(5, 8, 4, 6),
}));

const StyledFilters = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledRow = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 1,
});

const StyledRowEnd = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 1,
  alignItems: 'center',
  justifyContent: 'flex-end',
});
