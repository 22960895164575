import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { HeadingNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { styled } from '@mui/material';
import {
  $getRoot,
  EditorState,
  LexicalEditor,
  ParagraphNode,
  TextNode,
} from 'lexical';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  CustomHeadingNode,
  CustomListItemNode,
  CustomParagraphNode,
  CustomTextNode,
  ImageNode,
  VideoNode,
} from '@/admin/components/blogEditor/editorNodes';
import {
  AutoEmbedPlugin,
  ClickableLinkPlugin,
  DragDropPastePlugin,
  FloatingLinkEditorPlugin,
  ImagePlugin,
  ListMaxIndentLevelPlugin,
  OnLoadPlugin,
  TableCellActionMenuPlugin,
  TableCellResizerPlugin,
  ToolbarPlugin,
  // TreeViewPlugin,
  VideoPlugin,
} from '@/admin/components/blogEditor/editorPlugins';
import { editorTheme } from '@/admin/components/blogEditor/editorTheme';
import { DESCRIPTION_LENGTH } from '@/admin/consts/blogs';
import { MATCHERS } from '@/admin/consts/editor';
import { getShortenedText, parseDomFromString } from '@/admin/utils/helpers';

export const BlogEditor = () => {
  const {
    formState: { errors },
    clearErrors,
    getValues,
    setValue,
  } = useFormContext();

  const initialConfig = {
    namespace: 'Editor',
    nodes: [
      AutoLinkNode,
      CustomHeadingNode,
      CustomListItemNode,
      CustomParagraphNode,
      CustomTextNode,
      ImageNode,
      LinkNode,
      ListNode,
      TableCellNode,
      TableNode,
      TableRowNode,
      VideoNode,
      {
        replace: HeadingNode,
        with: (node: HeadingNode) => {
          return new CustomHeadingNode(node.__tag, node.__key);
        },
      },
      {
        replace: ListItemNode,
        with: (node: ListItemNode) => {
          return new CustomListItemNode(
            node.__value,
            node.__checked,
            node.__key
          );
        },
      },
      {
        replace: ParagraphNode,
        with: (node: ParagraphNode) => {
          return new CustomParagraphNode(node.__key);
        },
      },
      {
        replace: TextNode,
        with: (node: TextNode) => {
          return new CustomTextNode(node.__text, node.__key);
        },
      },
    ],
    onError: (error: Error) => {
      throw error;
    },
    theme: editorTheme,
    editorState: (editor: LexicalEditor) => {
      const dom = parseDomFromString(getValues('blogContent'));
      const nodes = $generateNodesFromDOM(editor, dom);
      const rootNode = $getRoot();
      rootNode.append(...nodes);
    },
  };

  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const handleChange = (editorState: EditorState, editor: LexicalEditor) => {
    const isSEODefault = getValues('isSEODefault');

    editor.update(() => {
      // set blogContent form value
      const htmlString = $generateHtmlFromNodes(editor);

      setValue('blogContent', htmlString, { shouldDirty: true });

      // clear required error manually when contents exist
      if (errors.blogContent && htmlString) {
        clearErrors('blogContent');
      }

      // set description form value
      if (isSEODefault) {
        const paragraphText: string[] = [];

        editorState._nodeMap.forEach((node) => {
          const parent = node.getParent();
          if (
            node.__type === 'text' &&
            parent?.__type === 'paragraph' &&
            parent?.__parent === 'root'
          ) {
            paragraphText.push(node.getTextContent());
          }
        });

        const description = getShortenedText(
          paragraphText.join(' '),
          DESCRIPTION_LENGTH,
          true
        );

        setValue('description', description, { shouldDirty: true });
      }
    });
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <StyledContainer>
        <ToolbarPlugin className={errors.blogContent ? 'error' : ''} />

        <StyledEditorContainer className={errors.blogContent ? 'error' : ''}>
          <RichTextPlugin
            contentEditable={
              <StyledEditorScroller>
                <StyledEditor ref={onRef}>
                  <ContentEditable className="contentEditable" />
                </StyledEditor>
              </StyledEditorScroller>
            }
            placeholder={null}
            ErrorBoundary={LexicalErrorBoundary}
          />

          <AutoFocusPlugin />
          <OnLoadPlugin />
          <OnChangePlugin onChange={handleChange} />

          <HistoryPlugin />

          <LinkPlugin />
          <AutoLinkPlugin matchers={MATCHERS} />
          <ClickableLinkPlugin />

          <ListPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />

          <ImagePlugin />
          <DragDropPastePlugin />

          <AutoEmbedPlugin />
          <VideoPlugin />

          <TablePlugin />

          {floatingAnchorElem && (
            <>
              <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
              <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
              <TableCellResizerPlugin anchorElem={floatingAnchorElem} />
            </>
          )}
        </StyledEditorContainer>
      </StyledContainer>

      {/* Uncomment when debug text editor */}
      {/* <TreeViewPlugin /> */}
    </LexicalComposer>
  );
};

const StyledContainer = styled('div')({
  minWidth: '750px',
  position: 'relative',
  lineHeight: '1.7',
  fontWeight: '400',
});

const StyledEditorContainer = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  position: 'relative',
  cursor: 'text',
  display: 'block',
  border: '1px solid rgba(0, 0, 0, 0.26)',
  borderTopStyle: 'none',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',

  '&.error': {
    border: `1px solid ${theme.palette.error.main}`,
    borderTopStyle: 'none',
  },
}));

const StyledEditorScroller = styled('div')({
  height: '500px',
  resize: 'none',
  cursor: 'text',
  display: 'block',
  position: 'relative',
  outline: 0,
  overflow: 'hidden auto',
});

const StyledEditor = styled('div')({
  height: '100%',
  position: 'relative',

  '& .contentEditable': {
    border: 0,
    fontSize: '15px',
    display: 'block',
    position: 'relative',
    tabSize: 1,
    outline: 0,
    padding: '8px 28px',
    minHeight: 'calc(100% - 16px)',
  },
});
