import LinkIcon from '@mui/icons-material/Link';
import { Button, styled, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AppContext, ThemeContext } from '@/admin/providers';
import { getSlugText, getSlugURL } from '@/admin/utils/helpers';
import { IBlogData } from '@/common/types';

export const BlogContentHeaderSlug = () => {
  const { t } = useTranslation();

  const {
    displayedUserInfo: { networkId, siteName, siteNameFr, userType },
  } = useContext(AppContext);
  const { openSnackbar } = useContext(ThemeContext);

  const { watch, getValues, getFieldState, formState } =
    useFormContext<IBlogData>();

  const [showCopyButton, setShowCopyButton] = useState(false);

  const blogName = watch('blogName');
  const language = watch('language');

  const slug = watch('slug');
  const slugUrl = getSlugURL(
    language,
    networkId,
    userType,
    siteName,
    siteNameFr
  );
  const url = `${slugUrl}${slug || getSlugText(t('untitledBlog'))}`;

  useEffect(() => {
    if (
      getValues('status') !== 'draft' &&
      !getFieldState('slug', formState).isDirty
    ) {
      setShowCopyButton(true);
      return;
    }
    setShowCopyButton(false);
  }, [blogName]);

  const onButtonClick = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(url);
        openSnackbar(t('blogLinkCopiedToClipboard'));
        return;
      } catch (err) {
        console.error(err);
        openSnackbar(t('blogLinkCopiedToClipboardFailed'));
      }
    }

    // Fallback to execCommand for BOSS CMP
    const textarea = document.createElement('textarea');
    textarea.value = url;
    document.body.appendChild(textarea);
    textarea.select();

    try {
      //@ts-ignore
      document.execCommand('copy');
      openSnackbar(t('blogLinkCopiedToClipboard'));
    } catch (err) {
      console.error(err);
      openSnackbar(t('blogLinkCopiedToClipboardFailed'));
    } finally {
      document.body.removeChild(textarea);
    }
  };

  return (
    <StyledSlug>
      <StyledSlugContent>
        <StyledTypography variant="subtitle1">{url}</StyledTypography>

        {showCopyButton && (
          <StyledButton startIcon={<LinkIcon />} onClick={onButtonClick}>
            {t('copy')}
          </StyledButton>
        )}
      </StyledSlugContent>
    </StyledSlug>
  );
};

const StyledSlug = styled('div')({
  position: 'relative',
});

const StyledSlugContent = styled('div')(({ theme }) => ({
  columnGap: theme.spacing(1),
  display: 'grid',
  gridTemplateColumns: '1fr 85px',
  overflow: 'hidden',
  width: 'fit-content',

  // blur at the end of the slug if the slug is bigger than the screen size
  '&::after': {
    background: `linear-gradient(274.24deg, ${theme.palette.grey[100]} 3.13%, rgba(249, 249, 249, 0) 88.79%)`,
    bottom: 0,
    content: '""',
    position: 'absolute',
    right: '93px',
    top: 0,
    width: '24px',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  position: 'relative',
  zIndex: 2,
}));
