import { REACT_APP_PROXY_API_URL } from '@/admin/config/variables';
import {
  FETCH_GOOGLE_REVIEWS_DATA_API,
  FETCH_TESTIMONIAL_SUMMARY_URL,
  FETCH_TESTIMONIAL_URL,
} from '@/admin/consts';
import { getFiltersQuery, getHeaders } from '@/admin/utils/helpers-api';
import {
  IGoogleBusinessData,
  ITestimonialsFilters,
  UserType,
} from '@/common/types';

export const getTestimonialsApi = async (
  { filters, token }: { filters: ITestimonialsFilters; token: string },
  signal?: AbortSignal
) => {
  const queries = filters ? getFiltersQuery(filters) : '';
  const urlQueries = queries ? `?${queries.substring(1)}` : '';

  const url = `${REACT_APP_PROXY_API_URL}${FETCH_TESTIMONIAL_URL}${urlQueries}`;
  const headers = getHeaders(token);

  const response = await fetch(url, { signal, headers });

  const { data } = await response.json();
  return data;
};

export const updateTestimonialStatus = async (
  toPublish: boolean,
  id: string,
  token: string
) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_TESTIMONIAL_URL}`;
  const body = {
    id,
    status: toPublish ? 'published' : ('unpublished' as string),
  };

  const headers = getHeaders(token);

  const res = await fetch(url, {
    method: 'PATCH',
    headers,
    body: JSON.stringify(body),
  });
  const { status } = await res.json();
  return status;
};

export const getSummaryApi = async (
  token: string,
  userId?: number,
  userType?: UserType
) => {
  const userIdQuery = userId
    ? `?userId=${userId}&userType=${userType || 'broker'}`
    : '';
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_TESTIMONIAL_SUMMARY_URL}${userIdQuery}`;
  const headers = getHeaders(token);

  const response = await fetch(url, { headers });

  const { data } = await response.json();
  return data;
};

export const storeSelectedBusiness = async (
  token: string,
  businessData: IGoogleBusinessData[],
  userId: number,
  userType: UserType
) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_GOOGLE_REVIEWS_DATA_API}/locations/location`;
  const headers = getHeaders(token);
  const body = {
    userId,
    userType,
    businessData,
  };

  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });

  const { data, status } = await response.json();
  return { data, status };
};

export const checkIfRefreshTokenValid = async (
  token: string,
  userId?: number,
  userType?: UserType
) => {
  const userIdQuery = userId
    ? `?userId=${userId}&userType=${userType || 'broker'}`
    : '';

  const url = `${REACT_APP_PROXY_API_URL}${FETCH_GOOGLE_REVIEWS_DATA_API}/auth${userIdQuery}`;
  const headers = getHeaders(token);

  const response = await fetch(url, { headers });

  const { status } = await response.json();
  return { status };
};

export const getSelectedBusinessesApi = async (
  token: string,
  userId: number,
  userType: UserType
) => {
  const userIdQuery = userId
    ? `?userId=${userId}&userType=${userType || 'broker'}`
    : '';

  const url = `${REACT_APP_PROXY_API_URL}${FETCH_GOOGLE_REVIEWS_DATA_API}/locations/location${userIdQuery}`;
  const headers = getHeaders(token);

  const response = await fetch(url, { headers });

  const { data } = await response.json();
  return data;
};

export const getGoogleBusinessesApi = async (
  token: string,
  userId: number,
  userType: UserType
) => {
  const userIdQuery = userId
    ? `?userId=${userId}&userType=${userType || 'broker'}`
    : '';

  const url = `${REACT_APP_PROXY_API_URL}${FETCH_GOOGLE_REVIEWS_DATA_API}/locations${userIdQuery}`;

  const headers = getHeaders(token);

  const response = await fetch(url, { headers });

  // return empty array when reaching a 204 (no data), destructuring data from response causes error.
  if (response.status === 204) {
    return { data: [], status: 204 };
  }

  const { data, status } = await response.json();
  return { data, status };
};

export const useGoogleLoginApi = async (
  code: string,
  userId: number,
  userType: UserType
) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_GOOGLE_REVIEWS_DATA_API}/auth`;
  const body = {
    code,
    userId,
    userType,
  };

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const { data, status } = await response.json();
  return { data, status };
};
