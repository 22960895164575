import { Button, Stack, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@/admin/components';
import { ADD_PATH } from '@/admin/consts/paths';

type TopProps = {
  title: string;
  children?: ReactNode;
  link?: string;
};

export const Top = ({ children, link, title }: TopProps) => (
  <StyledStack spacing={6}>
    <Typography variant="h1">{title}</Typography>

    {(link || children) && (
      <StyledInnerStack direction="row" alignItems="center" spacing={3}>
        {children}

        {link && (
          <Link to={ADD_PATH}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<Icon symbol="add" size="md" />}
            >
              {link}
            </Button>
          </Link>
        )}
      </StyledInnerStack>
    )}
  </StyledStack>
);

const StyledStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(5, 8, 0, 6),
}));

const StyledInnerStack = styled(Stack)({
  margin: '0 !important',
});
