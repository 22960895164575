import { styled } from '@mui/material';
import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type LinkProps = {
  children: ReactNode;
  to: string;
  onClick?: () => void;
};

export const Link = ({ children, to, onClick }: LinkProps) => (
  <StyledLink to={to} onClick={onClick}>
    {children}
  </StyledLink>
);

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  transition: 'font-weight, 0.3s',

  '&:hover': {
    fontWeight: 700,
  },
}));
