import { Stack, styled } from '@mui/material';

import { Skeleton } from '@/admin/components/skeleton';

export const SettingsSkeleton = () => {
  return (
    <StyledDashboard>
      <Skeleton width={201} height={56} />

      <Stack direction="row">
        <StyledColumn>
          <Skeleton height={400} />
        </StyledColumn>
        <StyledColumn>
          <Skeleton height={400} />
        </StyledColumn>
        <StyledColumn>
          <Skeleton height={250} />
        </StyledColumn>
      </Stack>
    </StyledDashboard>
  );
};

const StyledDashboard = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 5),
}));

const StyledColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});
