import { styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/admin/components';
import { img_url } from '@/admin/utils/helpers';

type AppPlaceHolderProps = {
  label: string,
  message: string,
  icon: string
}

export const AppPlaceholder = ({
  label,
  message,
  icon
}: AppPlaceHolderProps) => {
  const { t } = useTranslation();

  return (
    <StyledAppPlaceholder>
      <Icon symbol={icon} size="xxlg" wght={500} />
      <StyledTitle variant="h1">{t(label)}</StyledTitle>
      <StyledMessage>{t(message)}</StyledMessage>
    </StyledAppPlaceholder>
  );
};

const StyledAppPlaceholder = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundImage: `url("${img_url}/images/placeholder.jpg")`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  color: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'center',
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const StyledMessage = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginTop: theme.spacing(2),
}));
