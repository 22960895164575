import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { createContext, useState } from 'react';

import { Snackbar } from '@/admin/components';
import { theme } from '@/admin/providers/theme/theme';
import { FC } from '@/admin/types/common';

interface IThemeContext {
  openSnackbar: (message: string) => void;
}

const initialContext: IThemeContext = {
  openSnackbar: () => {},
};

export const ThemeContext = createContext<IThemeContext>(initialContext);

export const ThemeProvider = ({ children }: FC) => {
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

  const closeSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  const openSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setIsSnackbarOpen(true);
  };

  return (
    <ThemeContext.Provider value={{ openSnackbar }}>
      <MuiThemeProvider theme={theme}>
        {children}

        <Snackbar
          message={snackbarMessage}
          open={isSnackbarOpen}
          handleClose={closeSnackbar}
        />
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
