import { alpha, IconButton as MuiIconButton, styled } from '@mui/material';
import { ReactNode } from 'react';

type IconButtonProp = {
  children: ReactNode;
  onClick: () => void;
  color?: 'primary' | 'secondary';
  isDisabled?: boolean;
};

export const IconButton = ({
  children,
  color,
  isDisabled,
  onClick,
}: IconButtonProp) => (
  <StyledIconButton
    className={color ? color : 'primary'}
    disabled={isDisabled}
    onClick={onClick}
  >
    {children}
  </StyledIconButton>
);

const StyledIconButton = styled(MuiIconButton)(({ theme }) => ({
  border: '1px solid',
  borderRadius: '4px',
  boxSizing: 'content-box',
  transition: 'all 0.3s',

  '&.primary': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.lighter,
    },

    '&:disabled': {
      borderColor: theme.palette.grey[400],
      color: theme.palette.grey[400],
    },
  },

  '&.secondary': {
    backgroundColor: alpha(theme.palette.secondary.main as string, 0.5),
    borderColor: theme.palette.common.white,
    boxShadow: `0px 4px 4px ${theme.palette.shadow?.dark}`,
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main as string, 0.75),
    },
  },
}));
