import { styled } from '@mui/material';
import { ChangeEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';

import { Switch } from '@/admin/components';

type BlogContentHeaderVisibilityProps = {
  handleVisibilityChange: ChangeEventHandler;
};

export const BlogContentHeaderVisibility = ({
  handleVisibilityChange,
}: BlogContentHeaderVisibilityProps) => {
  const { watch } = useFormContext();

  const hidden = watch('status') === 'hidden';

  return (
    <StyledSwitch>
      <Switch
        ariaLabel="Visibility"
        checked={hidden}
        hasIcon
        label={'visibility'}
        textChecked="hidden"
        textDefault="public"
        onChange={handleVisibilityChange}
      />
    </StyledSwitch>
  );
};

const StyledSwitch = styled('div')({
  width: '100px',
});
