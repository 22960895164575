import { IAnnouncementsData, Lang } from '@/common/types';

export const defaultAnnouncements: IAnnouncementsData = {
  announcements: [],
  datePublished: null,
  dateSaved: null,
  id: ''
};

export const langIdByLang: Record<Lang, number> = { fr: 1, en: 0 };
