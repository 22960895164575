import { IUser, IBlogsFilters, BlogAccess } from '@/common/types';

export const BLOGS_PER_PAGE = 10;

export const DEFAULT_USER: IUser = {
  name: '',
  nameFr: '',
  networkId: 0,
  originalNetworkId: 0,
  userType: 'broker',
};

export const DEFAULT_BLOGS_FILTERS: IBlogsFilters = {
  ...DEFAULT_USER,
  blogName: '',
  language: '',
  status: '',
  userId: 0
};

export const DEFAULT_BLOG_ACCESS: BlogAccess = {
  broker: true,
  office: true,
  company: true,
};

export const DEFAULT_BLOGS_PAGE = 1;

export const DESCRIPTION_LENGTH = 160;

export const IMAGE_FILE_TYPE = ['.jpeg', '.jpg', '.png'];

export const SNACKBAR_TITLE_MAX_LENGTH = 35;

export const TITLE_LENGTH = 60;
