import { Button, styled } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Error, Icon } from '@/admin/components';
import { AppContext } from '@/admin/providers';

export const AppError = () => {
  const { t } = useTranslation();
  const { handleLogout, isBossLogin } = useContext(AppContext);

  return (
    <StyledContainer>
      <Error
        title={t('errorOccurredTitle')}
        message={
          !isBossLogin
            ? t('errorOccurredMessage')
            : t('errorOccurredRefreshMessage')
        }
      />
      {!isBossLogin && (
        <StyledButtonContainer>
          <StyledButton
            startIcon={<Icon size="md" symbol="refresh" />}
            variant="contained"
            onClick={() => window.location.reload()}
          >
            {t('refresh')}
          </StyledButton>
          <StyledButton
            startIcon={<Icon size="md" symbol="logout" />}
            variant="outlined"
            onClick={handleLogout}
          >
            {t('logout')}
          </StyledButton>
        </StyledButtonContainer>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled('div')({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledButtonContainer = styled('div')({
  display: 'flex',
});

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, 2),
}));
