import { Stack, styled } from '@mui/material';

import { Skeleton } from '@/admin/components';

export const TestimonialHeaderSkeleton = () => (
  <StyledTestimonialHeaderSkeleton>
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={6}
    >
      <Skeleton width={243} height={56} />
    </Stack>
  </StyledTestimonialHeaderSkeleton>
);

export const TestimonialFiltersSkeleton = () => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    spacing={6}
  >
    <Stack width={'100%'} direction="row" justifyContent="space-between">
      <Skeleton width="50%" height={59} />
      <Skeleton width="50%" height={59} />
    </Stack>
    <Stack
      width="50%"
      direction="row"
      alignItems="flex-end"
      justifyContent="space-between"
    >
      <Skeleton width="100%" height={48} />
    </Stack>
  </Stack>
);

export const TestimonialsSkeleton = () => (
  <>
    <Skeleton height={230} />
    <Skeleton height={230} />
    <Skeleton height={230} />
  </>
);

export const StyledTestimonialHeaderSkeleton = styled('div')(({ theme }) => ({
  flexShrink: 0,
  padding: theme.spacing(5, 8, 4, 6),
}));
