import { addClassNamesToElement } from '@lexical/utils';
import {
  TextNode,
  $applyNodeReplacement,
  NodeKey,
  EditorConfig,
  LexicalNode,
  SerializedTextNode,
} from 'lexical';

export class MergeTagNode extends TextNode {
  constructor(text: string, key?: NodeKey) {
    super(text, key);
  }

  static getType(): string {
    return 'merge-tag';
  }

  static clone(node: MergeTagNode): MergeTagNode {
    return new MergeTagNode(node.__text, node.__key);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    addClassNamesToElement(element, config.theme.mergeTag);
    return element;
  }

  static importJSON(serializedNode: SerializedTextNode): MergeTagNode {
    const node = $createMergeTagNode(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON(): SerializedTextNode {
    return { ...super.exportJSON(), type: 'merge-tag' };
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  isTextEntity(): boolean {
    return true;
  }
}

export const $createMergeTagNode = (text = ''): MergeTagNode => {
  return $applyNodeReplacement(new MergeTagNode(text));
};

export const $isMergeTagNode = (
  node: LexicalNode | null | undefined
): node is MergeTagNode => {
  return node instanceof MergeTagNode;
};
