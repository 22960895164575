import { Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import { BlogPost, EditBlogsSkeleton, Error } from '@/admin/components';
import {
  AppContext,
  BlogFormProvider,
  defaultBlogValues,
  StorageBlobCleanProvider,
} from '@/admin/providers';
import { getBlogByIdApi } from '@/admin/utils/helpers-api';

export const EditBlogPage = () => {
  const { blogId } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { selectedUserId, getAccessToken } = useContext(AppContext);

  const [defaultValues, setDefaultValues] = useState(defaultBlogValues);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getBlogDataById = async (blogId: string) => {
    try {
      const token = await getAccessToken();
      const { status, data } = await getBlogByIdApi(
        token,
        blogId,
        selectedUserId
      );

      if (status === 200) {
        setDefaultValues(data[0]);
      } else {
        setIsError(true);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    if (blogId) {
      getBlogDataById(blogId);
    }
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  if (isLoading) return <EditBlogsSkeleton />;

  if (isError)
    return (
      <Error title={t('errorOccurredTitle')}>
        <Typography variant="body1">
          {`${t('errorOccurredRefreshMessage')} ${t('or')} `}

          <span onClick={handleGoBack}>{t('errorOccurredBackMessage')}</span>
        </Typography>
      </Error>
    );

  return (
    <BlogFormProvider defaultValues={defaultValues}>
      <StorageBlobCleanProvider>
        <BlogPost isNewBlog={false} getBlogDataById={getBlogDataById} />
      </StorageBlobCleanProvider>
    </BlogFormProvider>
  );
};
