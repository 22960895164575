import { Grid, Paper, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@/admin/components';

type DashboardSectionProps = {
  children: ReactNode;
  title: string;
  link?: string;
  onLinkClick?: () => void;
};

export const DashboardSection = ({
  children,
  link,
  title,
  onLinkClick,
}: DashboardSectionProps) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={6}>
      <StyledCard variant="outlined">
        <StyledHeader>
          <Typography variant="h4">{title}</Typography>

          {link && (
            <StyledLink>
              <Link to={link} onClick={onLinkClick}>
                {t('seeMore')}
              </Link>
            </StyledLink>
          )}
        </StyledHeader>

        <>{children}</>
      </StyledCard>
    </Grid>
  );
};

const StyledCard = styled(Paper)(({ theme }) => ({
  borderRadius: '10px',
  height: '100%',
  padding: theme.spacing(3),
}));

const StyledHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(3),
}));

const StyledLink = styled('div')({
  fontSize: '12px',
  fontWeight: 600,
  letterSpacing: '0.05em',
  lineHeight: '16px',

  '& > a': {
    textDecoration: 'none',
  },
});
