import { Backdrop, CircularProgress, styled } from '@mui/material';

type BackdropLoaderProps = { open: boolean };

export const BackdropLoader = ({ open }: BackdropLoaderProps) => {
  return (
    <StyledBackdrop open={open}>
      <Loader />
    </StyledBackdrop>
  );
};

export const Loader = styled(CircularProgress)({
  margin: 'auto',
});

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  color: theme.palette.common.white,
  zIndex: theme.zIndex.drawer + 1,
}));
