import { CheckBoxOutlined, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  styled,
  Box,
  Checkbox,
  Autocomplete,
  TextField,
  Divider,
  AutocompleteChangeDetails,
} from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PROVINCE_OPTIONS } from '@/admin/consts/privacyPolicies';
import { PrivacyPoliciesContext } from '@/admin/providers';
import { Provinces } from '@/admin/types/common';

type AutocompleteProps = {
  provinces: Provinces[];
  setProvinces: (Provinces: Provinces[]) => void;
  originalProvinces?: Provinces[];
};

export const ProvinceAutoComplete = ({
  provinces,
  setProvinces,
  originalProvinces,
}: AutocompleteProps) => {
  const { t } = useTranslation();
  const { privacyPoliciesList } = useContext(PrivacyPoliciesContext);

  const [coveredProvinces, setCoveredProvinces] = useState<Provinces[]>(
    privacyPoliciesList.flatMap((policy) => policy.provinces)
  );
  const [nonCoveredProvinces, setNonCoveredProvinces] = useState<Provinces[]>(
    PROVINCE_OPTIONS.filter(
      (province) => !coveredProvinces.includes(province as Provinces)
    )
  );

  const [isAllSelected, setIsAllSelected] = useState<boolean>(
    nonCoveredProvinces.every((option) =>
      provinces.includes(option as Provinces)
    )
  );

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBoxOutlined fontSize="small" />;

  useEffect(() => {
    const covered = privacyPoliciesList.flatMap((policy) => policy.provinces);
    const nonCovered = PROVINCE_OPTIONS.filter(
      (province) => !covered.includes(province as Provinces)
    );
    if (nonCovered.length === 0) {
      setIsAllSelected(false);
    }
    setCoveredProvinces(covered);
    setNonCoveredProvinces(nonCovered);
  }, [provinces]);

  const handleAutocompleteChange = (
    newValue: string[],
    reason: string,
    option: AutocompleteChangeDetails<string> | undefined
  ) => {
    if (option?.option === 'all') {
      if (reason === 'selectOption') {
        setProvinces(provinces.concat(nonCoveredProvinces) as Provinces[]);
        setIsAllSelected(true);
      } else {
        setProvinces([]);
        setIsAllSelected(false);
      }
    } else {
      if (provinces.includes(option!.option as Provinces)) {
        setProvinces(
          provinces.filter((province) => province !== option?.option)
        );
      } else {
        setProvinces([...provinces, option!.option as Provinces]);
      }

      setIsAllSelected(
        nonCoveredProvinces.every((item) => newValue.includes(item))
      );
    }
  };

  const checkAllProvincesCovered = () => {
    // Create a set of all the provinces in the privacyPoliciesList
    const coveredProvinces = new Set();

    for (const policy of privacyPoliciesList) {
      for (const province of policy.provinces) {
        coveredProvinces.add(province);
      }
    }

    // Check if the coveredProvinces set includes all provinces in PROVINCE_OPTIONS
    for (const province of PROVINCE_OPTIONS) {
      if (!coveredProvinces.has(province)) {
        return false;
      }
    }

    return true;
  };

  return (
    <StyledAutocomplete>
      <Autocomplete
        fullWidth
        multiple
        disableCloseOnSelect
        clearOnBlur
        clearOnEscape
        aria-label={t('provinces')}
        disableClearable
        getOptionDisabled={(option) => {
          if (option === 'all') {
            return checkAllProvincesCovered();
          }

          // Check if the "option" is present in either "provinces" or "originalProvinces" (if provided)
          const combinedProvinces = originalProvinces
            ? [...provinces, ...originalProvinces]
            : provinces;
          if (combinedProvinces.includes(option as Provinces)) {
            return false;
          }

          // Check if the "option" is present in the provinces of any privacy policy
          return privacyPoliciesList.some((policy) => {
            return policy.provinces.includes(option as Provinces);
          });
        }}
        getOptionLabel={(option) => (option === 'divider' ? '' : t(option))}
        noOptionsText={t('noResultsFound')}
        options={['all', 'divider', ...PROVINCE_OPTIONS]}
        value={isAllSelected ? ['all'] : (provinces as Provinces[])}
        onChange={(event, newValue: string[], reason, option) => {
          handleAutocompleteChange(newValue, reason, option);
        }}
        renderInput={(params) => (
          <TextField
            placeholder={provinces.length ? '' : t('selectOptions')}
            {...params}
          />
        )}
        renderOption={(props, option, { selected }) => {
          if (option !== 'divider') {
            const key = option;

            return (
              <Box
                sx={{ background: 'transparent !important' }}
                component="li"
                {...props}
                key={key}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    isAllSelected
                      ? (originalProvinces ? originalProvinces : [])
                          .concat(nonCoveredProvinces)
                          .includes(option as Provinces) || option === 'all'
                      : selected
                  }
                />
                {t(option)}
              </Box>
            );
          }
          return <Divider style={{ margin: '0 auto', width: '90%' }} />;
        }}
      />
    </StyledAutocomplete>
  );
};

const StyledAutocomplete = styled('div')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1, 0, 2, 0),
}));
