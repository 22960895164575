import { IPrivacyPolicyValues, PolicyMergeTag, Province } from '@/common/types';

export const PRIVACY_POLICIES_PER_PAGE = 14;

export const defaultPrivacyPolicy: IPrivacyPolicyValues = {
  createdBy: '',
  dateCreated: 0,
  datePublished: 0,
  dateUpdated: 0,
  policyTextEn: '<p></p>',
  policyTextFr: '<p></p>',
  provinces: [],
  status: 'draft',
  updatedBy: '',
  userId: 0,
  userType: 'network',
};

export const PROVINCE_OPTIONS: Province[] = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NT',
  'NS',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT',
];

export const MERGE_TAG_TYPES: Record<PolicyMergeTag, PolicyMergeTag> = {
  brand: 'brand',
  franchise: 'franchise',
  franchiseAddress: 'franchiseAddress',
  franchiseEmail: 'franchiseEmail',
  franchiseLicense: 'franchiseLicense',
  franchisePhone: 'franchisePhone',
  franchiseWebsite: 'franchiseWebsite',
};

export const BLOCK_TYPES = {
  paragraph: 'normal',
  h1: 'heading1',
  h2: 'heading2',
  h3: 'heading3',
};

export const provinceInitials: Record<string, Record<string, string>> = {
  en: {
    AB: 'AB',
    BC: 'BC',
    MB: 'MB',
    NB: 'NB',
    NL: 'NL',
    NT: 'NT',
    NS: 'NS',
    NU: 'NU',
    ON: 'ON',
    PE: 'PE',
    QC: 'QC',
    SK: 'SK',
    YT: 'YT',
  },
  fr: {
    AB: 'AB',
    BC: 'CB',
    MB: 'MB',
    NB: 'NB',
    NL: 'TNL',
    NT: 'TNO',
    NS: 'NE',
    NU: 'NU',
    ON: 'ON',
    PE: 'IPE',
    QC: 'QC',
    SK: 'SK',
    YT: 'YK',
  },
};
