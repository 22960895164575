import {
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  LexicalEditor,
} from 'lexical';

import { Icon } from '@/admin/components';
import { ToolbarButton } from '@/admin/components/blogEditor/editorPlugins';

type ToolbarAlignProps = {
  editor: LexicalEditor;
};

export const ToolbarAlign = ({ editor }: ToolbarAlignProps) => {
  const handleAlignClick = (align: ElementFormatType) => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, align);
  };

  return (
    <>
      <ToolbarButton
        ariaLabel="Format align left"
        onClick={() => handleAlignClick('left')}
      >
        <Icon size="sm" symbol="format_align_left" />
      </ToolbarButton>

      <ToolbarButton
        ariaLabel="Format align center"
        onClick={() => handleAlignClick('center')}
      >
        <Icon size="sm" symbol="format_align_center" />
      </ToolbarButton>
      <ToolbarButton
        ariaLabel="Format align right"
        onClick={() => handleAlignClick('right')}
      >
        <Icon size="sm" symbol="format_align_right" />
      </ToolbarButton>
    </>
  );
};
