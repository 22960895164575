import { Stack, styled } from '@mui/material';

import { Skeleton } from '@/admin/components';

export const PrivacyPoliciesHeaderSkeleton = () => (
  <StyledPrivacyPoliciesSkeleton>
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={0}
    >
      <Skeleton width={325} height={56} />
      <Stack direction="row" alignItems="center">
        <Skeleton width={162} height={56} />
      </Stack>
    </Stack>
  </StyledPrivacyPoliciesSkeleton>
);

export const PrivacyPoliciesSkeleton = () => (
  <StyledPrivacyPoliciesSkeleton>
    <Skeleton height={240} />
  </StyledPrivacyPoliciesSkeleton>
);

const StyledPrivacyPoliciesSkeleton = styled('div')(({ theme }) => ({
  flexShrink: 0,
  padding: theme.spacing(5, 8, 0, 5),
}));
