import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BlogInfoSeoEditModal, ReadOnlyTextField } from '@/admin/components';
import { AppContext } from '@/admin/providers';
import {
  getIncrementedString,
  getSlugText,
  isSlugUnique,
} from '@/admin/utils/helpers';
import { getSlugsApi } from '@/admin/utils/helpers-api';
import { IBlogData, IBlogSEOData, ISlugsData } from '@/common/types';

export const BlogInfoSeo = () => {
  const { t } = useTranslation();

  const {
    getAccessToken,
    displayedUserInfo: { employeeId },
  } = useContext(AppContext);
  const { getValues, setValue, watch } = useFormContext<IBlogData>();

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [slugsData, setSlugsData] = useState<ISlugsData[]>([]);

  const metaDescription = watch('description');
  const slug = watch('slug');
  const metaTitle = watch('title');
  const blogId = getValues('id');

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleSaveEditModal = async ({
    slug,
    title,
    description,
  }: IBlogSEOData) => {
    const newSlug =
      slug || getSlugText(getValues('blogName')) || t('untitledBlogSlug');
    setValue('slug', newSlug, { shouldDirty: true });
    setValue('title', title || t('untitledBlog'), { shouldDirty: true });
    setValue('description', description, { shouldDirty: true });
    setValue('isSEODefault', false);
    setIsEditModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      const token = await getAccessToken();
      try {
        const slugsRes = await getSlugsApi(token, employeeId);
        setSlugsData(slugsRes);
      } catch (error) {
        /*eslint-disable-next-line no-console*/
        console.error(error);
      }
    })();
  }, [employeeId]);

  useEffect(() => {
    if (!isSlugUnique(slugsData, slug, blogId)) {
      setValue('slug', getIncrementedString(slug), { shouldDirty: false });
    }
  }, [slug]);

  return (
    <StyledBox>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3">{t('seo')}</Typography>
        <Button onClick={handleOpenEditModal}>{t('edit')}</Button>
      </Stack>

      <StyledTextField>
        <ReadOnlyTextField label={t('slug')} value={slug} />
      </StyledTextField>

      <StyledTextField>
        <ReadOnlyTextField label={t('metaTitle')} value={metaTitle} />
      </StyledTextField>

      <StyledTextarea>
        <ReadOnlyTextField
          label={t('metaDescription')}
          placeholder={metaDescription ? '' : t('metaDescriptionEmptyState')}
          value={metaDescription}
        />
      </StyledTextarea>

      <BlogInfoSeoEditModal
        open={isEditModalOpen}
        handleCloseEditModal={handleCloseEditModal}
        handleSaveEditModal={handleSaveEditModal}
        slugsData={slugsData}
      />
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),

  [theme.breakpoints.down('lg')]: {
    marginRight: theme.spacing(4),
  },
}));

const StyledTextField = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const StyledTextarea = styled(StyledTextField)({
  height: '162px',
});
