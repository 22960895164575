import { LexicalEditor } from 'lexical';

import { INSERT_MERGE_TAG_COMMAND } from '@/admin/components/privacyPolicyEditor/editorPlugins/mergeTagPlugin';
import { ToolbarSelect } from '@/admin/components/privacyPolicyEditor/editorPlugins/toolbarPlugin';
import { MERGE_TAG_TYPES } from '@/admin/consts/privacyPolicies';
import { SelectType } from '@/admin/types/common';

type ToolbarMergeTagsProps = {
  editor: LexicalEditor;
  defaultType: string;
  selectedType: SelectType | null;
};

export const ToolbarMergeTags = ({
  selectedType,
  defaultType,
  editor,
}: ToolbarMergeTagsProps) => {
  const formatType = (type: SelectType) => {
    editor.dispatchCommand(INSERT_MERGE_TAG_COMMAND, type);
  };

  return (
    <ToolbarSelect
      selectedType={selectedType}
      defaultType={defaultType}
      types={MERGE_TAG_TYPES as Record<SelectType, string>}
      formatType={formatType}
    />
  );
};
