import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $deleteTableColumn,
  $getElementGridForTableNode,
  $getTableColumnIndexFromTableCellNode,
  $getTableNodeFromLexicalNodeOrThrow,
  $getTableRowIndexFromTableCellNode,
  $insertTableColumn,
  $insertTableRow,
  $isTableCellNode,
  $isTableRowNode,
  $removeTableRowAtIndex,
  getTableSelectionFromTableElement,
  HTMLTableElementWithWithTableSelectionState,
  TableCellHeaderStates,
  TableCellNode,
} from '@lexical/table';
import { Box, List, ListItemButton, ListItemText, styled } from '@mui/material';
import { $getRoot, $getSelection, DEPRECATED_$isGridSelection } from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

type TableCellActionMenuProps = Readonly<{
  contextRef: { current: null | HTMLElement };
  onClose: () => void;
  setIsMenuOpen: (isOpen: boolean) => void;
  tableCellNode: TableCellNode;
}>;

export const TableCellActionMenuDropdown = ({
  onClose,
  tableCellNode: _tableCellNode,
  setIsMenuOpen,
  contextRef,
}: TableCellActionMenuProps) => {
  const { t } = useTranslation();

  const [editor] = useLexicalComposerContext();

  const dropDownRef = useRef<HTMLDivElement | null>(null);

  const [tableCellNode, setTableCellNode] = useState(_tableCellNode);
  const [selectionCounts, setSelectionCounts] = useState({
    columns: 1,
    rows: 1,
  });

  useEffect(() => {
    return editor.registerMutationListener(TableCellNode, (nodeMutations) => {
      const nodeUpdated =
        nodeMutations.get(tableCellNode.getKey()) === 'updated';

      if (nodeUpdated) {
        editor.getEditorState().read(() => {
          setTableCellNode(tableCellNode.getLatest());
        });
      }
    });
  }, [editor, tableCellNode]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      const selection = $getSelection();

      if (DEPRECATED_$isGridSelection(selection)) {
        const selectionShape = selection.getShape();

        setSelectionCounts({
          columns: selectionShape.toX - selectionShape.fromX + 1,
          rows: selectionShape.toY - selectionShape.fromY + 1,
        });
      }
    });
  }, [editor]);

  useEffect(() => {
    const positionDropdown = () => {
      const menuButtonElement = contextRef.current;
      const dropDownElement = dropDownRef.current;

      if (menuButtonElement != null && dropDownElement != null) {
        const menuButtonRect = menuButtonElement.getBoundingClientRect();
        const dropdownRect = dropDownElement.getBoundingClientRect();
        const isOffWindow =
          menuButtonRect.left + menuButtonRect.width + dropdownRect.width >
          window.innerWidth;

        dropDownElement.style.opacity = '1';

        dropDownElement.style.top = `${
          menuButtonRect.top + window.pageYOffset
        }px`;

        dropDownElement.style.left = `${
          isOffWindow
            ? menuButtonRect.left - dropdownRect.width - 5
            : menuButtonRect.left +
              menuButtonRect.width +
              window.pageXOffset +
              5
        }px`;
      }
    };
    positionDropdown();

    window.addEventListener('resize', positionDropdown);

    return () => {
      window.removeEventListener('resize', positionDropdown);
    };
  }, [contextRef, dropDownRef]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropDownRef.current != null &&
        contextRef.current != null &&
        !dropDownRef.current.contains(event.target as Node) &&
        !contextRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    }

    window.addEventListener('click', handleClickOutside);

    return () => window.removeEventListener('click', handleClickOutside);
  }, [setIsMenuOpen, contextRef]);

  const clearTableSelection = useCallback(() => {
    editor.update(() => {
      if (tableCellNode.isAttached()) {
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
        const tableElement = editor.getElementByKey(
          tableNode.getKey()
        ) as HTMLTableElementWithWithTableSelectionState;

        if (!tableElement) {
          throw new Error('Expected to find tableElement in DOM');
        }

        const tableSelection = getTableSelectionFromTableElement(tableElement);
        if (tableSelection !== null) {
          tableSelection.clearHighlight();
        }

        tableNode.markDirty();
        setTableCellNode(tableCellNode.getLatest());
      }

      const rootNode = $getRoot();
      rootNode.selectStart();
    });
  }, [editor, tableCellNode]);

  const insertTableRowAtSelection = useCallback(
    (shouldInsertAfter: boolean) => {
      editor.update(() => {
        const selection = $getSelection();

        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

        let tableRowIndex;

        if (DEPRECATED_$isGridSelection(selection)) {
          const selectionShape = selection.getShape();
          tableRowIndex = shouldInsertAfter
            ? selectionShape.toY
            : selectionShape.fromY;
        } else {
          tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode);
        }

        const grid = $getElementGridForTableNode(editor, tableNode);

        $insertTableRow(
          tableNode,
          tableRowIndex,
          shouldInsertAfter,
          selectionCounts.rows,
          grid
        );

        clearTableSelection();

        onClose();
      });
    },
    [editor, tableCellNode, selectionCounts.rows, clearTableSelection, onClose]
  );

  const insertTableColumnAtSelection = useCallback(
    (shouldInsertAfter: boolean) => {
      editor.update(() => {
        const selection = $getSelection();

        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

        let tableColumnIndex;

        if (DEPRECATED_$isGridSelection(selection)) {
          const selectionShape = selection.getShape();
          tableColumnIndex = shouldInsertAfter
            ? selectionShape.toX
            : selectionShape.fromX;
        } else {
          tableColumnIndex =
            $getTableColumnIndexFromTableCellNode(tableCellNode);
        }

        const grid = $getElementGridForTableNode(editor, tableNode);

        $insertTableColumn(
          tableNode,
          tableColumnIndex,
          shouldInsertAfter,
          selectionCounts.columns,
          grid
        );

        clearTableSelection();

        onClose();
      });
    },
    [
      editor,
      tableCellNode,
      selectionCounts.columns,
      clearTableSelection,
      onClose,
    ]
  );

  const deleteTableRowAtSelection = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
      const tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode);

      $removeTableRowAtIndex(tableNode, tableRowIndex);

      clearTableSelection();
      onClose();
    });
  }, [editor, tableCellNode, clearTableSelection, onClose]);

  const deleteTableAtSelection = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
      tableNode.remove();

      clearTableSelection();
      onClose();
    });
  }, [editor, tableCellNode, clearTableSelection, onClose]);

  const deleteTableColumnAtSelection = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

      const tableColumnIndex =
        $getTableColumnIndexFromTableCellNode(tableCellNode);

      $deleteTableColumn(tableNode, tableColumnIndex);

      clearTableSelection();
      onClose();
    });
  }, [editor, tableCellNode, clearTableSelection, onClose]);

  const toggleTableRowIsHeader = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

      const tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode);

      const tableRows = tableNode.getChildren();

      if (tableRowIndex >= tableRows.length || tableRowIndex < 0) {
        throw new Error('Expected table cell to be inside of table row.');
      }

      const tableRow = tableRows[tableRowIndex];

      if (!$isTableRowNode(tableRow)) {
        throw new Error('Expected table row');
      }

      tableRow.getChildren().forEach((tableCell) => {
        if (!$isTableCellNode(tableCell)) {
          throw new Error('Expected table cell');
        }

        tableCell.toggleHeaderStyle(TableCellHeaderStates.ROW);
      });

      clearTableSelection();
      onClose();
    });
  }, [editor, tableCellNode, clearTableSelection, onClose]);

  const toggleTableColumnIsHeader = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

      const tableColumnIndex =
        $getTableColumnIndexFromTableCellNode(tableCellNode);

      const tableRows = tableNode.getChildren();

      for (let r = 0; r < tableRows.length; r++) {
        const tableRow = tableRows[r];

        if (!$isTableRowNode(tableRow)) {
          throw new Error('Expected table row');
        }

        const tableCells = tableRow.getChildren();

        if (tableColumnIndex >= tableCells.length || tableColumnIndex < 0) {
          throw new Error('Expected table cell to be inside of table row.');
        }

        const tableCell = tableCells[tableColumnIndex];

        if (!$isTableCellNode(tableCell)) {
          throw new Error('Expected table cell');
        }

        tableCell.toggleHeaderStyle(TableCellHeaderStates.COLUMN);
      }

      clearTableSelection();
      onClose();
    });
  }, [editor, tableCellNode, clearTableSelection, onClose]);

  return createPortal(
    <StyledBox
      ref={dropDownRef}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <List>
        <ListItemButton onClick={() => insertTableRowAtSelection(false)}>
          <ListItemText
            primary={
              selectionCounts.rows === 1
                ? t('tableInsertRowAbove')
                : t('tableInsertRowsAbove').replace(
                    '{number}',
                    String(selectionCounts.rows)
                  )
            }
          />
        </ListItemButton>

        <ListItemButton
          divider={true}
          onClick={() => insertTableRowAtSelection(true)}
        >
          <ListItemText
            primary={
              selectionCounts.rows === 1
                ? t('tableInsertRowBelow')
                : t('tableInsertRowsBelow').replace(
                    '{number}',
                    String(selectionCounts.rows)
                  )
            }
          />
        </ListItemButton>

        <ListItemButton onClick={() => insertTableColumnAtSelection(false)}>
          <ListItemText
            primary={
              selectionCounts.columns === 1
                ? t('tableInsertColumnLeft')
                : t('tableInsertColumnsLeft').replace(
                    '{number}',
                    String(selectionCounts.columns)
                  )
            }
          />
        </ListItemButton>

        <ListItemButton
          divider={true}
          onClick={() => insertTableColumnAtSelection(true)}
        >
          <ListItemText
            primary={
              selectionCounts.columns === 1
                ? t('tableInsertColumnRight')
                : t('tableInsertColumnsRight').replace(
                    '{number}',
                    String(selectionCounts.columns)
                  )
            }
          />
        </ListItemButton>

        <ListItemButton onClick={deleteTableColumnAtSelection}>
          <ListItemText primary={t('tableDeleteColumn')} />
        </ListItemButton>

        <ListItemButton onClick={deleteTableRowAtSelection}>
          <ListItemText primary={t('tableDeleteRow')} />
        </ListItemButton>

        <ListItemButton divider={true} onClick={deleteTableAtSelection}>
          <ListItemText primary={t('tableDeleteTable')} />
        </ListItemButton>

        <ListItemButton onClick={toggleTableRowIsHeader}>
          <ListItemText
            primary={
              (tableCellNode.__headerState & TableCellHeaderStates.ROW) ===
              TableCellHeaderStates.ROW
                ? t('tableRemoveRowHeader')
                : t('tableAddRowHeader')
            }
          />
        </ListItemButton>

        <ListItemButton onClick={toggleTableColumnIsHeader}>
          <ListItemText
            primary={
              (tableCellNode.__headerState & TableCellHeaderStates.COLUMN) ===
              TableCellHeaderStates.COLUMN
                ? t('tableRemoveColumnHeader')
                : t('tableAddColumnHeader')
            }
          />
        </ListItemButton>
      </List>
    </StyledBox>,

    document.body
  );
};

const StyledBox = styled(Box)({
  zIndex: 10,
  position: 'fixed',
  boxShadow:
    '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
  borderRadius: '8px',
  backgroundColor: '#fff',
});
