import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { Icon } from '@/admin/components';
import { INavLink } from '@/admin/types/common';

type NavMenuProps = {
  icon: string;
  isOpen: boolean;
  links: INavLink[];
  title: string;
};

export const NavMenu = ({ icon, isOpen, links, title }: NavMenuProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [isSummaryActive, setIsSummaryActive] = useState<boolean>(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [accordionExpanded, setAccordionExpanded] = useState<string | false>(
    false
  );

  const open = Boolean(menuAnchorEl);

  const handleOpenMenu = (e: MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(e.currentTarget);
    setIsSummaryActive(false);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
    setIsSummaryActive(isMenuItemActive());
  };

  const handleChangeAccordion =
    (panel: string) => (e: SyntheticEvent, isExpanded: boolean) => {
      setAccordionExpanded(isExpanded ? panel : false);
      setIsSummaryActive(isMenuItemActive() && !isExpanded);
    };

  const isMenuItemActive = (): boolean => {
    const currentPathname = pathname.slice(1);
    return links.some((link) => link.title === currentPathname);
  };

  useEffect(() => {
    if (isOpen) {
      setIsSummaryActive(!accordionExpanded && isMenuItemActive());
    } else {
      setIsSummaryActive(isMenuItemActive() && !menuAnchorEl);
    }
  }, [pathname, isOpen]);

  const Summary = () => (
    <>
      <Icon size="md" symbol={icon} />
      {isOpen && <StyledTitle variant="subtitle1">{t(title)}</StyledTitle>}
    </>
  );

  const MenuList = () => (
    <>
      {links.map((link) => (
        <MenuItem key={link.to}>
          <NavLink
            className={({ isActive }: { isActive: boolean }) =>
              isActive ? 'active' : undefined
            }
            to={link.to}
          >
            {t(link.title)}
          </NavLink>
        </MenuItem>
      ))}
    </>
  );

  return (
    <StyledNavMenu>
      {isOpen && (
        <Accordion
          disableGutters
          elevation={0}
          square
          expanded={accordionExpanded === 'panel1'}
          onChange={handleChangeAccordion('panel1')}
        >
          <StyledAccordionSummary
            className={`summary  ${!isOpen ? 'closed' : ''} ${
              isSummaryActive ? 'active' : ''
            }`}
            expandIcon={<ExpandMoreIcon />}
          >
            <Summary />
          </StyledAccordionSummary>

          <StyledAccordionDetails>
            <ul>
              <MenuList />
            </ul>
          </StyledAccordionDetails>
        </Accordion>
      )}

      {!isOpen && (
        <>
          <Tooltip title={t(title)} arrow placement="right">
            <StyledSummary
              className={`summary ${!isOpen ? 'closed' : ''} ${
                isSummaryActive ? 'active' : ''
              }`}
              onClick={handleOpenMenu}
            >
              <Summary />
            </StyledSummary>
          </Tooltip>

          <Menu
            className="navigation"
            anchorEl={menuAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            onClose={handleCloseMenu}
          >
            <MenuList />
          </Menu>
        </>
      )}
    </StyledNavMenu>
  );
};

const StyledNavMenu = styled('div')(({ theme }) => ({
  '& .MuiPaper-root': {
    border: 'none',
  },

  '& .summary': {
    alignItems: 'center',
    borderLeft: '4px solid transparent',
    display: 'flex',
    height: '48px',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    transition: 'all 0.3s',
    width: '100%',

    '&:hover': {
      backgroundColor: theme.palette.primary.lighter,
    },

    '&.closed': {
      justifyContent: 'center',
      paddingLeft: theme.spacing(1.5),
    },

    '&.active': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,
    },

    '&  a': {
      display: 'flex',
    },

    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
      paddingLeft: theme.spacing(1.5),
    },
  },
}));

const StyledSummary = styled('button')({
  cursor: 'pointer',
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2.5),

  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,

  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    transform: 'rotate(-90deg)',

    '&.Mui-expanded': {
      transform: 'rotate(0)',
    },
  },

  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,

  '& .MuiMenuItem-root': {
    padding: 0,

    '& > *': {
      borderLeft: '4px solid transparent',
      color: theme.palette.text.primary,
      lineHeight: '48px',
      height: '48px',
      padding: theme.spacing(0, 2, 0, 9.5),
      width: '100%',

      '&.active': {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.main,
      },
    },
  },
}));
