import { Star } from '@mui/icons-material';
import { Rating, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Link, TestimonialFacebookRating, IconSvg } from '@/admin/components';
import { ThemeName } from '@/admin/types/common';
import { brandIcons, sourceIcons } from '@/admin/utils/helpers';
import { ITestimonialSummary, TestimonialSource } from '@/common/types';

type DashboardTestimonialsSummaryProps = {
  data: Record<TestimonialSource, ITestimonialSummary>;
  brand: ThemeName;
  link: string;
  onLinkClick: (source: TestimonialSource) => void;
};

export const DashboardTestimonialsSummary = ({
  data,
  brand,
  link,
  onLinkClick,
}: DashboardTestimonialsSummaryProps) => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      {Object.keys(data).map((source) => {
        const { averageRating, total } = data[source as TestimonialSource];
        const Icon =
          source === 'website' ? brandIcons[brand] : sourceIcons[source];

        const hasReviews = total > 0;
        const hasOneReview = total === 1;

        return (
          <StyledStack key={source}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <StyledSvgIcon>
                <IconSvg>
                  <Icon />
                </IconSvg>
              </StyledSvgIcon>
              {hasReviews ? (
                <>
                  <Typography variant="h3">{averageRating}</Typography>
                  {source === 'facebook' && (
                    <TestimonialFacebookRating isLiked={averageRating > 2.5} />
                  )}
                  {source !== 'facebook' && (
                    <StyledRating
                      name="read-only"
                      value={averageRating}
                      readOnly
                      precision={0.1}
                      emptyIcon={
                        <Star style={{ opacity: 0.55 }} fontSize="inherit" />
                      }
                    />
                  )}
                  <Typography>{`${total} ${
                    hasOneReview ? t(`${source}Review`) : t(`${source}Reviews`)
                  }`}</Typography>
                </>
              ) : (
                <Typography>{t('noReviews')}</Typography>
              )}
            </Stack>
            {hasReviews && (
              <StyledLink>
                <Link
                  to={link}
                  onClick={() => onLinkClick(source as TestimonialSource)}
                >
                  {t('seeAll')}
                </Link>
              </StyledLink>
            )}
          </StyledStack>
        );
      })}
    </StyledContainer>
  );
};

const StyledContainer = styled('div')({
  height: '80%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '100px',
  borderBottom: `1px solid ${theme.palette.grey[50]}`,
}));

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: theme.palette.common.black,
  },
}));

const StyledLink = styled('div')({
  fontSize: '12px',
  fontWeight: 600,
  letterSpacing: '0.05em',
  lineHeight: '16px',

  '& > a': {
    textDecoration: 'none',
  },
});

const StyledSvgIcon = styled('div')({
  fontSize: '24px',
  lineHeight: 0,
});
