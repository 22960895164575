import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { TextField } from '@/admin/components';
import { AppContext, defaultBlogSEOValues } from '@/admin/providers';
import { isSlugUnique, getSlugURL } from '@/admin/utils/helpers';
import { IBlogSEOData, ISlugsData } from '@/common/types';

type BlogInfoSeoEditModalProps = {
  open: boolean;
  handleCloseEditModal: () => void;
  handleSaveEditModal: (values: IBlogSEOData) => void;
  slugsData: ISlugsData[];
};

export const BlogInfoSeoEditModal = ({
  open,
  handleCloseEditModal,
  handleSaveEditModal,
  slugsData,
}: BlogInfoSeoEditModalProps) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const {
    displayedUserInfo: { networkId, siteName, siteNameFr, userType },
  } = useContext(AppContext);
  const { getValues } = useFormContext();

  const [errors, setErrors] = useState(defaultBlogSEOValues);
  const [values, setValues] = useState(defaultBlogSEOValues);
  const [isSlugError, setIsSlugError] = useState<boolean>(false);

  const blogId = getValues('id');

  const blogSEOSchema = yup.object().shape({
    description: yup.string().max(160, t('formDescriptionMax')).optional(),
    slug: yup
      .string()
      .max(150, t('formSlugMax'))
      .matches(/^$|^[a-z0-9-]+$/, t('formSlugMatch'))
      .optional(),
    title: yup.string().max(60, t('formTitleMax')).optional(),
  });

  useEffect(() => {
    if (open) {
      setValues({
        slug: getValues('slug'),
        title: getValues('title'),
        description: getValues('description'),
      });
    }
  }, [open]);

  const handleChangeSlug = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, slug: e.target.value });
    validateInputValue('slug', e.target.value);

    setIsSlugError(!isSlugUnique(slugsData, e.target.value, blogId));
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, title: e.target.value });
    validateInputValue('title', e.target.value);
  };

  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, description: e.target.value });
    validateInputValue('description', e.target.value);
  };

  const onClose = () => {
    handleCloseEditModal();
    setErrors(defaultBlogSEOValues);
  };

  const onSave = () => {
    const hasNoError = !Object.values(errors).filter((err) => err).length;

    if (hasNoError) {
      handleSaveEditModal(values);
    }
  };

  const validateInputValue = (key: string, value: string) => {
    blogSEOSchema
      .validate({ ...values, [key]: value }, { abortEarly: false })
      .then(() => {
        setErrors(defaultBlogSEOValues);
      })
      .catch((err) => {
        const newErrors: IBlogSEOData = { ...defaultBlogSEOValues };

        err.inner.map((e: yup.ValidationError) => {
          if (e.path) {
            newErrors[e.path as keyof IBlogSEOData] = e.message;
          }
        });

        setErrors(newErrors);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t('editSeo')}</DialogTitle>

      <DialogContent>
        <StyledTextField>
          <TextField
            error={!!errors.slug}
            helperText={errors.slug}
            label={t('slug')}
            placeholder={t('slug')}
            subText={getSlugURL(
              language,
              networkId,
              userType,
              siteName,
              siteNameFr
            )}
            value={values.slug}
            onChange={handleChangeSlug}
          />
        </StyledTextField>

        {isSlugError && <StyledError>{t('slugAlert')}</StyledError>}

        <StyledTextField>
          <TextField
            error={!!errors.title}
            helperText={errors.title}
            label={t('metaTitle')}
            placeholder={t('metaTitle')}
            value={values.title}
            onChange={handleChangeTitle}
          />
        </StyledTextField>

        <StyledTextField>
          <TextField
            caption={t('metaDescriptionHelperCaption')}
            error={!!errors.description}
            helperText={errors.description}
            label={t('metaDescription')}
            multiline={true}
            placeholder={t('metaDescriptionPlaceholder')}
            value={values.description}
            onChange={handleChangeDescription}
          />
        </StyledTextField>
      </DialogContent>

      <StyledDialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t('cancel')}
        </Button>

        <Button variant="contained" onClick={onSave}>
          {t('saveChanges')}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

const StyledTextField = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  paddingRight: theme.spacing(3),
}));

const StyledError = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'Archivo',
  color: theme.palette.error.main,
  padding: theme.spacing(0.5, 0, 0, 2),
}));
