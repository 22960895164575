import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Dialog, ProvinceAutoComplete } from '@/admin/components';
import { useModal } from '@/admin/hooks';
import { PrivacyPoliciesContext } from '@/admin/providers';
import { getLocalizedDate } from '@/admin/utils/helpers';
import { IPrivacyPoliciesData, Province } from '@/common/types';

export const PrivacyPolicyContentHeader = () => {
  const { privacyPolicyId } = useParams();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { getValues, setValue } = useFormContext<IPrivacyPoliciesData>();

  const provinces = getValues('provinces');
  const dateUpdated = getValues('dateUpdated');
  const datePublished = getValues('datePublished');
  const userType = getValues('userType');

  const [selectedProvinces, setSelectedProvinces] =
    useState<Province[]>(provinces);

  const { isLoading, privacyPoliciesList } = useContext(PrivacyPoliciesContext);
  const { modalContent, showModal, handleModalClose } = useModal();

  const lastSaved = dateUpdated && getLocalizedDate(language, dateUpdated);
  const lastPublished =
    datePublished && getLocalizedDate(language, datePublished);

  const originalProvinces = privacyPoliciesList.find((policy) => {
    return policy.id === privacyPolicyId;
  })?.provinces;

  const onButtonClick = () => {
    showModal({
      title: t('editProvinces'),
      message: '',
      buttonAgree: t('confirm'),
      handleAgree: handleSave,
      handleClose: handleClose,
    });
  };

  // handling SAVE new policy action
  const handleSave = async () => {
    setValue('provinces', selectedProvinces);
    handleModalClose();
  };

  const handleClose = () => {
    setSelectedProvinces(provinces);
    handleModalClose();
  };

  return (
    <StyledContainer
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Stack direction="column" spacing={2}>
        <Typography variant="h4">
          {t(userType === 'network' ? 'corporate' : 'custom')}
        </Typography>
        <Typography variant="h1">{t('privacyPolicy')}</Typography>

        <StyledChipContainer>
          {userType === 'network' ? (
            <StyledChip
              variant="outlined"
              key={'corporate'}
              label={t('default')}
            />
          ) : provinces.length ? (
            provinces.map((province: Province) => (
              <StyledChip
                variant="outlined"
                key={province}
                label={t(province)}
              />
            ))
          ) : (
            <StyledChip
              variant="outlined"
              key={'none'}
              label={t('unassigned')}
            />
          )}
          {userType !== 'network' && (
            <StyledButton onClick={onButtonClick}>{t('edit')}</StyledButton>
          )}
        </StyledChipContainer>
      </Stack>

      <StyledStack direction="row">
        {userType === 'network' ? (
          <StyledTextBox>
            <Typography variant="caption">{`${t('lastSaved')}:`}</Typography>
            <Typography>{lastSaved || t('unsaved')}</Typography>
          </StyledTextBox>
        ) : (
          <>
            <StyledTextBox>
              <Typography variant="caption">{`${
                datePublished ? t('lastUpdated') : t('lastSaved')
              }:`}</Typography>
              <Typography>{lastSaved || t('unsaved')}</Typography>
            </StyledTextBox>
            <Divider orientation="vertical" flexItem />
            <StyledTextBox>
              <Typography variant="caption">{`${t(
                'firstPublished'
              )}:`}</Typography>
              <Typography>{lastPublished || t('unpublished')}</Typography>
            </StyledTextBox>
          </>
        )}
      </StyledStack>

      <Dialog
        buttonAgree={modalContent.buttonAgree}
        message={modalContent.message}
        title={modalContent.title}
        open={modalContent.open}
        disableAgreeButton={false}
        handleClose={modalContent.handleClose}
        handleAgree={handleSave}
        hasCloseIconButton
      >
        {!isLoading && (
          <ProvinceAutoComplete
            provinces={selectedProvinces}
            setProvinces={setSelectedProvinces}
            originalProvinces={originalProvinces}
          />
        )}
      </Dialog>
    </StyledContainer>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  '& > :first-child': {
    margin: theme.spacing(0, 2, 0, 0),
  },
}));

const StyledContainer = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  minWidth: '750px',
}));

const StyledTextBox = styled(Box)(({ theme }) => ({
  minWidth: theme.spacing(16),
  padding: theme.spacing(1, 0, 1, 2),
}));

const StyledChipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1.5),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  borderColor: theme.palette.grey[500],
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  position: 'relative',
  zIndex: 2,
}));
