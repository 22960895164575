import { Paper, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { EmptyState } from '@/admin/components';

export const TestimonialsEmptyState = () => {
  const { t } = useTranslation();

  return (
    <StyledPaper>
      <EmptyState
        icon="format_quote"
        message={t('noTestimonialsFoundMessage')}
        title={t('noTestimonialsFound')}
      />
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.palette.grey[400]}`,
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
}));
