import { TOGGLE_LINK_COMMAND } from '@lexical/link';
import { FORMAT_TEXT_COMMAND, LexicalEditor, TextFormatType } from 'lexical';

import { Icon } from '@/admin/components';
import { ToolbarButton } from '@/admin/components/blogEditor/editorPlugins';

type ToolbarTextFormatProps = {
  editor: LexicalEditor;
  isBold: boolean;
  isItalic: boolean;
  isLink: boolean;
  isUnderline: boolean;
};

export const ToolbarTextFormat = ({
  editor,
  isBold,
  isItalic,
  isLink,
  isUnderline,
}: ToolbarTextFormatProps) => {
  const handleTextFormatClick = (formatType: TextFormatType) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);
  };

  const insertLink = () => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  };

  return (
    <>
      <ToolbarButton
        ariaLabel="Format bold"
        className={isBold ? 'active' : ''}
        onClick={() => handleTextFormatClick('bold')}
      >
        <Icon size="sm" symbol="format_bold" wght={600} />
      </ToolbarButton>

      <ToolbarButton
        ariaLabel="Format italic"
        className={isItalic ? 'active' : ''}
        onClick={() => handleTextFormatClick('italic')}
      >
        <Icon size="sm" symbol="format_italic" />
      </ToolbarButton>

      <ToolbarButton
        ariaLabel="Format underline"
        className={isUnderline ? 'active' : ''}
        onClick={() => handleTextFormatClick('underline')}
      >
        <Icon size="sm" symbol="format_underlined" />
      </ToolbarButton>

      <ToolbarButton
        ariaLabel="Link"
        className={isLink ? 'active' : ''}
        onClick={insertLink}
      >
        <Icon size="sm" symbol="link" />
      </ToolbarButton>
    </>
  );
};
