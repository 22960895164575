import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  styled,
} from '@mui/material';
import { MouseEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Dialog } from '@/admin/components';
import { useModal } from '@/admin/hooks';
import {
  AppContext,
  PrivacyPoliciesContext,
  ThemeContext,
} from '@/admin/providers';
import { IAction } from '@/admin/types/common';
import { deletePolicyApi } from '@/admin/utils/helpers-api';
import { IPrivacyPoliciesData } from '@/common/types';

type PrivacyPoliciesTableActionsProps = {
  privacyPolicy: IPrivacyPoliciesData;
  handleCopyClick: (privacyPolicy: IPrivacyPoliciesData) => void;
};

export const PrivacyPoliciesTableActions = ({
  privacyPolicy,
  handleCopyClick,
}: PrivacyPoliciesTableActionsProps) => {
  const { t } = useTranslation();

  const {
    getAccessToken,
    selectedUser: { userType },
  } = useContext(AppContext);
  const { openSnackbar } = useContext(ThemeContext);
  const { privacyPoliciesList, setPrivacyPoliciesList } = useContext(
    PrivacyPoliciesContext
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { modalContent, showModal, handleModalClose } = useModal();

  const { userType: policyType, id } = privacyPolicy;

  const openPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const closeDialog = () => {
    handleModalClose();
    closePopover();
  };

  const handleDeleteClick = () => {
    showModal({
      message: t('deletePolicyDialogMessage'),
      title: t('deletePolicyDialogTitle'),
      handleAgree: agreeDeleteDialog,
      handleClose: closeDialog,
    });
  };

  const agreeDeleteDialog = async () => {
    try {
      const token = await getAccessToken();
      const status = await deletePolicyApi(id, token);

      if (status === 200) {
        handleSuccessAction(id, t('deletePolicySnackbarMessage'));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleSuccessAction = (id: string, snackbarMessage: string) => {
    const newList = privacyPoliciesList.filter((policy) => policy.id !== id);
    setPrivacyPoliciesList(newList);
    closePopover();
    handleModalClose();
    openSnackbar(snackbarMessage);
  };

  const [editAction, copyAction, deleteAction]: IAction[] = [
    {
      id: 'edit',
      title: t(`${policyType === 'network' ? 'view' : 'edit'}`),
      to: id,
    },
    {
      id: 'copy',
      title: t('copy'),
      onClick: () => handleCopyClick(privacyPolicy),
    },
    { id: 'delete', title: t('delete'), onClick: handleDeleteClick },
  ];
  const actions: IAction[] = [editAction, copyAction, deleteAction];
  if (policyType === 'network') {
    actions.pop();
  }
  if (userType === 'network') {
    actions.pop();
  }

  return (
    <>
      <div>
        <IconButton onClick={openPopover}>
          <MoreHorizIcon />
        </IconButton>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <StyledList>
            {actions.map((action) => (
              <ListItem disablePadding key={action.id}>
                {/* link */}
                {action.to && (
                  <ListItemButton>
                    <ListItemText>
                      <Link to={action.to}>{action.title}</Link>
                    </ListItemText>
                  </ListItemButton>
                )}
                {/* button */}
                {!action.to && (
                  <ListItemButton onClick={action.onClick}>
                    <ListItemText primary={action.title} />
                  </ListItemButton>
                )}
              </ListItem>
            ))}
          </StyledList>
        </Popover>
      </div>

      <Dialog
        buttonAgree={modalContent.buttonAgree}
        buttonClose={modalContent.buttonClose}
        message={modalContent.message}
        title={modalContent.title}
        open={modalContent.open}
        handleAgree={modalContent.handleAgree}
        handleClose={modalContent.handleClose}
      />
    </>
  );
};

const StyledList = styled(List)({
  width: '120px',
});
