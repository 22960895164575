import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar as MuiSnackbar } from '@mui/material';

type SnackbarProps = {
  message: string;
  open: boolean;
  handleClose: () => void;
};

export const Snackbar = ({ message, open, handleClose }: SnackbarProps) => (
  <MuiSnackbar
    action={
      <IconButton
        size="medium"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="medium" />
      </IconButton>
    }
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    autoHideDuration={6000}
    message={message}
    open={open}
    onClose={handleClose}
  />
);
