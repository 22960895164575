import { ChangeEventHandler } from 'react';

import {
  BlogContentBody,
  BlogContentHeader,
} from '@/admin/components/blogPost';

type BlogContentProps = {
  handleVisibilityChange: ChangeEventHandler;
};

export const BlogContent = ({ handleVisibilityChange }: BlogContentProps) => (
  <>
    <BlogContentHeader handleVisibilityChange={handleVisibilityChange} />
    <BlogContentBody />
  </>
);
