import {
  Box,
  Chip,
  styled,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PrivacyPoliciesTableActions, Link } from '@/admin/components';
import { LAST_COLUMN_WIDTH } from '@/admin/consts';
import { PrivacyPoliciesContext } from '@/admin/providers';
import { IPrivacyPoliciesPageColumn } from '@/admin/types/common';
import { getLocalizedDate } from '@/admin/utils/helpers';
import { IPrivacyPoliciesData, Province } from '@/common/types';

type PrivacyPoliciesTableProps = {
  handleCopyClick: (privacyPolicy: IPrivacyPoliciesData) => void;
  handleOpenModal: () => void;
};

const columns: IPrivacyPoliciesPageColumn[] = [
  { id: 'userType', label: 'type' },
  { id: 'provinces', label: 'provinces' },
  { id: 'dateUpdated', label: 'lastSaved' },
  { id: 'actions', label: 'actions' },
];

export const PrivacyPoliciesTable = ({
  handleCopyClick,
}: PrivacyPoliciesTableProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { allPolicies } = useContext(PrivacyPoliciesContext);

  return (
    <TableContainer>
      <MuiTable>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCellHead
                key={column.id}
                align={column.align}
                style={column.id === 'provinces' ? { width: '50%' } : {}}
              >
                {t(column.label)}
              </StyledTableCellHead>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {allPolicies.map((privacyPolicy, i) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={i}>
              {columns.map((column) => {
                if (column.id === 'userType') {
                  return (
                    <TableCell key={column.id} align={column.align}>
                      <StyledLinkStyler>
                        <Link to={privacyPolicy.id}>
                          {t(
                            privacyPolicy[column.id] === 'network'
                              ? 'corporate'
                              : 'custom'
                          )}
                        </Link>
                      </StyledLinkStyler>
                    </TableCell>
                  );
                }

                if (column.id === 'provinces') {
                  return (
                    <TableCell key={column.id} align={column.align}>
                      <StyledChipContainer>
                        {privacyPolicy.userType === 'network' ? (
                          <StyledChip
                            variant="outlined"
                            key={'corporate'}
                            label={t('default')}
                          />
                        ) : privacyPolicy.provinces.length ? (
                          privacyPolicy.provinces.map((province: Province) => (
                            <StyledChip
                              variant="outlined"
                              key={province}
                              label={t(province)}
                            />
                          ))
                        ) : (
                          <StyledSpan>NA</StyledSpan>
                        )}
                      </StyledChipContainer>
                    </TableCell>
                  );
                }

                if (column.id === 'dateUpdated') {
                  return (
                    <TableCell key={column.id} align={column.align}>
                      <span>
                        {getLocalizedDate(
                          language,
                          privacyPolicy.dateUpdated as number
                        )}
                      </span>
                    </TableCell>
                  );
                }

                if (column.id === 'actions') {
                  return (
                    <StyledTableCell key={column.id} align={column.align}>
                      <PrivacyPoliciesTableActions
                        privacyPolicy={privacyPolicy}
                        handleCopyClick={handleCopyClick}
                      />
                    </StyledTableCell>
                  );
                }
                return (
                  <TableCell key={column.id} align={column.align}>
                    <span>{privacyPolicy[column.id] as string}</span>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

const StyledLinkStyler = styled('div')({
  '& > *': {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
  },
});

const StyledTableCellHead = styled(TableCell)({
  width: `calc((100% - ${LAST_COLUMN_WIDTH}px) / 3)`, // 4 = columns.length - 1

  '&:last-child': {
    width: `${LAST_COLUMN_WIDTH}px`,
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 2, 1, 1),
}));

const StyledChipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1.5),
}));

const StyledSpan = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  borderColor: theme.palette.grey[500],
}));
