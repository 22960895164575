import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { IPrivacyPoliciesData, IPrivacyPolicyValues } from '@/common/types';

type PrivacyPolicyFormProviderProps = {
  children: ReactNode;
  defaultValues: IPrivacyPolicyValues | IPrivacyPoliciesData;
};

export const PrivacyPolicyFormProvider = ({
  children,
  defaultValues,
}: PrivacyPolicyFormProviderProps) => {
  const { t } = useTranslation();

  const privacyPolicySchema = yup.object().shape({
    brand: yup.string(),
    createdBy: yup.string(),
    dateCreated: yup.number(),
    datePublished: yup.number(),
    dateUpdated: yup.number(),
    franchiseId: yup.number(),
    language: yup.string(),
    networkId: yup.number(),
    policyTextEn: yup.string().required(t('formPrivacyPolicyContentRequired')),
    policyTextFr: yup.string().required(t('formPrivacyPolicyContentRequired')),
    provinces: yup.array(),
    status: yup.string(),
    updatedBy: yup.string(),
  });

  const methods = useForm<IPrivacyPolicyValues>({
    mode: 'onTouched',
    resolver: yupResolver(privacyPolicySchema),
    defaultValues,
  });

  useEffect(() => {
    // update default values as fetched PrivacyPolicy data
    methods.reset({ ...defaultValues });
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <StyledForm>{children}</StyledForm>
    </FormProvider>
  );
};

const StyledForm = styled('form')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});
