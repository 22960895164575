import { Check } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  Typography,
  styled,
} from '@mui/material';
import { FocusEvent, ReactNode, forwardRef } from 'react';
import { RefCallBack } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ISelectOption } from '@/admin/types/common';

type SelectProps = {
  ariaLabel: string;
  label: string;
  options: ISelectOption[];
  value: string;
  onChange: (e: SelectChangeEvent) => void;
  renderValue?: (value: string) => ReactNode;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
};

export const Select = forwardRef<RefCallBack, SelectProps>(
  (
    {
      ariaLabel,
      label,
      options,
      value,
      onBlur,
      onChange,
      renderValue,
      disabled,
      error,
      helperText,
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <FormControl disabled={disabled} error={error} fullWidth size="small">
        <StyledTypography
          className={disabled ? 'disabled' : ''}
          variant="caption"
        >
          {label}
        </StyledTypography>

        <MuiSelect
          displayEmpty
          inputProps={{ 'aria-label': ariaLabel }}
          onBlur={onBlur}
          onChange={onChange}
          ref={ref}
          value={value}
          IconComponent={ExpandMoreIcon}
          renderValue={
            renderValue || value ? undefined : () => <div>{t('select')}</div>
          }
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <StyledIcon className="selected-icon">
                <Check color="primary" />
              </StyledIcon>
              {t(option.label)}
            </MenuItem>
          ))}
        </MuiSelect>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);

Select.displayName = 'Select';

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),

  '&.disabled': {
    color: theme.palette.text.secondary,
  },
}));

const StyledIcon = styled('div')(({ theme }) => ({
  display: 'none',
  lineHeight: 0,
  marginRight: theme.spacing(1),
}));
