import { Skeleton, Stack, styled } from '@mui/material';

export const AnnouncementsSkeleton = () => (
  <StyledContainer>
    <StyledStack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Skeleton variant="rectangular" width={312} height={56} />

      <Stack direction="row" spacing={3}>
        <Skeleton variant="rectangular" width={98} height={40} />
        <Skeleton variant="rectangular" width={120} height={40} />
      </Stack>
    </StyledStack>

    <Skeleton variant="rectangular" height={56} />

    <StyledSkeleton variant="rectangular" />
  </StyledContainer>
);

export const AnnouncementsFormSkeleton = () => (
  <>
    <Skeleton variant="rectangular" height={56} />
    <StyledSkeleton variant="rectangular" />
  </>
);

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.spacing(6, 6, 4),
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(7),
}));

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  flexGrow: 1,
  marginTop: theme.spacing(3),
}));
