import { Warning } from '@mui/icons-material';
import { Alert, Button, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/admin/components';
import { GoogleIcon } from '@/admin/icons';
import { setItemToLocalStorage } from '@/admin/utils/helpers';

type TestimonialsAlertProps = {
  setGoogleAlertOpen: (value: boolean) => void;
  googleSignInStatus: string;
  googleLogin: () => void;
};

export const TestimonialsAlert = ({
  setGoogleAlertOpen,
  googleSignInStatus,
  googleLogin,
}: TestimonialsAlertProps) => {
  const { t } = useTranslation();

  return (
    <StyledAlert
      icon={<Warning />}
      severity="warning"
      onClose={() => {
        setGoogleAlertOpen(false);
        setItemToLocalStorage('prefersNoAlert', true);
      }}
    >
      <StyledContainer>
        <StyledTextContainer>
          {googleSignInStatus === 'null'
            ? t('googleSignInMessage')
            : t('googleSignInExpired')}
        </StyledTextContainer>
        <StyledButtonContainer>
          <StyledButton variant="outlined" onClick={() => googleLogin()}>
            <IconSvg>
              <StyledSvgIcon>
                <GoogleIcon />
              </StyledSvgIcon>
            </IconSvg>
            {t('googleSignIn')}
          </StyledButton>
        </StyledButtonContainer>
      </StyledContainer>
    </StyledAlert>
  );
};

const StyledAlert = styled(Alert)(({ theme }) => ({
  margin: theme.spacing(4, 8, 0, 6),
  '& > :nth-of-type(2)': {
    width: '100%',
  },
}));

const StyledContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledTextContainer = styled('div')({
  flexGrow: 1,
  lineHeight: '20px',
});

const StyledButtonContainer = styled('div')({
  marginLeft: 'auto',
});

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: '#0D1022',
  backgroundColor: '#FFFFFF',
  borderColor: 'rgba(42, 67, 255, 0.5)', // #2A43FF with 50% opacity
  borderRadius: '5px',
  marginLeft: theme.spacing(2),
  padding: theme.spacing(1, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  gap: '8px',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    borderColor: '#cccccc',
  },
}));

const StyledSvgIcon = styled('div')({
  lineHeight: 0,
});
