import { Button, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReadOnlyTextField, Top } from '@/admin/components';
import { getLocalizedDate } from '@/admin/utils/helpers';

type AnnouncementsTopProps = {
  handlePublish: () => void;
  handleSave: () => void;
  datePublished?: number | null;
  dateSaved?: number | null;
};

export const AnnouncementsTop = ({
  datePublished,
  dateSaved,
  handlePublish,
  handleSave,
}: AnnouncementsTopProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Top title={t('announcements')}>
      <Stack alignItems="center" direction="row" spacing={3}>
        {dateSaved && (
          <ReadOnlyTextField
            label={t('lastSaved')}
            value={getLocalizedDate(language, dateSaved)}
          />
        )}

        {datePublished && (
          <>
            <Divider orientation="vertical" flexItem />
            <ReadOnlyTextField
              label={t('lastPublished')}
              value={getLocalizedDate(language, datePublished)}
            />
          </>
        )}

        <Button variant="outlined" onClick={handleSave}>
          {t('save')}
        </Button>

        <Button variant="contained" onClick={handlePublish}>
          {t('publish')}
        </Button>
      </Stack>
    </Top>
  );
};
