import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getTableCellNodeFromLexicalNode,
  TableCellNode,
} from '@lexical/table';
import { styled } from '@mui/material';
import { $getSelection, $isRangeSelection } from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Icon } from '@/admin/components';
import { TableCellActionMenuDropdown } from '@/admin/components/blogEditor/editorPlugins';

type TableCellActionMenuPluginProps = {
  anchorElem: HTMLElement;
};

export const TableCellActionMenuPlugin = ({
  anchorElem,
}: TableCellActionMenuPluginProps) => {
  const [editor] = useLexicalComposerContext();

  const menuButtonRef = useRef(null);
  const menuRootRef = useRef(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [tableCellNode, setTableMenuCellNode] = useState<TableCellNode | null>(
    null
  );

  const moveMenu = useCallback(() => {
    const menu = menuButtonRef.current;
    const selection = $getSelection();
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (selection == null || menu == null) {
      setTableMenuCellNode(null);
      return;
    }

    const rootElement = editor.getRootElement();

    if (
      $isRangeSelection(selection) &&
      rootElement !== null &&
      nativeSelection !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(
        selection.anchor.getNode()
      );

      if (tableCellNodeFromSelection == null) {
        setTableMenuCellNode(null);
        return;
      }

      const tableCellParentNodeDOM = editor.getElementByKey(
        tableCellNodeFromSelection.getKey()
      );

      if (tableCellParentNodeDOM == null) {
        setTableMenuCellNode(null);
        return;
      }

      setTableMenuCellNode(tableCellNodeFromSelection);
    } else if (!activeElement) {
      setTableMenuCellNode(null);
    }
  }, [editor]);

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.getEditorState().read(() => {
        moveMenu();
      });
    });
  }, [editor, moveMenu]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const positionMenuButton = () => {
      const menuButtonDOM = menuButtonRef.current as HTMLButtonElement | null;

      if (
        menuButtonDOM !== null &&
        tableCellNode !== null &&
        scrollerElem !== null
      ) {
        const tableCellNodeDOM = editor.getElementByKey(tableCellNode.getKey());

        if (tableCellNodeDOM != null) {
          const tableCellRect = tableCellNodeDOM.getBoundingClientRect();
          const menuRect = menuButtonDOM.getBoundingClientRect();
          const scrollerRect = scrollerElem.getBoundingClientRect();

          const top = tableCellRect.top - scrollerRect.top + 6;
          const left =
            tableCellRect.right - menuRect.width - scrollerRect.left - 10;

          menuButtonDOM.style.opacity =
            tableCellRect.bottom <= scrollerRect.bottom ? '1' : '0';
          menuButtonDOM.style.transform = `translate(${left}px, ${top}px)`;
        } else {
          menuButtonDOM.style.opacity = '0';
          menuButtonDOM.style.transform = 'translate(-10000px, -10000px)';
        }
      }
    };
    positionMenuButton();

    scrollerElem?.addEventListener('scroll', positionMenuButton);
    window.addEventListener('resize', positionMenuButton);

    return () => {
      scrollerElem?.removeEventListener('scroll', positionMenuButton);
      window.removeEventListener('resize', positionMenuButton);
    };
  }, [menuButtonRef, tableCellNode, editor, anchorElem]);

  const prevTableCellDOM = useRef(tableCellNode);

  useEffect(() => {
    if (prevTableCellDOM.current !== tableCellNode) {
      setIsMenuOpen(false);
    }

    prevTableCellDOM.current = tableCellNode;
  }, [prevTableCellDOM, tableCellNode]);

  return (
    <StyledContainer ref={menuButtonRef}>
      {tableCellNode != null && (
        <>
          <StyledButton
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setIsMenuOpen(!isMenuOpen);
            }}
            ref={menuRootRef}
          >
            <Icon size="xs" symbol="expand_more" />
          </StyledButton>

          {isMenuOpen && (
            <TableCellActionMenuDropdown
              contextRef={menuRootRef}
              setIsMenuOpen={setIsMenuOpen}
              onClose={() => setIsMenuOpen(false)}
              tableCellNode={tableCellNode}
            />
          )}
        </>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  willChange: 'transform',
});

const StyledButton = styled('button')({
  backgroundColor: '#eee',
  border: 0,
  position: 'relative',
  borderRadius: '15px',
  color: '#222',
  display: 'inline-block',
  cursor: 'pointer',
});
