import {
  styled,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BlogsEmptyState,
  BlogsTableActions,
  Link,
  Pagination,
} from '@/admin/components';
import { ADD_PATH, BLOGS_PER_PAGE, LAST_COLUMN_WIDTH } from '@/admin/consts';
import { BlogsContext } from '@/admin/providers';
import { IBlogsPageColumn } from '@/admin/types/common';

type BlogsTableProps = {
  isNoBlogs: boolean;
  isNoBlogsByFilters: boolean;
  getBlogs: () => void;
  handleChangePage: (e: unknown, newPage: number) => void;
};

const columns: IBlogsPageColumn[] = [
  { id: 'blogName', label: 'title' },
  { id: 'dateUpdated', label: 'lastModified' },
  { id: 'status', label: 'status' },
  { id: 'actions', label: 'actions' },
];

export const BlogsTable = ({
  isNoBlogs,
  isNoBlogsByFilters,
  getBlogs,
  handleChangePage,
}: BlogsTableProps) => {
  const { t } = useTranslation();
  const { blogsList, currentPage, totalCount } = useContext(BlogsContext);

  const blogs = blogsList[currentPage];
  const pagesCount = Math.ceil(totalCount / BLOGS_PER_PAGE);

  return (
    <>
      <TableContainer>
        <MuiTable>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCellHead key={column.id} align={column.align}>
                  {t(column.label)}
                </StyledTableCellHead>
              ))}
            </TableRow>
          </TableHead>

          {isNoBlogs && (
            <BlogsEmptyState
              linkText={t('newBlog')}
              linkTo={ADD_PATH}
              message={t('noBlogsToReviewMessage')}
              title={t('noBlogsToReview')}
            />
          )}

          {isNoBlogsByFilters && (
            <BlogsEmptyState
              message={t('noBlogsFoundMessage')}
              title={t('noBlogsFound')}
            />
          )}

          {!isNoBlogs && !isNoBlogsByFilters && (
            <TableBody>
              {blogs.map((blog, i) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    if (column.id === 'blogName') {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <StyledLinkStyler>
                            <Link to={blog.id}>{blog[column.id]}</Link>
                          </StyledLinkStyler>
                        </TableCell>
                      );
                    }

                    if (column.id === 'status') {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <span>{t(blog.status)}</span>
                        </TableCell>
                      );
                    }

                    if (column.id === 'actions') {
                      return (
                        <StyledTableCell key={column.id} align={column.align}>
                          <BlogsTableActions blog={blog} getBlogs={getBlogs} />
                        </StyledTableCell>
                      );
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        <span>{blog[column.id] as string}</span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          )}
        </MuiTable>
      </TableContainer>

      {pagesCount > 1 && (
        <StyledPagination>
          <Pagination
            page={currentPage}
            count={pagesCount}
            handleChangePage={handleChangePage}
          />
        </StyledPagination>
      )}
    </>
  );
};

const StyledLinkStyler = styled('div')({
  '& > *': {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
  },
});

const StyledTableCellHead = styled(TableCell)({
  width: `expression( 100% - ${LAST_COLUMN_WIDTH}px) / 3)`, // 4 = columns.length - 1

  '&:last-child': {
    width: `${LAST_COLUMN_WIDTH}px`,
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 2, 1, 1),
}));

const StyledPagination = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
  paddingTop: theme.spacing(2),
}));
