import { Dashboard } from '@/admin/components';
import { BlogsProvider, TestimonialsProvider } from '@/admin/providers';

export const DashboardPage = () => (
  <TestimonialsProvider>
    <BlogsProvider>
      <Dashboard />
    </BlogsProvider>
  </TestimonialsProvider>
);
