import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents';
import { styled } from '@mui/material';
import { ElementFormatType, NodeKey } from 'lexical';

type VideoComponentProps = Readonly<{
  className: Readonly<{
    base: string;
    focus: string;
  }>;
  format: ElementFormatType | null;
  nodeKey: NodeKey;
  videoURL: string;
}>;

export const VideoComponent = ({
  className,
  format,
  nodeKey,
  videoURL,
}: VideoComponentProps) => (
  <BlockWithAlignableContents
    className={className}
    format={format}
    nodeKey={nodeKey}
  >
    <StyledIframe
      width="560"
      height="315"
      src={videoURL}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen={true}
      title="Embed video"
    />
  </BlockWithAlignableContents>
);

const StyledIframe = styled('iframe')({ border: 'none' });
