import { Button, Grid, Paper, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type SettingsSectionProps = {
  children: ReactNode;
  title: string;
  handleUpdate: () => void;
  disabled?: boolean;
  isButtonHidden?: boolean;
};

export const SettingsSection = ({
  children,
  disabled,
  isButtonHidden,
  title,
  handleUpdate,
}: SettingsSectionProps) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={4}>
      <StyledPaper>
        <StyledTypography variant="h3">{title}</StyledTypography>
        <div>{children}</div>
        {!isButtonHidden && (
          <StyledButton
            disabled={disabled}
            variant="outlined"
            onClick={handleUpdate}
          >
            {t('update')}
          </StyledButton>
        )}
      </StyledPaper>
    </Grid>
  );
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 3, 4),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));
