import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalTextEntity } from '@lexical/react/useLexicalTextEntity';
import {
  TextNode,
  createCommand,
  $getSelection,
  COMMAND_PRIORITY_EDITOR,
  $isRangeSelection,
} from 'lexical';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MergeTagNode,
  $createMergeTagNode,
} from '@/admin/components/privacyPolicyEditor/editorNodes';
import { SelectType } from '@/admin/types/common';

const getMergeTagRegexStringChars = () => {
  const alphanumericChars =
    '\u0041-\u005A\u0061-\u007A\u00C0-\u00FF\u0020\u0027'; // English, French characters, spaces and apostrophes

  return {
    alphanumericChars,
  };
};

const getMergeTagRegexString = () => {
  const { alphanumericChars } = getMergeTagRegexStringChars();
  const mergeTag = `{{\\s*([${alphanumericChars}]+)\\s*}}`;
  return mergeTag;
};

const REGEX = new RegExp(getMergeTagRegexString(), 'i');

export const INSERT_MERGE_TAG_COMMAND = createCommand(
  'INSERT_MERGE_TAG_COMMAND'
);

export const MergeTagPlugin = () => {
  const { t } = useTranslation();

  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([MergeTagNode])) {
      throw new Error('MergeTagPlugin: MergeTagNode not registered on editor');
    }
  }, [editor]);

  useEffect(() => {
    return editor.registerCommand(
      INSERT_MERGE_TAG_COMMAND,
      (type: SelectType) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }

        const focusNode = selection?.focus.getNode();

        if (focusNode !== null) {
          const mergeTagNode = $createMergeTagNode(`{{${t(type)}}} `);
          selection.insertNodes([mergeTagNode]);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor, t]);

  const createMergeTagNode = useCallback((textNode: TextNode) => {
    return $createMergeTagNode(textNode.getTextContent());
  }, []);

  const getMergeTagMatch = useCallback((text: string) => {
    const matchArr = REGEX.exec(text);

    if (matchArr === null) {
      return null;
    }

    const mergeTagLength = matchArr[0].length;
    const startOffset = matchArr.index;
    const endOffset = startOffset + mergeTagLength;

    return {
      end: endOffset,
      start: startOffset,
    };
  }, []);

  useLexicalTextEntity(getMergeTagMatch, MergeTagNode, createMergeTagNode);
  return null;
};
