import { Grid, styled, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DashboardBlogs,
  DashboardEmptyState,
  DashboardSection,
  DashboardTestimonials,
  DashBlogsSkeleton,
  DashTestimonialsSkeleton,
  DashboardTestimonialsSummary,
  Skeleton,
  Error,
  Toolbar,
  Testimonial,
} from '@/admin/components';
import { DEFAULT_BLOGS_FILTERS } from '@/admin/consts/blogs';
import { ADD_PATH, BLOGS_PATH, TESTIMONIALS_PATH } from '@/admin/consts/paths';
import {
  DEFAULT_TESTIMONIAL_FILTERS,
  DEFAULT_TESTIMONIAL_SUMMARY,
  DEFAULT_TESTIMONIAL,
} from '@/admin/consts/testimonials';
import {
  AppContext,
  BlogsContext,
  TestimonialsContext,
} from '@/admin/providers';
import { IDashboardBlogsColumn } from '@/admin/types/common';
import { getBrand, getLocalizedDate } from '@/admin/utils/helpers';
import {
  getBlogsApi,
  getSummaryApi,
  getTestimonialsApi,
} from '@/admin/utils/helpers-api';
import {
  IBlogData,
  ITestimonialData,
  ITestimonialSummary,
  TestimonialSource,
} from '@/common/types';

const DEFAULT_BLOGS_NUMBER = 5;

const publishedBlogsColumns: IDashboardBlogsColumn[] = [
  { id: 'blogName', label: 'title' },
  { id: 'dateUpdated', label: 'datePublished' },
];

const modifiedBlogsColumns: IDashboardBlogsColumn[] = [
  { id: 'blogName', label: 'title' },
  { id: 'dateUpdated', label: 'lastModified' },
];

export const Dashboard = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    displayedUserInfo: { userType, blogAccess },
    isAdmin,
    selectedUser,
    selectedUserId,
    userInfo: { networkId, role },
    getAccessToken,
  } = useContext(AppContext);
  const { storeBlogsFilters } = useContext(BlogsContext);
  const { changeTestimonials, getTestimonials, storeTestimonialFilters } =
    useContext(TestimonialsContext);

  // Blog States
  const [blogsLoading, setBlogsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  // data
  const [modifiedBlogs, setModifiedBlogs] = useState<IBlogData[]>([]);
  const [publishedBlogs, setPublishedBlogs] = useState<IBlogData[]>([]);

  const isPublishedBlogs = Boolean(publishedBlogs.length);
  const isModifiedBlogs = Boolean(modifiedBlogs.length);

  // Testimonial States
  const [testimonialsLoading, setTestimonialsLoading] = useState<boolean>(true);
  const [testimonialsSummary, setTestimonialsSummary] = useState<
    Record<TestimonialSource, ITestimonialSummary>
  >(DEFAULT_TESTIMONIAL_SUMMARY);
  const [currentTestimonial, setCurrentTestimonial] =
    useState<ITestimonialData>(DEFAULT_TESTIMONIAL);
  const [isTestimonialExpanded, setIsTestimonialExpanded] =
    useState<boolean>(false);

  const modifiedTestimonials = getTestimonials();
  const publishedTestimonials = getTestimonials({ status: 'published' });
  const isModifiedTestimonials = Boolean(modifiedTestimonials.length);
  const isPublishedTestimonials = Boolean(publishedTestimonials.length);

  const brand = getBrand(networkId);
  const hasSummary = userType !== 'network';

  // fetch blogs
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    if (
      (isAdmin && selectedUser.name && blogAccess) ||
      (!isAdmin && blogAccess)
    ) {
      (async () => {
        try {
          const token = await getAccessToken();

          // fetch modified blogs
          const { blogs: modifiedBlogs } = await getBlogsApi(
            {
              filters: {
                userId: selectedUserId,
                userType: selectedUser.userType,
              },
              limit: DEFAULT_BLOGS_NUMBER,
              token,
            },
            signal
          );

          // localize a blog's date
          modifiedBlogs.forEach((blog) => {
            blog.dateUpdated = getLocalizedDate(language, blog.dateUpdated);
          });

          // fetch published blogs
          const { blogs: publishedBlogs } = await getBlogsApi(
            {
              filters: {
                status: 'published',
                userId: selectedUserId,
                userType: selectedUser.userType,
              },

              limit: DEFAULT_BLOGS_NUMBER,
              token,
            },
            signal
          );

          // localize a blog's date
          publishedBlogs.forEach((blog) => {
            blog.dateUpdated = getLocalizedDate(language, blog.dateUpdated);
          });

          if (signal.aborted) return;

          setModifiedBlogs(modifiedBlogs);
          setPublishedBlogs(publishedBlogs);
          setBlogsLoading(false);
        } catch (error) {
          if (error instanceof window.Error && error.name === 'AbortError') {
            // eslint-disable-next-line no-console
            console.log('Fetch blogs aborted');
          } else {
            setIsError(true);
            setBlogsLoading(false);
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }
      })();
    }

    return () => {
      abortController.abort();
    };
  }, [selectedUserId, blogAccess]);

  const handleSetDefaultPublishedBlogsFilters = () => {
    storeBlogsFilters({ ...DEFAULT_BLOGS_FILTERS, status: 'published' });
  };

  const handleSetDefaultModifiedBlogsFilters = () => {
    storeBlogsFilters(DEFAULT_BLOGS_FILTERS);
  };

  // fetch testimonials
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    if ((isAdmin && selectedUser.name) || !isAdmin) {
      (async () => {
        try {
          setTestimonialsLoading(true);

          const token = await getAccessToken();

          // fetch all testimonials
          const { resource, total } = await getTestimonialsApi(
            {
              filters: {
                ...DEFAULT_TESTIMONIAL_FILTERS,
                userType: selectedUser.userType || 'broker',
                userId: selectedUserId,
              },
              token,
            },
            signal
          );

          if (signal.aborted) return;

          if (hasSummary) {
            const data = await getSummaryApi(
              token,
              selectedUserId,
              selectedUser.userType
            );
            setTestimonialsSummary(data);
          }

          changeTestimonials(resource, total);
          setTestimonialsLoading(false);
        } catch (error) {
          if (error instanceof window.Error && error.name === 'AbortError') {
            // eslint-disable-next-line no-console
            console.log('Fetch testimonials aborted');
          } else {
            setTestimonialsLoading(false);
            setIsError(true);
            // eslint-disable-next-line no-console
            console.error('error', error);
          }
        }
      })();
    }

    return () => {
      abortController.abort();
    };
  }, [selectedUserId]);

  const handleSetPublishedTestimonialsFilters = () => {
    storeTestimonialFilters({
      ...DEFAULT_TESTIMONIAL_FILTERS,
      status: 'published',
    });
  };

  const handleSetModifiedTestimonialsFilters = () => {
    storeTestimonialFilters(DEFAULT_TESTIMONIAL_FILTERS);
  };

  const handleSetSourceTestimonialsFilters = (source: TestimonialSource) => {
    storeTestimonialFilters({ ...DEFAULT_TESTIMONIAL_FILTERS, source });
  };

  if (isError)
    return (
      <Error
        message={t('errorOccurredMessage')}
        title={t('errorOccurredTitle')}
      />
    );

  return (
    <StyledDashboard>
      {blogsLoading && testimonialsLoading ? (
        <StyledHeader>
          <Skeleton width={201} height={50} />
        </StyledHeader>
      ) : (
        <Typography variant="h1">{t('dashboard')}</Typography>
      )}

      {blogAccess && (
        <>
          {blogsLoading && <DashBlogsSkeleton />}

          {!blogsLoading && (
            <>
              <StyledToolbar>
                <Toolbar title={t('blogs')} />
              </StyledToolbar>

              <Grid container spacing={3}>
                {/* no any blogs empty state */}
                {!isPublishedBlogs && !isModifiedBlogs && (
                  <DashboardEmptyState
                    symbol="article"
                    isFullWidth
                    linkText={t('newBlog')}
                    linkTo={`${BLOGS_PATH}/${ADD_PATH}`}
                    message={t('noBlogsToReviewMessage')}
                    title={t('noBlogsToDisplay')}
                  />
                )}

                {/* only drafts empty state */}
                {!isPublishedBlogs && isModifiedBlogs && (
                  <DashboardEmptyState
                    symbol="article"
                    message={t('noPublishedBlogsMessage')}
                    title={t('noPublishedBlogsTitle')}
                  />
                )}

                {isPublishedBlogs && (
                  <DashboardSection
                    link={BLOGS_PATH}
                    title={t('recentlyPublished')}
                    onLinkClick={handleSetDefaultPublishedBlogsFilters}
                  >
                    <DashboardBlogs
                      columns={publishedBlogsColumns}
                      blogs={publishedBlogs}
                    />
                  </DashboardSection>
                )}

                {isModifiedBlogs && (
                  <DashboardSection
                    link={BLOGS_PATH}
                    title={t('recentlyModified')}
                    onLinkClick={handleSetDefaultModifiedBlogsFilters}
                  >
                    <DashboardBlogs
                      columns={modifiedBlogsColumns}
                      blogs={modifiedBlogs}
                    />
                  </DashboardSection>
                )}
              </Grid>
            </>
          )}
        </>
      )}

      {testimonialsLoading ? (
        <DashTestimonialsSkeleton />
      ) : (
        <>
          <StyledToolbar>
            <Toolbar title={t('testimonials')} />
          </StyledToolbar>

          {!isTestimonialExpanded ? (
            <Grid container spacing={3}>
              {/* no testimonials */}
              {!isPublishedTestimonials && !isModifiedTestimonials && (
                <DashboardEmptyState
                  symbol="format_quote"
                  isFullWidth
                  message={t('noTestimonialsToReviewMessage')}
                  title={t('noTestimonialsToDisplay')}
                />
              )}

              {/* only draft testimonials */}
              {!isPublishedTestimonials &&
                !hasSummary &&
                isModifiedTestimonials && (
                  <DashboardEmptyState
                    symbol="format_quote"
                    message={t('noPublishedTestimonialsMessage')}
                    title={t('noPublishedTestimonialsTitle')}
                  />
                )}

              {/* published testimonials present OR dashboard summary */}
              {hasSummary && testimonialsSummary && isModifiedTestimonials ? (
                <DashboardSection title={t('ratingsSummary')}>
                  <DashboardTestimonialsSummary
                    brand={brand}
                    data={testimonialsSummary}
                    link={TESTIMONIALS_PATH}
                    onLinkClick={handleSetSourceTestimonialsFilters}
                  />
                </DashboardSection>
              ) : (
                isPublishedTestimonials &&
                role === 'broker' && (
                  <DashboardSection
                    link={TESTIMONIALS_PATH}
                    title={t('recentlyPublished')}
                    onLinkClick={handleSetPublishedTestimonialsFilters}
                  >
                    <DashboardTestimonials
                      setCurrentTestimonial={setCurrentTestimonial}
                      testimonials={publishedTestimonials}
                      setIsTestimonialExpanded={setIsTestimonialExpanded}
                      isTestimonialExpanded={isTestimonialExpanded}
                    />
                  </DashboardSection>
                )
              )}

              {isModifiedTestimonials && (
                <DashboardSection
                  link={TESTIMONIALS_PATH}
                  title={t('latestTestimonials')}
                  onLinkClick={handleSetModifiedTestimonialsFilters}
                >
                  <DashboardTestimonials
                    setCurrentTestimonial={setCurrentTestimonial}
                    testimonials={modifiedTestimonials}
                    setIsTestimonialExpanded={setIsTestimonialExpanded}
                    isTestimonialExpanded={isTestimonialExpanded}
                  />
                </DashboardSection>
              )}
            </Grid>
          ) : (
            <Testimonial
              id={currentTestimonial.id}
              authorName={
                currentTestimonial.reviewName ||
                `${currentTestimonial.reviewFirstName} ${currentTestimonial.reviewLastName}`
              }
              content={currentTestimonial.reviewContent}
              date={currentTestimonial.datePublished}
              networkId={currentTestimonial.networkId}
              rating={currentTestimonial.reviewRating}
              source={currentTestimonial.source}
              status={currentTestimonial.status}
              setIsTestimonialExpanded={setIsTestimonialExpanded}
              isTestimonialExpanded={isTestimonialExpanded}
              reviewLikeRating={currentTestimonial.reviewLikeRating}
              page="dashboard"
            />
          )}
        </>
      )}
    </StyledDashboard>
  );
};

const StyledDashboard = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 8, 5, 6),
}));

const StyledToolbar = styled('div')(({ theme }) => ({
  margin: theme.spacing(5, 0, 3),
}));

const StyledHeader = styled('div')(({ theme }) => ({
  margin: theme.spacing(0),
}));
