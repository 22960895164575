import { Card, Grid, styled } from '@mui/material';

import { EmptyState } from '@/admin/components';

type DashboardEmptyStateProps = {
  message: string;
  title: string;
  isFullWidth?: boolean;
  linkText?: string;
  linkTo?: string;
  symbol?: string;
};

export const DashboardEmptyState = ({
  isFullWidth,
  linkText,
  linkTo,
  message,
  title,
  symbol,
}: DashboardEmptyStateProps) => (
  <Grid item xs={isFullWidth ? 12 : 6}>
    <StyledCard variant="outlined">
      <EmptyState
        icon={symbol}
        linkText={linkText}
        linkTo={linkTo}
        message={message}
        title={title}
      />
    </StyledCard>
  </Grid>
);

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '10px',
  height: '100%',
  padding: theme.spacing(2),
}));
