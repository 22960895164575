import { AutoEmbedOption } from '@lexical/react/LexicalAutoEmbedPlugin';
import { styled } from '@mui/material';

type AutoEmbedMenuProps = {
  selectedItemIndex: number | null;
  onOptionClick: (option: AutoEmbedOption, index: number) => void;
  onOptionMouseEnter: (index: number) => void;
  options: Array<AutoEmbedOption>;
};

export const AutoEmbedMenu = ({
  options,
  selectedItemIndex,
  onOptionClick,
  onOptionMouseEnter,
}: AutoEmbedMenuProps) => {
  return (
    <StyledUL>
      {options.map((option: AutoEmbedOption, i: number) => (
        <StyledList
          key={option.key}
          tabIndex={-1}
          ref={option.setRefElement}
          role="option"
          aria-selected={selectedItemIndex === i}
          onMouseEnter={() => onOptionMouseEnter(i)}
          onClick={() => onOptionClick(option, i)}
        >
          <StyledText>{option.title}</StyledText>
        </StyledList>
      ))}
    </StyledUL>
  );
};

const StyledUL = styled('ul')({
  padding: 0,
  listStyle: 'none',
  margin: 0,
  borderRadius: '8px',
  maxHeight: '200px',
});

const StyledList = styled('li')({
  margin: 0,
  fontSize: '14px',
  outline: 'none',
  padding: '8px',
  color: '#050505',
  cursor: 'pointer',
  lineHeight: '16px',
  display: 'flex',
  alignContent: 'center',
  flexDirection: 'row',
  flexShrink: 0,
  backgroundColor: '#fff',
  borderRadius: '8px',
  border: 0,

  '&:hover': {
    backgroundColor: '#eee',
  },

  '&:first-of-type': {
    borderRadius: '8px 8px 0px 0px',
  },

  '&:last-of-type': {
    borderRadius: '0px 0px 8px 8px',
  },
});

const StyledText = styled('span')({
  display: 'flex',
  lineHeight: '20px',
  flexGrow: 1,
  minWidth: '150px',
});
