import { Stack, styled } from '@mui/material';

import { Skeleton } from '@/admin/components/skeleton';

export const DashBlogsSkeleton = () => {
  return (
    <>
      <StyledHeader>
        <Skeleton height={40} />
      </StyledHeader>

      <Stack direction="row">
        <StyledColumn>
          <Skeleton height={300} />
        </StyledColumn>
        <StyledColumn>
          <Skeleton height={300} />
        </StyledColumn>
      </Stack>
    </>
  );
};

export const DashTestimonialsSkeleton = () => {
  return (
    <>
      <StyledHeader>
        <Skeleton height={40} />
      </StyledHeader>

      <Stack direction="row">
        <StyledColumn>
          <Skeleton height={300} />
        </StyledColumn>
        <StyledColumn>
          <Skeleton height={300} />
        </StyledColumn>
      </Stack>
    </>
  );
};

const StyledHeader = styled('div')(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

const StyledColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});
