import { styled } from '@mui/material';
import type { LexicalEditor } from 'lexical';
import * as React from 'react';
import { useRef, useEffect } from 'react';

import { DIRECTION } from '@/admin/consts/editor';
import { clamp } from '@/admin/utils/helpers-editor';

type ImageResizerProps = {
  editor: LexicalEditor;
  imageRef: { current: null | HTMLElement };
  maxWidth?: number;
  onResizeEnd: (width: 'inherit' | number, height: 'inherit' | number) => void;
  onResizeStart: () => void;
};

export const ImageResizer = ({
  onResizeStart,
  onResizeEnd,
  imageRef,
  maxWidth,
  editor,
}: ImageResizerProps) => {
  const controlWrapperRef = useRef<HTMLDivElement>(null);
  const userSelect = useRef({
    priority: '',
    value: 'default',
  });
  const positioningRef = useRef<{
    currentHeight: 'inherit' | number;
    currentWidth: 'inherit' | number;
    direction: number;
    isResizing: boolean;
    ratio: number;
    startHeight: number;
    startWidth: number;
    startX: number;
    startY: number;
  }>({
    currentHeight: 0,
    currentWidth: 0,
    direction: 0,
    isResizing: false,
    ratio: 0,
    startHeight: 0,
    startWidth: 0,
    startX: 0,
    startY: 0,
  });

  const editorRootElement = editor.getRootElement();
  // Find max width, accounting for editor padding.
  const maxWidthContainer = maxWidth
    ? maxWidth
    : editorRootElement !== null
    ? editorRootElement.getBoundingClientRect().width - 20
    : 100;
  const maxHeightContainer =
    editorRootElement !== null
      ? editorRootElement.getBoundingClientRect().height - 20
      : 100;

  const minWidth = 100;
  const minHeight = 100;

  useEffect(() => {
    // if isResizing is still active, when component unmounts
    return () => {
      const position = positioningRef.current;
      if (position.isResizing) {
        setEndCursor();

        const image = imageRef.current;
        if (image) {
          const { width, height } = image.getBoundingClientRect();
          onResizeEnd(width, height);
        } else {
          onResizeEnd('inherit', 'inherit');
        }

        document.removeEventListener('pointermove', handlePointerMove);
        document.removeEventListener('pointerup', handlePointerUp);
      }
    };
  }, []);

  const setStartCursor = (direction: number) => {
    const ew = direction === DIRECTION.east || direction === DIRECTION.west;
    const ns = direction === DIRECTION.north || direction === DIRECTION.south;
    const nwse =
      (direction & DIRECTION.north && direction & DIRECTION.west) ||
      (direction & DIRECTION.south && direction & DIRECTION.east);

    const cursorDir = ew ? 'ew' : ns ? 'ns' : nwse ? 'nwse' : 'nesw';

    if (editorRootElement !== null) {
      editorRootElement.style.setProperty(
        'cursor',
        `${cursorDir}-resize`,
        'important'
      );
    }
    if (document.body !== null) {
      document.body.style.setProperty(
        'cursor',
        `${cursorDir}-resize`,
        'important'
      );
      userSelect.current.value = document.body.style.getPropertyValue(
        '-webkit-user-select'
      );
      userSelect.current.priority = document.body.style.getPropertyPriority(
        '-webkit-user-select'
      );
      document.body.style.setProperty(
        '-webkit-user-select',
        'none',
        'important'
      );
    }
  };

  const setEndCursor = () => {
    if (editorRootElement !== null) {
      editorRootElement.style.setProperty('cursor', 'default');
    }
    if (document.body !== null) {
      document.body.style.setProperty('cursor', 'default');
      document.body.style.setProperty(
        '-webkit-user-select',
        userSelect.current.value,
        userSelect.current.priority
      );
    }
  };

  const handlePointerDown = (
    event: React.PointerEvent<HTMLDivElement>,
    direction: number
  ) => {
    if (!editor.isEditable()) {
      return;
    }

    const image = imageRef.current;
    const controlWrapper = controlWrapperRef.current;

    if (image !== null && controlWrapper !== null) {
      const { width, height } = image.getBoundingClientRect();
      const positioning = positioningRef.current;
      positioning.startWidth = width;
      positioning.startHeight = height;
      positioning.ratio = width / height;
      positioning.currentWidth = width;
      positioning.currentHeight = height;
      positioning.startX = event.clientX;
      positioning.startY = event.clientY;
      positioning.isResizing = true;
      positioning.direction = direction;

      setStartCursor(direction);
      onResizeStart();

      controlWrapper.classList.add('image-control-wrapper--resizing');
      image.style.height = `${height}px`;
      image.style.width = `${width}px`;

      document.addEventListener('pointermove', handlePointerMove);
      document.addEventListener('pointerup', handlePointerUp);
    }
  };

  const handlePointerMove = (event: PointerEvent) => {
    const image = imageRef.current;
    const positioning = positioningRef.current;

    const isHorizontal =
      positioning.direction & (DIRECTION.east | DIRECTION.west);
    const isVertical =
      positioning.direction & (DIRECTION.south | DIRECTION.north);

    if (image !== null && positioning.isResizing) {
      // Corner cursor
      if (isHorizontal && isVertical) {
        let diff = Math.floor(positioning.startX - event.clientX);
        diff = positioning.direction & DIRECTION.east ? -diff : diff;

        const width = clamp(
          positioning.startWidth + diff,
          minWidth,
          maxWidthContainer
        );

        const height = width / positioning.ratio;
        image.style.width = `${width}px`;
        image.style.height = `${height}px`;
        positioning.currentHeight = height;
        positioning.currentWidth = width;
      } else if (isVertical) {
        let diff = Math.floor(positioning.startY - event.clientY);
        diff = positioning.direction & DIRECTION.south ? -diff : diff;

        const height = clamp(
          positioning.startHeight + diff,
          minHeight,
          maxHeightContainer
        );

        image.style.height = `${height}px`;
        positioning.currentHeight = height;
      } else {
        let diff = Math.floor(positioning.startX - event.clientX);
        diff = positioning.direction & DIRECTION.east ? -diff : diff;

        const width = clamp(
          positioning.startWidth + diff,
          minWidth,
          maxWidthContainer
        );

        image.style.width = `${width}px`;
        positioning.currentWidth = width;
      }
    }
  };

  const handlePointerUp = () => {
    const image = imageRef.current;
    const positioning = positioningRef.current;
    const controlWrapper = controlWrapperRef.current;
    if (image !== null && controlWrapper !== null && positioning.isResizing) {
      const width = positioning.currentWidth;
      const height = positioning.currentHeight;
      positioning.startWidth = 0;
      positioning.startHeight = 0;
      positioning.ratio = 0;
      positioning.startX = 0;
      positioning.startY = 0;
      positioning.currentWidth = 0;
      positioning.currentHeight = 0;
      positioning.isResizing = false;

      controlWrapper.classList.remove('image-control-wrapper--resizing');

      setEndCursor();
      onResizeEnd(width, height);

      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);
    }
  };
  return (
    <div ref={controlWrapperRef}>
      <StyledImageResizerN
        onPointerDown={(event) => {
          handlePointerDown(event, DIRECTION.north);
        }}
      />
      <StyledImageResizerNE
        onPointerDown={(event) => {
          handlePointerDown(event, DIRECTION.north | DIRECTION.east);
        }}
      />
      <StyledImageResizerE
        onPointerDown={(event) => {
          handlePointerDown(event, DIRECTION.east);
        }}
      />
      <StyledImageResizerSE
        onPointerDown={(event) => {
          handlePointerDown(event, DIRECTION.south | DIRECTION.east);
        }}
      />
      <StyledImageResizerS
        onPointerDown={(event) => {
          handlePointerDown(event, DIRECTION.south);
        }}
      />
      <StyledImageResizerSW
        onPointerDown={(event) => {
          handlePointerDown(event, DIRECTION.south | DIRECTION.west);
        }}
      />
      <StyledImageResizerW
        onPointerDown={(event) => {
          handlePointerDown(event, DIRECTION.west);
        }}
      />
      <StyledImageResizerNW
        onPointerDown={(event) => {
          handlePointerDown(event, DIRECTION.north | DIRECTION.west);
        }}
      />
    </div>
  );
};

const StyledImageResizer = styled('div')({
  display: 'block',
  width: '7px',
  height: '7px',
  position: 'absolute',
  backgroundColor: 'rgb(60, 132, 244)',
  border: '1px solid #fff',
});

const StyledImageResizerN = styled(StyledImageResizer)({
  top: '-6px',
  left: '48%',
  cursor: 'n-resize',
});

const StyledImageResizerNE = styled(StyledImageResizer)({
  top: '-6px',
  right: '-6px',
  cursor: 'ne-resize',
});

const StyledImageResizerE = styled(StyledImageResizer)({
  bottom: '48%',
  right: '-6px',
  cursor: 'e-resize',
});

const StyledImageResizerSE = styled(StyledImageResizer)({
  bottom: '-2px',
  right: '-6px',
  cursor: 'nwse-resize',
});

const StyledImageResizerS = styled(StyledImageResizer)({
  bottom: '-2px',
  left: '48%',
  cursor: 's-resize',
});

const StyledImageResizerSW = styled(StyledImageResizer)({
  bottom: '-2px',
  left: '-6px',
  cursor: 'sw-resize',
});

const StyledImageResizerW = styled(StyledImageResizer)({
  top: '48%',
  left: '-6px',
  cursor: 'w-resize',
});

const StyledImageResizerNW = styled(StyledImageResizer)({
  top: '-6px',
  left: '-6px',
  cursor: 'nw-resize',
});
