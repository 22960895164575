import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogProps } from '@/admin/components';
import { IDialogArgs } from '@/admin/types/common';

export const useModal = () => {
  const { t } = useTranslation();

  const handleModalClose = () => {
    setModalContent(defaultModalContent);
  };

  const defaultModalContent = {
    buttonAgree: t('confirm'),
    buttonClose: t('cancel'),
    message: '',
    open: false,
    title: '',
    handleAgree: () => {},
    handleClose: handleModalClose,
  };

  const [modalContent, setModalContent] =
    useState<DialogProps>(defaultModalContent);

  const showModal = (dialogArgs: IDialogArgs, isOneButton?: boolean) => {
    setModalContent((prev) => {
      if (isOneButton) {
        return {
          ...prev,
          open: true,
          buttonAgree: t('okay'),
          buttonClose: undefined,
          handleClose: undefined,
          ...dialogArgs,
        };
      }
      return { ...prev, open: true, ...dialogArgs };
    });
  };

  return { modalContent, showModal, handleModalClose };
};
