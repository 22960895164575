import {
  IUser,
  ITestimonialData,
  ITestimonialsFilters,
  ITestimonialSummary,
  TestimonialSource,
} from '@/common/types';

export const DEFAULT_RECIPIENT: IUser = {
  name: '',
  nameFr: '',
  networkId: 0,
  originalNetworkId: 0,
  userType: 'broker',
};

export const DEFAULT_TESTIMONIAL_FILTERS: ITestimonialsFilters = {
  ...DEFAULT_RECIPIENT,
  language: '',
  source: '',
  status: '',
  reviewContent: '',
  userId: 0,
};

export const DEFAULT_TESTIMONIAL: ITestimonialData = {
  authorName: '',
  userType: '',
  brand: '',
  dateCreated: 0,
  datePublished: 0,
  dateUpdated: 0,
  userId: 0,
  id: '',
  language: 'en',
  networkId: 0,
  reviewRating: 1,
  reviewContent: '',
  source: 'website',
  status: '',
};

export const DEFAULT_TESTIMONIAL_SUMMARY: Record<
  TestimonialSource,
  ITestimonialSummary
> = {
  website: {
    total: 0,
    averageRating: 0,
  },
  google: {
    total: 0,
    averageRating: 0,
  },
  facebook: {
    total: 0,
    averageRating: 0,
  },
};

export const TESTIMONIALS_PER_PAGE = 10;

export const CHAR_LIMIT = 350;
