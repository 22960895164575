import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from '@/admin/components';
import {
  ANNOUNCEMENTS_PATH,
  BLOGS_PATH,
  SESSION_TIME_DETECT_END,
  SESSION_TIME_DETECT_START,
  userActivityEvents,
  defaultSessionTimeoutModal,
  PRIVACY_POLICIES_PATH,
} from '@/admin/consts';
import { useModal } from '@/admin/hooks';
import { AppContext } from '@/admin/providers';
import { IDialogArgs } from '@/admin/types/common';

export const AppSessionTime = () => {
  const { t } = useTranslation();

  const {
    openSessionTimeoutModal,
    getAccessToken,
    handleLogout,
    setIsTokenExpired,
    setOpenSessionTimeoutModal,
  } = useContext(AppContext);

  const { modalContent, showModal, handleModalClose } = useModal();

  const [isUserActive, setIsUserActive] = useState(false);
  const [isTimeToDetectUserActive, setIsTimeToDetectUserActive] =
    useState(false);
  const [isTimeToUpdateToken, setIsTimeToUpdateToken] = useState(false);
  const [isTokenUpdated, setIsTokenUpdated] = useState(true);

  // check if elapsed 50 minutes after access token issued
  useEffect(() => {
    let updateTime: ReturnType<typeof setTimeout>;

    if (isTokenUpdated) {
      updateTime = setTimeout(() => {
        setIsTimeToDetectUserActive(true);
      }, SESSION_TIME_DETECT_START); // 50 minutes
    }

    return () => {
      clearTimeout(updateTime);
    };
  }, [isTokenUpdated]);

  // detect user activity after 50 minutes elapsed
  useEffect(() => {
    let updateTime: ReturnType<typeof setTimeout>;

    if (isTimeToDetectUserActive) {
      setIsTokenUpdated(false);

      userActivityEvents.forEach((event) => {
        document.addEventListener(event, handleActiveUser);
      });

      updateTime = setTimeout(() => {
        setIsTimeToUpdateToken(true);
      }, SESSION_TIME_DETECT_END); // 5 minutes
    }

    return () => {
      userActivityEvents.forEach((event) => {
        document.removeEventListener(event, handleActiveUser);
      });
      clearTimeout(updateTime);
    };
  }, [isTimeToDetectUserActive]);

  // refresh token if user is active or log out if user is not active
  useEffect(() => {
    (async () => {
      if (isTimeToUpdateToken || isUserActive) {
        if (isUserActive) {
          await handleUpdateToken();
        } else {
          setIsTokenExpired(true);

          const pathName = window.location.pathname;

          // session timeout modal except add/edit blogs, policies and announces
          if (
            !pathName.includes(`${BLOGS_PATH}/`) &&
            !pathName.includes(ANNOUNCEMENTS_PATH) &&
            !pathName.includes(`${PRIVACY_POLICIES_PATH}/`)
          ) {
            showModal(getModalContent());
          }
        }
        setIsUserActive(false);
        setIsTimeToDetectUserActive(false);
        setIsTimeToUpdateToken(false);

        userActivityEvents.forEach((event) => {
          document.removeEventListener(event, handleActiveUser);
        });
      }
    })();
  }, [isTimeToUpdateToken, isUserActive]);

  // handling session timeout modal for Add/Edit blog, policy and announcements page
  useEffect(() => {
    const { open, path, autoSave, callback } = openSessionTimeoutModal;

    if (open) {
      const modalContent: Partial<IDialogArgs> = {
        handleClose: () => handleAppLogout(callback),
      };

      if (autoSave) {
        const messageObject = {
          blogs: 'article',
          announcements: 'announcements',
          privacypolicies: 'privacyPolicies',
        };

        modalContent.message = t('sessionExpiredAutoSaveDialogMessage').replace(
          '{object}',
          t(messageObject[path || 'blogs']).toLocaleLowerCase()
        );
      }

      showModal(getModalContent(modalContent));

      setOpenSessionTimeoutModal(defaultSessionTimeoutModal);
    }
  }, [openSessionTimeoutModal]);

  const getModalContent = (dialogArgs?: Partial<IDialogArgs>): IDialogArgs => ({
    buttonAgree: t('sessionContinue'),
    buttonClose: t('logOut'),
    message: t('sessionExpiredDialogMessage'),
    title: t('sessionExpiredDialogTitle'),
    handleAgree: handleUpdateToken,
    handleClose: handleLogout,
    ...dialogArgs,
  });

  const handleActiveUser = () => {
    setIsUserActive(true);
  };

  const handleUpdateToken = async () => {
    const newToken = await getAccessToken(true);

    if (newToken) {
      setIsTokenUpdated(true);
      setIsTokenExpired(false);
      handleModalClose();
    }
  };

  const handleAppLogout = (callback: () => void) => {
    callback && callback();
    handleLogout();
  };

  return (
    <Dialog
      buttonAgree={modalContent.buttonAgree}
      buttonClose={modalContent.buttonClose}
      message={modalContent.message}
      open={modalContent.open}
      title={modalContent.title}
      handleAgree={modalContent.handleAgree}
      handleClose={modalContent.handleClose}
    />
  );
};
