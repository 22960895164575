import { FormHelperText, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { BlogEditor } from '@/admin/components/';

export const BlogContentBody = () => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <BlogEditor />

      <StyledFormHelperText error={!!errors.blogContent}>
        {errors.blogContent?.message as string}
      </StyledFormHelperText>
    </>
  );
};

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
