import { Icon } from '@/admin/components';
import { ToolbarButton } from '@/admin/components/blogEditor/editorPlugins';

type ToolbarTableProps = {
  handleShowTableModal: () => void;
};

export const ToolbarTable = ({ handleShowTableModal }: ToolbarTableProps) => {
  return (
    <>
      <ToolbarButton ariaLabel="Insert Table" onClick={handleShowTableModal}>
        <Icon size="sm" symbol="table" />
      </ToolbarButton>
    </>
  );
};
