import { Box, styled } from '@mui/material';

import { StyledGrid, StyledGridContainer } from '@/admin/components/blogPost';
import { StyledAppBar, StyledToolbar } from '@/admin/components/common';
import { Skeleton } from '@/admin/components/skeleton';

export const EditPrivacyPoliciesSkeleton = () => (
  <>
    <StyledContainer>
      <StyledAppBar position="static">
        <StyledToolbar>
          <Skeleton width={181} height={32} />
          <StyledRow>
            <Skeleton width={85} height={40} />
            <Skeleton width={124} height={40} />
          </StyledRow>
        </StyledToolbar>
      </StyledAppBar>
    </StyledContainer>

    <StyledGridContainer container>
      <StyledGrid item xs={12} lg={9}>
        <StyledRow>
          <StyledRow>
            <Skeleton width={169} height={60} />
            <Skeleton width={112} height={60} />
          </StyledRow>
        </StyledRow>

        <Skeleton height={706} />
      </StyledGrid>
    </StyledGridContainer>
  </>
);

const StyledContainer = styled(Box)({ zIndex: 10, minWidth: '800px' });

const StyledRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});
