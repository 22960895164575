import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ReactNode } from 'react';

import { Icon } from '@/admin/components';

type ErrorProps = {
  title: string;
  message?: string;
  children?: ReactNode;
};

export const Error = ({ message, title, children }: ErrorProps) => (
  <StyledError>
    <Icon symbol="error" size="xlg" />

    <StyledTypography variant="h1">{title}</StyledTypography>
    <Typography variant="body1">{message}</Typography>
    {children}
  </StyledError>
);

const StyledError = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'center',
  margin: '0 auto',
  padding: theme.spacing(4),
  textAlign: 'center',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0, 3),
}));
