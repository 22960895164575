import {
  PrivacyPolicyContentBody,
  PrivacyPolicyContentHeader,
} from '@/admin/components/';

export const PrivacyPolicyContent = () => (
  <>
    <PrivacyPolicyContentHeader />
    <PrivacyPolicyContentBody />
  </>
);
