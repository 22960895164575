import { Button, styled } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Error,
  TestimonialsAlert,
  TestimonialsBusinessPicker,
  TestimonialFiltersSkeleton,
  TestimonialHeaderSkeleton,
  TestimonialsSkeleton,
  Pagination,
  Testimonial,
  TestimonialsFilters,
  Dialog,
  Switch,
  TestimonialsEmptyState,
  TestimonialsTop,
  IconSvg,
  DialogProps,
} from '@/admin/components';
import {
  DEFAULT_TESTIMONIAL_FILTERS,
  TESTIMONIALS_PER_PAGE,
} from '@/admin/consts';
import { useModal } from '@/admin/hooks';
import { GoogleIcon } from '@/admin/icons';
import {
  AppContext,
  TestimonialsContext,
  ThemeContext,
} from '@/admin/providers';
import {
  getItemFromLocalStorage,
  getItemFromSessionStorage,
} from '@/admin/utils/helpers';
import {
  checkIfRefreshTokenValid,
  getGoogleBusinessesApi,
  getSelectedBusinessesApi,
  getTestimonialsApi,
  storeSelectedBusiness,
  updateDisplayTestimonialsApi,
  useGoogleLoginApi,
} from '@/admin/utils/helpers-api';
import {
  IGoogleBusinessData,
  ITestimonialsFilters,
  SortingOrderTestimonials,
  TestimonialsFiltersNames,
  TestimonialSource,
} from '@/common/types';

export const Testimonials = () => {
  const { t } = useTranslation();

  const {
    displayedUserInfo,
    selectedUser: { userType },
    selectedUserId,
    userInfo: { employeeId },
    getAccessToken,
    setDisplayedUserInfo,
  } = useContext(AppContext);
  const { displayTestimonials } = displayedUserInfo;
  const {
    currentPage,
    testimonials,
    totalCount,
    changeCurrentPage,
    changeTestimonials,
    getTestimonials,
    resetStoredTestimonials,
  } = useContext(TestimonialsContext);
  const { openSnackbar } = useContext(ThemeContext);

  const { modalContent, showModal, handleModalClose } = useModal();

  // states
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  //visibility states
  const [isTogglePublic, setIsTogglePublic] =
    useState<boolean>(displayTestimonials);
  const [isVisibilitySaved, setIsVisibilitySaved] = useState<boolean>(true);
  // filters
  const [filters, setFilters] = useState<ITestimonialsFilters>(
    (getItemFromSessionStorage(
      'testimonialFilters',
      true
    ) as ITestimonialsFilters) || DEFAULT_TESTIMONIAL_FILTERS
  );
  const [order, setOrder] = useState<SortingOrderTestimonials>('dateDesc');
  const [searchValue, setSearchValue] = useState(filters.reviewContent || '');
  const [googleAlertOpen, setGoogleAlertOpen] = useState<boolean>(true);
  const [googleSignInStatus, setGoogleSignInStatus] = useState<string>('null');
  const [checkboxValue, setCheckboxValue] = useState<IGoogleBusinessData[]>([]);
  const [locationError, setLocationError] = useState<boolean>(false);
  const [googleLocations, setGoogleLocations] = useState<IGoogleBusinessData[]>(
    []
  );
  const [locationsValue, setLocationsValue] = useState<IGoogleBusinessData[]>(
    []
  );
  const [isShowModalLoginButton, setIsShowModalLoginButton] = useState<boolean>(
    googleLocations?.length === 0 ||
      (googleLocations?.length === 1 && locationsValue?.length === 0)
  );

  const [locationModalContent, setLocationModalContent] = useState<
    DialogProps & { handleGoogleClose: () => void }
  >({
    title: '',
    message: '',
    open: false,
    buttonAgree: 'confirm',
    buttonClose: 'close',
    handleAgree: () => handleSaveLocation(selectedUserIdRef.current),
    handleGoogleClose: () => handleCloseLocationModal(),
  });

  const filteredTestimonials = useMemo(
    () => getTestimonials(filters, order),
    [testimonials, currentPage, filters, order]
  );

  const pagesCount = Math.ceil(totalCount / TESTIMONIALS_PER_PAGE);
  const isShowBusinessPicker = !locationError && googleLocations?.length > 1;

  const prefersNoAlert = getItemFromLocalStorage('prefersNoAlert') as
    | boolean
    | null;

  const setModalContent = (
    content: Partial<DialogProps & { handleGoogleClose: () => void }>
  ) => {
    setLocationModalContent((prevState) => ({
      ...prevState,
      ...content,
    }));
  };

  useEffect(() => {
    // Added AbortController for BOSS CMP fingerprints
    const abortController = new AbortController();
    const signal = abortController.signal;

    (async () => {
      try {
        setIsLoading(true);
        const token = await getAccessToken();

        const { resource, total } = await getTestimonialsApi(
          {
            filters: {
              ...filters,
              userId: selectedUserId,
              userType,
            },
            token,
          },
          signal
        );

        if (signal.aborted) return;

        changeTestimonials(resource, total);
        resetStoredTestimonials();
        setIsLoading(false);
        setIsFirstLoad(false);
      } catch (error) {
        if (error instanceof window.Error && error.name === 'AbortError') {
          // eslint-disable-next-line no-console
          console.log('Fetch testimonials aborted');
        } else {
          setIsLoading(false);
          setIsFirstLoad(false);
          setIsError(true);
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [selectedUserId, locationsValue]);

  const checkboxValueRef = useRef(checkboxValue);

  useEffect(() => {
    checkboxValueRef.current = checkboxValue;
  }, [checkboxValue]);

  // use of ref to avoid the problem of closures using stale state values, if selectedUser is changed, but component is not rerendered.
  const selectedUserIdRef = useRef<number>(selectedUserId);

  useEffect(() => {
    selectedUserIdRef.current = selectedUserId;
  }, [selectedUserId]);

  // clear state values when user is changed but component is not rerendered, on BOSS
  useEffect(() => {
    setGoogleAlertOpen(true);
    setCheckboxValue([]);
    setLocationsValue([]);
    setGoogleLocations([]);
    isGoogleTokenValid();
  }, [selectedUserId]);

  const getSelectedBusinesses = async () => {
    const token = await getAccessToken();
    const data = await getSelectedBusinessesApi(
      token,
      selectedUserId,
      userType
    );

    if (data) {
      setLocationsValue(data);
      setCheckboxValue(data);
    }
  };

  const isGoogleTokenValid = async () => {
    const token = await getAccessToken();

    const { status } = await checkIfRefreshTokenValid(
      token,
      selectedUserId,
      userType
    );

    if (status === 200) {
      setGoogleSignInStatus('valid');
      getSelectedBusinesses();
      getGoogleBusinesses();
    } else if (status === 400) {
      setGoogleSignInStatus('expired');
      setModalContent({
        title: 'googleExpired',
        message: 'googleSignInExpired',
      });
    } else if (status === 404) {
      setGoogleSignInStatus('null');
      setModalContent({
        title: 'signInGoogle',
        message: 'googleSignInMessage',
      });
    }
  };

  useEffect(() => {
    setIsShowModalLoginButton(googleLocations?.length === 0);
    if (googleSignInStatus === 'null') {
      setModalContent({
        title: 'signInGoogle',
        message: 'googleSignInMessage',
        buttonAgree: null,
      });
      return;
    }

    if (googleLocations) {
      if (googleLocations.length > 1) {
        setModalContent({
          title: 'selectBusiness',
          message: 'multipleBusinesses',
          buttonAgree: 'confirm',
          buttonClose: 'cancel',
          handleAgree: () => handleSaveLocation(selectedUserIdRef.current),
        });
      } else if (googleLocations.length < 1) {
        setModalContent({
          title: 'noBusinesses',
          message: 'noBusinessesMessage',
          buttonAgree: null,
          buttonClose: 'close',
        });
      } else if (googleLocations.length === 1 && locationsValue.length === 0) {
        setModalContent({
          title: 'confirmBusiness',
          message: 'confirmBusinessMessage',
          buttonAgree: 'confirm',
          buttonClose: 'cancel',
          handleGoogleClose: () => {
            setIsShowModalLoginButton(true);
            handleCloseLocationModal();
            setModalContent({
              title: 'signInGoogle',
              message: 'googleSignInMessage',
              buttonAgree: null,
              handleGoogleClose: () => {
                handleCloseLocationModal();
              },
            });
          },
          handleAgree: () =>
            handleSaveLocation(selectedUserIdRef.current, [googleLocations[0]]),
        });
      } else if (googleLocations.length === 1 && locationsValue.length !== 0) {
        setIsShowModalLoginButton(true);
        setModalContent({
          title: 'reviewBusiness',
          message: 'reviewBusinessMessage',
          buttonAgree: null,
          buttonClose: 'close',
          handleGoogleClose: () => {
            handleCloseLocationModal();
          },
        });
      }
    }
  }, [googleLocations, googleSignInStatus, locationsValue]);

  const handleFiltersChange = (
    filter: TestimonialsFiltersNames,
    newValue: string | null
  ) => {
    setFilters({ ...filters, [filter]: newValue });
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    changeCurrentPage(newPage);
  };

  const handleClickSearch = () => {
    handleFiltersChange('reviewContent', searchValue);
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleOpenModal = async () => {
    setIsTogglePublic(displayTestimonials);
    showModal({
      title: t('testimonialVisibilityTitle'),
      message: t('testimonialVisibilityMessage'),
      buttonAgree: t('save'),
      handleAgree: handleSave,
      handleClose: handleClose,
    });
  };

  const handleLocationChange = (
    event: React.SyntheticEvent,
    checked: boolean
  ) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;

    const business = googleLocations.find((location) => {
      return location.name == value;
    });

    // Remove values in checkboxValue that are not in googleLocations
    setCheckboxValue((prev) =>
      prev.filter((checkboxLocation) =>
        googleLocations
          .map((googleLocation) => googleLocation.name)
          .includes(checkboxLocation.name)
      )
    );

    if (business && checked) {
      setCheckboxValue((prev) => [...prev, business]);
    } else {
      setCheckboxValue((prev) =>
        prev.filter((location) => String(location.name) !== value)
      );
    }
  };

  const handleBusinessSaveFailed = () => {
    openSnackbar(t('businessSavedFail'));
    setLocationError(true);
    setModalContent({
      title: 'addBusinessFail',
      message: 'addBusinessFailMessage',
      buttonAgree: 'confirm',
      buttonClose: null,
      handleAgree: () => {
        handleCloseLocationModal();
        setLocationError(false);
        setModalContent({
          title: 'selectBusiness',
          message: 'multipleBusinesses',
          buttonAgree: 'confirm',
          buttonClose: 'cancel',
          handleAgree: () => {
            handleSaveLocation(selectedUserIdRef.current);
          },
        });
      },
    });
  };

  const handleSaveLocation = async (
    selectedUserId: number,
    locations?: IGoogleBusinessData[]
  ) => {
    const token = await getAccessToken();

    const data = locations || checkboxValueRef.current;

    if (token) {
      const { status } = await storeSelectedBusiness(
        token,
        data,
        selectedUserId,
        userType
      );

      if (status === 200) {
        openSnackbar(t('businessSavedSuccess'));
        setLocationsValue(data);
        handleCloseLocationModal();
      } else {
        handleBusinessSaveFailed();
      }
    }
  };

  const handleClose = () => {
    setIsTogglePublic(displayTestimonials);
    setIsVisibilitySaved(true);
    handleModalClose();
  };

  const handleVisibilityToggleChange = () => {
    setIsTogglePublic(!isTogglePublic);
    setIsVisibilitySaved(!isVisibilitySaved);
  };

  const handleSave = async () => {
    const data = {
      userId: selectedUserId || employeeId,
      displayTestimonials: isTogglePublic,
    };
    const token = await getAccessToken();
    if (token) {
      try {
        const res = await updateDisplayTestimonialsApi(token, data, userType);
        if (res === 201) {
          setDisplayedUserInfo({
            ...displayedUserInfo,
            displayTestimonials: isTogglePublic,
          });
          openSnackbar(t('testimonialVisibilityConfirm'));
          setIsVisibilitySaved(true);
        } else {
          // reset toggle if changes arent saved
          setIsTogglePublic(!isTogglePublic);
          openSnackbar(t('testimonialVisibilityFailed'));
        }
      } catch (e) {
        setIsTogglePublic(!isTogglePublic);
        openSnackbar(t('testimonialVisibilityFailed'));
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
    handleModalClose();
  };

  const setGoogleSignInFailed = () => {
    setModalContent({
      title: 'googleSignInFailed',
      message: 'googleSignInFailedMessage',
      buttonAgree: 'confirm',
      buttonClose: null,
      handleAgree: async () => {
        handleCloseLocationModal();
      },
      open: true,
    });
  };
  //
  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      console.log('Login Success', code);
      const { status } = await useGoogleLoginApi(
        code,
        selectedUserId,
        userType
      );

      if (status === 200 || status === 201) {
        setGoogleSignInStatus('valid');
        await getGoogleBusinesses();
        setGoogleAlertOpen(false);
        setIsShowModalLoginButton(false);

        setModalContent({
          open: true,
        });
      } else {
        setGoogleSignInFailed();
      }
    },
    onError: (error) => {
      console.log('Login Failed', error);
      setGoogleSignInFailed();
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/business.manage',
  });

  const handleCloseLocationModal = () => {
    setModalContent({
      open: false,
    });
  };

  const getGoogleBusinesses = async () => {
    const token = await getAccessToken();
    const { data, status } = await getGoogleBusinessesApi(
      token,
      selectedUserId,
      userType
    );

    if (status === 200 || status === 204) {
      setGoogleLocations(data);
    } else {
      console.error('Failed to get Google businesses');
      setModalContent({
        title: 'getGoogleBusinessFailed',
        message: 'getGoogleBusinessFailedMessage',
        buttonAgree: 'confirm',
        buttonClose: null,
        handleAgree: () => {
          handleCloseLocationModal();
        },
        open: true,
      });
    }
  };

  if (isError)
    return (
      <Error
        message={t('errorOccurredMessage')}
        title={t('errorOccurredTitle')}
      />
    );

  const {
    title,
    message,
    open,
    buttonAgree,
    buttonClose,
    handleAgree,
    handleGoogleClose,
  } = locationModalContent;

  return (
    <div>
      {isLoading && isFirstLoad ? (
        <TestimonialHeaderSkeleton />
      ) : (
        <div>
          <TestimonialsTop
            searchValue={searchValue}
            handleChangeSearch={handleChangeSearch}
            handleClickSearch={handleClickSearch}
            handleOpenModal={handleOpenModal}
            googleSignInStatus={googleSignInStatus}
            googleButtonAction={() => {
              setModalContent({
                open: true,
              });
            }}
            prefersNoAlert={prefersNoAlert}
          />
          {googleAlertOpen &&
            ((!prefersNoAlert && googleSignInStatus !== 'valid') ||
              (prefersNoAlert && googleSignInStatus === 'expired')) && (
              <TestimonialsAlert
                setGoogleAlertOpen={setGoogleAlertOpen}
                googleSignInStatus={googleSignInStatus}
                googleLogin={googleLogin}
              />
            )}
        </div>
      )}

      <StyledTestimonials>
        {isLoading && isFirstLoad ? (
          <TestimonialFiltersSkeleton />
        ) : (
          <TestimonialsFilters
            language={filters.language || ''}
            order={order}
            source={filters.source as TestimonialSource}
            status={filters.status || ''}
            handleFiltersChange={handleFiltersChange}
            setOrder={setOrder}
          />
        )}

        {isLoading ? (
          <TestimonialsSkeleton />
        ) : totalCount === 0 ? (
          <TestimonialsEmptyState />
        ) : (
          !!filteredTestimonials.length &&
          filteredTestimonials.map((testimonial) => (
            <Testimonial
              key={testimonial?.id || Math.random()}
              authorName={
                testimonial.reviewName ||
                `${testimonial.reviewFirstName} ${testimonial.reviewLastName}`
              }
              content={testimonial.reviewContent}
              date={testimonial.datePublished}
              networkId={testimonial.networkId}
              rating={testimonial.reviewRating}
              source={testimonial.source}
              status={testimonial.status}
              id={testimonial?.id as string}
              reviewLikeRating={testimonial.reviewLikeRating}
              page="testimonials"
            />
          ))
        )}
      </StyledTestimonials>

      {!isLoading && pagesCount > 1 && (
        <StyledPagination>
          <Pagination
            page={currentPage}
            count={pagesCount}
            handleChangePage={handleChangePage}
          />
        </StyledPagination>
      )}

      <Dialog
        buttonAgree={modalContent.buttonAgree}
        message={modalContent.message}
        title={modalContent.title}
        open={modalContent.open}
        disableAgreeButton={isVisibilitySaved}
        handleClose={modalContent.handleClose}
        handleAgree={handleSave}
        hasCloseIconButton
      >
        <StyledSwitch>
          <Switch
            ariaLabel="Visibility"
            checked={!isTogglePublic}
            textChecked="hidden"
            textDefault="public"
            onChange={handleVisibilityToggleChange}
            width={110}
          />
        </StyledSwitch>
      </Dialog>
      <Dialog
        title={t(title)}
        message={
          message === 'confirmBusinessMessage' ||
          message === 'reviewBusinessMessage'
            ? t(message, {
                businessName: googleLocations?.[0]?.title ?? '',
              })
            : message
            ? t(message)
            : ''
        }
        open={open}
        handleClose={handleGoogleClose}
        buttonAgree={buttonAgree ? t(buttonAgree) : null}
        buttonClose={buttonClose ? t(buttonClose) : null}
        handleAgree={handleAgree}
        actionsChildren={
          isShowModalLoginButton && (
            <StyledButtonMarginTop
              variant="outlined"
              onClick={() => {
                googleLogin();
              }}
            >
              <IconSvg>
                <StyledSvgIcon>
                  <GoogleIcon />
                </StyledSvgIcon>
              </IconSvg>
              {googleSignInStatus === 'null'
                ? t('googleSignIn')
                : t('googleSignInAnother')}
            </StyledButtonMarginTop>
          )
        }
      >
        {isShowBusinessPicker && (
          <TestimonialsBusinessPicker
            googleLocations={googleLocations}
            handleLocationChange={handleLocationChange}
            preCheckedValues={checkboxValue.map((location) => location.name)}
          />
        )}
      </Dialog>
    </div>
  );
};

const StyledTestimonials = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.spacing(4, 8, 4, 6),
}));

const StyledPagination = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(8),
  marginBottom: theme.spacing(3),
}));

const StyledSwitch = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: '#0D1022',
  backgroundColor: '#FFFFFF',
  borderColor: 'rgba(42, 67, 255, 0.5)', // #2A43FF with 50% opacity
  borderRadius: '5px',
  marginLeft: theme.spacing(2),
  padding: theme.spacing(1, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  gap: '8px',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    borderColor: '#cccccc',
  },
}));

const StyledSvgIcon = styled('div')({
  lineHeight: 0,
});

const StyledButtonMarginTop = styled(StyledButton)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  alignSelf: 'flex-start',
}));
